const languages = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Spanish',
    code: 'es',
  },
];

export default languages;
