import { createSlice, createSelector } from '@reduxjs/toolkit';
import { tools } from '@lake-superior/core';

const removeDuplicatesById = (a, b) => a
  .concat(b.filter((bItem) => !a.some((aItem) => aItem.id === bItem.id)));

const INITIAL_STATE = {
  showNavBar: true,
  showMobileBanner: false,
  mobileBannerText: 'This is a Beta prototype optimized for a mobile device. Please view on your phone for the best experience.',
  shellHeaderClasses: [],
  navBarClasses: [],
  menu: {
    main: [],
    context: [],
    prev: {},
  },
};

const MFEManagerDuck = createSlice({
  name: 'shell',
  initialState: INITIAL_STATE,
  reducers: {
    toggleNavBar: (state, { payload }) => {
      const {
        showNavBar,
      } = payload;

      state.showNavBar = showNavBar;
    },
    modifyMobileBanner: (state, { payload }) => {
      const {
        show,
        text,
      } = payload;

      state.showMobileBanner = show;

      if (tools.isDefinedAndNotNullAndNotEmpty(text)) {
        state.mobileBannerText = text;
      }
    },
    addClassToShellHeader: (state, { payload }) => {
      state.shellHeaderClasses = state.shellHeaderClasses.concat(payload);
    },
    removeClassFromShellHeader: (state, { payload }) => {
      state.shellHeaderClasses = state.shellHeaderClasses.filter((t) => !payload.includes(t));
    },
    addClassToNavBar: (state, { payload }) => {
      state.navBarClasses = state.navBarClasses.concat(payload);
    },
    removeClassFromNavBar: (state, { payload }) => {
      state.navBarClasses = state.navBarClasses.filter((t) => !payload.includes(t));
    },
    // MENU ACTIONS
    MFEMounted: (state, { payload }) => {
      const {
        menuConfig,
      } = payload;

      state.menu.main = removeDuplicatesById(state.menu.main, menuConfig.main);

      if (tools.isPropertyDefinedAndNotNullAndNotEmpty(menuConfig, 'context')) {
        state.menu.context = removeDuplicatesById(state.menu.context, menuConfig.context);
      }
    },

    addItemsMainMenu: (state, { payload }) => {
      state.menu.main = removeDuplicatesById(state.menu.main, payload);
    },
    addItemsContextMenu: (state, { payload }) => {
      state.menu.context = removeDuplicatesById(state.menu.context, payload);
    },
    removeItemsMainMenu: (state, { payload }) => {
      state.menu.main = state.menu.main
        .filter((t) => !payload.some((j) => t.id.toLowerCase() === j.toLowerCase()));
    },
    removeItemsContextMenu: (state, { payload }) => {
      state.menu.context = state.menu.context
        .filter((t) => !payload.some((j) => t.id.toLowerCase() === j.toLowerCase()));
    },
    reOrderItemsMainMenu: (state, { payload }) => {
      payload.forEach((item) => {
        state.menu.main.find((t) => t.id === item.id).order = item.order;
      });
    },
    reOrderItemsContextMenu: (state, { payload }) => {
      payload.forEach((item) => {
        state.menu.context.find((t) => t.id === item.id).order = item.order;
      });
    },
    // END OF MENU ACTIONS
  },
});

// access the part of the store that you need
const _shellSelector = (state) => state.shell;
const _shellMenuSelector = (state) => state.shell.menu;

export const showNavBarSelector = createSelector(
  _shellSelector,
  (shellSlice) => shellSlice.showNavBar,
);

export const showMobileBannerSelector = createSelector(
  _shellSelector,
  (shellSlice) => ({
    show: shellSlice.showMobileBanner,
    text: shellSlice.mobileBannerText,
  }),
);

export const shellHeaderClassesSelector = createSelector(
  _shellSelector,
  (shellSlice) => shellSlice.shellHeaderClasses,
);

export const navBarClassesSelector = createSelector(
  _shellSelector,
  (shellSlice) => shellSlice.navBarClasses,
);

export const mainMenuItemSelector = createSelector(
  _shellMenuSelector,
  (shellMenuSlice) => Object.freeze(shellMenuSlice.main
    .slice()
    .sort((a, b) => a.order - b.order)),
);

export const contextMenuSelector = createSelector(
  _shellMenuSelector,
  (shellMenuSlice) => Object.freeze(shellMenuSlice.context
    .slice()
    .sort((a, b) => a.order - b.order)),
);

export const contextMainMenuSelector = createSelector(
  _shellMenuSelector,
  (shellMenuSlice) => Object.freeze(shellMenuSlice.context.concat(shellMenuSlice.main)
    .slice()
    .sort((a, b) => a.order - b.order)),
);

export const {
  setCurrentConfigRole,
} = MFEManagerDuck.actions;

// Export the reducer in the default export
export default MFEManagerDuck.reducer;
