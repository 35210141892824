import { createReducerManager } from '@lake-superior/ui-core';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

export default function (initialState) {
  const reducerManager = createReducerManager(rootReducer);

  const store = configureStore({
    reducer: reducerManager.reduce,
    preloadedState: initialState,
    // [thunk]
    middleware: getDefaultMiddleware(),
    devTools: false,
  });

  store.ReducerManager = reducerManager;
  return store;
}
