import React from 'react';
import { IdentityActions } from '@lake-superior/ui-core';
import { useSelector } from 'react-redux';

const withSession = (WrappedComponent) => {
  const Wrapped = (props) => {
    const session = useSelector(IdentityActions.activeUserSessionSelector);

    if (session === null) {
      return null;
    }

    return (
      <WrappedComponent
        {...props}
        session={session}
      />
    );
  };

  return Wrapped;
};

export default withSession;
