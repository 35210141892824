import { tools } from '@lake-superior/core';
import { UICoreSliceConfig } from '@lake-superior/ui-core';
import { createSlice } from '@reduxjs/toolkit';

const infiniteScrollSliceConfig = UICoreSliceConfig.infiniteScroll;

const findNotificationIndex = (items, id) => items.findIndex((t) => t.id === id);

const onToggleOrDeleteStart = (state) => {
  state.shellNotifications.isLoading = true;
};

const onToggleOrDeleteSucess = (state, {
  payload: {
    paged: {
      result,
      pageCount,
      totalRowCount,
    },
    touchedNotificationId,
  },
}) => {
  const infiniteScrollItems = state.shellNotifications.items;

  // Step 1: Remove the touched notification ID
  const index = findNotificationIndex(infiniteScrollItems, touchedNotificationId);
  if (index > -1) {
    infiniteScrollItems.splice(index, 1);
  }

  const newPageIds = result.map((t) => t.id);
  const existingIds = infiniteScrollItems.map((t) => t.id);

  // Step 2: Add if possible, the new notification to complete the page.
  const newItems = newPageIds.filter((x) => !existingIds.includes(x));

  newItems.forEach((newItem) => {
    if (tools.isDefinedAndNotNullAndNotEmpty(newItem)) {
      const position = findNotificationIndex(result, newItem);
      infiniteScrollItems.splice(position, 0, result.find((t) => t.id === newItem));
    }
  });

  // Step 3: Update the corresponding values based on the new
  // computed items using the same round trip http request respose

  state.shellNotifications.totalPageCount = pageCount;
  state.shellNotifications.totalItemsCount = totalRowCount;
  state.shellNotifications.lastPageReached = infiniteScrollItems.length === totalRowCount;
  state.shellNotifications.isLoading = false;
};

infiniteScrollSliceConfig.extraReducers = {
  'notifications/toggleStatusStart': onToggleOrDeleteStart,
  'notifications/toggleStatusSuccess': onToggleOrDeleteSucess,
  'notifications/deleteStart': onToggleOrDeleteStart,
  'notifications/deleteSuccess': onToggleOrDeleteSucess,
};

const infiniteScroll = createSlice(infiniteScrollSliceConfig);

export default infiniteScroll.reducer;
