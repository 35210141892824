import { IdentityListener, CreateActionListener } from '@lake-superior/ui-core';
import { createSelector } from '@reduxjs/toolkit';
import { tools } from '@lake-superior/core';
import { setupPendoMetadata } from '../../pendo';
import { logoutUrl } from '../../config/appsettings.json';

const listenerId = 'prShell';

const isPRShellListener = (action) => (callback) => {
  if (action.listenerId === listenerId) {
    callback();
  }
};

const resetLoginState = (state) => {
  state.loginError = {
    messageTranslateKey: null,
    faultCode: null,
  };
};

const getTranslateKeyPath = (faultCode) => ((faultCode === null)
  ? 'faultCodes.IdentityService_500-1'
  : `faultCodes.${faultCode}`);

// ----------------------------- > Listeners setup

const INITIAL_STATE = {
  loginState: null,
  loginError: {
    messageTranslateKey: null,
    faultCode: null,
  },
  forgotPasswordState: {
    error: null,
  },
  resetPasswordState: {
    error: null,
  },
  resetPasswordChallengeState: {
    error: null,
  },
  registerState: {
    error: null,
  },
  searchUsersState: {
    error: {
      hasError: false,
    },
    isSpinnerLoading: false,
  },
  currentProfile: {
    error: null,
  },
  createNewPasswordState: {
    error: null,
    oneTimeSessionToken: null,
  },
  control: 0,
  securityQuestionsState: {
    error: null,
  },
  getSecurityQuestionsState: {
    questions: [],
    error: null,
  },
  verifySecurityQuestionsAnswersState: {
    error: null,
    response: null,
  },
  getVerificationOptionsState: {
    error: null,
  },
  changePasswordByGuidState: {
    error: null,
  },
  verificationCodeForForgotState: {
    error: null,
  },
  registerByPhoneWithUserDataState: {
    error: null,
    isSpinnerLoading: false,
  },
  doesProfileExistByParamsState: {
    error: null,
    response: null,
  },
};

IdentityListener.initialState = INITIAL_STATE;

IdentityListener.on.loginStart = (state, action) => {
  isPRShellListener(action)(() => {
    resetLoginState(state);

    state.forgotPasswordState = {
      error: null,
    };

    state.resetPasswordState = {
      error: null,
    };

    state.resetPasswordChallengeState = {
      error: null,
    };

    state.registerState = {
      error: null,
    };

    state.doesProfileExistByParamsState = {
      error: null,
      response: null,
    };

    state.searchUsersState = {
      error: {
        hasError: false,
      },
      isSpinnerLoading: false,
    };
  });
};

IdentityListener.on.loginFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.loginError = action.payload;
  });
};

IdentityListener.on.forgotPasswordStart = (state, action) => {
  isPRShellListener(action)(() => {
    resetLoginState(state);

    state.resetPasswordState = {
      error: null,
    };
  });
};

IdentityListener.on.forgotPasswordSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.forgotPasswordState = {
      error: {
        hasError: false,
      },
      ...action,
    };
  });
};

IdentityListener.on.forgotPasswordFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.forgotPasswordState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.saveSecurityQuestionsAnswersStart = (state, action) => {
  isPRShellListener(action)(() => {
    state.securityQuestionsState = {
      error: null,
      ...action,
    };
  });
};

IdentityListener.on.saveSecurityQuestionsAnswersSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.securityQuestionsState = {
      error: {
        hasError: false,
      },
      ...action,
    };
  });
};

IdentityListener.on.saveSecurityQuestionsAnswersFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.securityQuestionsState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.getSecurityQuestionsStart = (state, action) => {
  isPRShellListener(action)(() => {
    state.getSecurityQuestionsState = {
      error: null,
      ...action,
    };
  });
};

IdentityListener.on.getSecurityQuestionsSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.getSecurityQuestionsState = {
      error: {
        hasError: false,
      },
      questions: action.payload,
    };
  });
};

IdentityListener.on.getSecurityQuestionsFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.getSecurityQuestionsState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.verifySecurityQuestionsStart = (state, action) => {
  isPRShellListener(action)(() => {
    state.verifySecurityQuestionsAnswersState = {
      error: null,
      ...action,
    };
  });
};

IdentityListener.on.verifySecurityQuestionsSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.verifySecurityQuestionsAnswersState = {
      error: {
        hasError: false,
      },
      ...action,
      response: action.payload,
    };
  });
};

IdentityListener.on.verifySecurityQuestionsFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.verifySecurityQuestionsAnswersState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.changePasswordByGuidStart = (state, action) => {
  isPRShellListener(action)(() => {
    state.changePasswordByGuidState = {
      error: null,
      ...action,
    };
  });
};

IdentityListener.on.changePasswordByGuidSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.changePasswordByGuidState = {
      error: {
        hasError: false,
      },
      ...action,
      response: { ...action.payload },
    };
  });
};

IdentityListener.on.changePasswordByGuidFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.changePasswordByGuidState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.searchUsersStart = (state, action) => {
  isPRShellListener(action)(() => {
    state.searchUsersState = {
      error: {
        hasError: false,
      },
      isSpinnerLoading: true,
      ...action,
    };
  });
};

IdentityListener.on.searchUsersSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.searchUsersState = {
      error: {
        hasError: false,
      },
      isSpinnerLoading: false,
      ...action,
    };
  });
};

IdentityListener.on.searchUsersFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.searchUsersState = {
      error: {
        hasError: true,
      },
      isSpinnerLoading: false,
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.registerByPhoneWithUserDataStart = (state, {
  listenerId,
  payload,
  ...rest
}) => {
  isPRShellListener({ listenerId })(() => {
    state.registerByPhoneWithUserDataState = {
      error: {
        hasError: false,
      },
      isSpinnerLoading: true,
      ...rest,
    };
  });
};

IdentityListener.on.registerByPhoneWithUserDataSuccess = (state, {
  listenerId,
  payload,
  ...rest
}) => {
  isPRShellListener({ listenerId })(() => {
    state.registerByPhoneWithUserDataState = {
      error: {
        hasError: false,
      },
      isSpinnerLoading: false,
      response: { ...payload, success: true },
      ...rest,
    };
  });
};

IdentityListener.on.registerByPhoneWithUserDataFail = (state, {
  listenerId,
  payload,
  ...rest
}) => {
  isPRShellListener({ listenerId })(() => {
    state.registerByPhoneWithUserDataState = {
      error: {
        hasError: true,
      },
      isSpinnerLoading: false,
      messageTranslateKey: getTranslateKeyPath(payload.faultCode),
      ...rest,
    };
  });
};

IdentityListener.on.resetPasswordStart = (state, action) => {
  isPRShellListener(action)(() => {
    resetLoginState(state);

    state.forgotPasswordState = {
      error: null,
    };
  });
};

IdentityListener.on.resetPasswordSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.resetPasswordState = {
      error: {
        hasError: false,
      },
      ...action,
    };
  });
};

IdentityListener.on.resetPasswordFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.resetPasswordState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.resetPasswordChallengeStart = (state, action) => {
  isPRShellListener(action)(() => {
    resetLoginState(state);

    state.resetPasswordChallengeState = {
      error: null,
    };
  });
};

IdentityListener.on.resetPasswordChallengeSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.resetPasswordChallengeState = {
      error: {
        hasError: false,
      },
      ...action,
    };
  });
};

IdentityListener.on.resetPasswordChallengeFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.resetPasswordChallengeState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.registerByEmailStart = (state, action) => {
  isPRShellListener(action)(() => {
    resetLoginState(state);

    state.registerState = {
      error: null,
    };
  });
};

IdentityListener.on.registerByEmailSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.registerState = {
      error: {
        hasError: false,
      },
      ...action,
    };
  });
};

IdentityListener.on.registerByPhoneFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.registerState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.registerByPhoneStart = (state, action) => {
  isPRShellListener(action)(() => {
    resetLoginState(state);

    state.registerState = {
      error: null,
    };
  });
};

IdentityListener.on.registerByPhoneSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.registerState = {
      error: {
        hasError: false,
      },
      ...action,
    };
  });
};

IdentityListener.on.registerByEmailFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.registerState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.getVerificationOptionsStart = (state, action) => {
  isPRShellListener(action)(() => {
    Object.assign(state, INITIAL_STATE);
  });
};

IdentityListener.on.getVerificationOptionsSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.getVerificationOptionsState = {
      error: {
        hasError: false,
      },
    };
  });
};

IdentityListener.on.getVerificationOptionsFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.getVerificationOptionsState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.profileByContextIdStart = (state, action) => {
  isPRShellListener(action)(() => {
    resetLoginState(state);

    state.currentProfile = {
      error: null,
    };
  });
};

IdentityListener.on.profileByContextIdSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.currentProfile = {
      error: {
        hasError: false,
      },
      ...action.payload,
    };
  });
};

IdentityListener.on.profileByContextIdFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.currentProfile = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action,
    };
  });
};

IdentityListener.on.SendVerificationCodeForForgotPasswordSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.verificationCodeForForgotState = {
      error: {
        hasError: false,
      },
      ...action.payload,
    };
  });
};

IdentityListener.on.SendVerificationCodeForForgotPasswordFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.verificationCodeForForgotState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      ...action.payload,
    };
  });
};

IdentityListener.on.logoutSuccess = () => {
  setupPendoMetadata();
  window.location.href = logoutUrl;
};

IdentityListener.on.logoutFail = () => {
  setupPendoMetadata();
  window.location.href = logoutUrl;
};

IdentityListener.on.clearChallenge = (state, action) => {
  isPRShellListener(action)(() => {
    state.resetPasswordChallengeState = {
      error: null,
    };

    state.loginError = {
      messageTranslateKey: null,
      faultCode: null,
    };
  });
};

IdentityListener.on.clearAll = (state, action) => {
  isPRShellListener(action)(() => {
    state.loginState = null;

    state.loginError = {
      messageTranslateKey: null,
      faultCode: null,
    };

    state.forgotPasswordState = {
      error: null,
    };

    state.resetPasswordState = {
      error: null,
    };

    state.resetPasswordChallengeState = {
      error: null,
    };

    state.registerState = {
      error: null,
    };

    state.searchUsersState = {
      error: {
        hasError: false,
      },
      isSpinnerLoading: false,
    };

    state.currentProfile = {
      error: null,
    };

    state.createNewPasswordState = {
      error: null,
      oneTimeSessionToken: null,
    };

    state.securityQuestionsState = {
      error: null,
    };

    state.getSecurityQuestionsState = {
      questions: [],
      error: null,
    };

    state.verifySecurityQuestionsAnswersState = {
      error: null,
      response: null,
    };

    state.getVerificationOptionsState = {
      error: null,
    };

    state.changePasswordByGuidState = {
      error: null,
    };

    state.verificationCodeForForgotState = {
      error: null,
    };

    state.registerByPhoneWithUserDataState = {
      error: null,
      isSpinnerLoading: false,
    };

    state.doesProfileExistByParamsState = {
      error: null,
      response: null,
    };

    state.control++;
  });
};

IdentityListener.on.doesProfileExistByParamsStart = (state, action) => {
  isPRShellListener(action)(() => {
    state.doesProfileExistByParamsState = {
      error: null,
      response: null,
    };
  });
};

IdentityListener.on.doesProfileExistByParamsSuccess = (state, action) => {
  isPRShellListener(action)(() => {
    state.doesProfileExistByParamsState = {
      error: {
        hasError: false,
      },
      response: action.payload,
    };
  });
};

IdentityListener.on.doesProfileExistByParamsFail = (state, action) => {
  isPRShellListener(action)(() => {
    state.doesProfileExistByParamsState = {
      error: {
        hasError: true,
      },
      messageTranslateKey: getTranslateKeyPath(action.payload.faultCode),
      response: null,
    };
  });
};

IdentityListener.on.resetRegistrationFlow = (state, action) => {
  isPRShellListener(action)(() => {
    state.registerState = {
      error: null,
    };

    state.doesProfileExistByParamsState = {
      error: null,
      response: null,
    };

    state.searchUsersState = {
      error: {
        hasError: false,
      },
      isSpinnerLoading: false,
    };
  });
};

// ----------------------------- > Listeners setup

const { identityListener: reducer } = CreateActionListener(listenerId, IdentityListener);

const identityListenerSelector = (state) => state.IdentityListener;

export const loginErrorSelector = createSelector(
  identityListenerSelector,
  (listener) => {
    if (tools.isPropertyDefinedAndNotNullAndNotEmpty(listener, 'loginError.statusCode')) {
      return {
        messageTranslateKey: getTranslateKeyPath(listener.loginError.faultCode),
        faultCode: listener.loginError.faultCode,
      };
    }

    return listener.loginError;
  },
);

export const forgotPasswordStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.forgotPasswordState,
);

export const securityQuestionsSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.securityQuestionsState,
);

export const controlSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.control,
);

export const resetPassworStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.resetPasswordState,
);

export const resetPasswordChallengeStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.resetPasswordChallengeState,
);

export const createNewPasswordStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.createNewPasswordState,
);

export const registerStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.registerState,
);

export const getSecurityQuestionsSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.getSecurityQuestionsState,
);

export const verifySecurityQuestionsAnswersSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.verifySecurityQuestionsAnswersState,
);

export const changePasswordByGuidSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.changePasswordByGuidState,
);

export const getVerificationOptionsSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.getVerificationOptionsState,
);

export const searchUsersStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.searchUsersState,
);

export const currentProfileSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.currentProfile,
);

export const verificationCodeForForgotStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.verificationCodeForForgotState,
);

export const registerByPhoneWithUserDataStateSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.registerByPhoneWithUserDataState,
);

export const doesProfileExistByParamsSelector = createSelector(
  identityListenerSelector,
  (listener) => listener.doesProfileExistByParamsState,
);

export default reducer;
