import React from 'react';
import { Route } from 'react-router-dom';
import Footer from '../LandingPage/Footer';

const ShellFooter = () => (
  <footer className="shell-footer">
    <Route exact path="/" component={Footer} />
  </footer>
);

export default ShellFooter;
