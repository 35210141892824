import React from 'react';
import { TrackedPhoneNumberBasic } from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import translateKeys from '../../locales/keys';

const ForgotPasswordTextField = ({ controlledWith }) => (
  <TrackedPhoneNumberBasic
    dataTestId="phonenumber"
    name="phonenumber"
    translateId={translateKeys.LOGIN.LOGINPAGE.LOGIN_INPUT.LABEL_BY_PHONE}
    placeholderTranslateId={
      translateKeys.LOGIN.LOGINPAGE.LOGIN_INPUT.PLACEHOLDER_BY_PHONE
    }
    required
    disableCopyPaste={false}
    controlledWith={controlledWith}
  />
);

ForgotPasswordTextField.propTypes = {
  controlledWith: PropTypes.func,
};

ForgotPasswordTextField.defaultProps = {
  controlledWith: () => null,
};

export default ForgotPasswordTextField;
