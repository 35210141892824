import React, { useCallback, useEffect, useState } from 'react';
import { tools } from '@lake-superior/core';
import { useHistory } from 'react-router-dom';
import {
  ButtonProps, FormHandler, FieldCheckList,
  Text, TrackedToggleableIconTextField, IdentityActions, SubmitButton,
  TextHelperTypes, InlineAlert, inlineAlertStatus, Dialog, DialogProps,
  elementWrapperIconProps,
} from '@lake-superior/ui-core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import translateKeys from '../../locales/keys';
import { ResetPasswordChallenge } from './Helpers/IdentityServiceAPIs';
import {
  FieldCheckListArray, passwordEyeConfigArray, checkHasAllValidPasswordPolicy, FieldCheckListArgs,
} from './Helpers/loginUtils';
import { sessionTypeId } from '../../config/appsettings.json';
import { resetPasswordChallengeStateSelector } from '../../redux/listeners/identityListener';
import readytologinImage from '../../assets/img/Auth/readytologin.png';

const CreateNewPassword = () => {
  const dispatch = useDispatch();
  const bearerToken = useSelector(IdentityActions.bearerTokenSelector);
  const accessToken = useSelector(IdentityActions.accessTokenSelector);
  const identityChallenge = useSelector(IdentityActions.identityChallengeSelector);
  const resetPasswordState = useSelector(resetPasswordChallengeStateSelector);

  const [tempPassword, setTempPassword] = useState('');
  const [tempConfirmation, setTempConfirmation] = useState('');
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const gotoLogin = useCallback(() => {
    history.push('/auth/login');
  }, [history]);

  useEffect(() => {
    if (!tools.isPropertyDefinedAndNotNullAndNotEmpty(identityChallenge, 'challengeKey')
      && !tools.isDefinedAndNotNullAndNotEmpty(resetPasswordState.error)
    ) {
      gotoLogin();
    }
  }, [gotoLogin, identityChallenge, resetPasswordState.error]);

  useEffect(() => {
    if (tools.isDefinedAndNotNullAndNotEmpty(resetPasswordState.faultCode)) {
      if (resetPasswordState.faultCode === 'IdentityService_400-22') {
        history.push({
          pathname: '/auth/Login',
          state: { invalidChallengeKeyResetPass: true },
        });
      }
    }
  }, [history, resetPasswordState.faultCode]);

  useEffect(() => {
    if (bearerToken && accessToken && !identityChallenge) {
      setIsOpen(true);
    }
  }, [bearerToken, accessToken, history, identityChallenge]);

  return (
    <>
      <div className="use-ui-core">
        <Dialog
          buttonsPosition={DialogProps.ButtonsPosition.CENTER}
          closeButton={false}
          isOpen={isOpen}
          okButtonTranslateId={translateKeys.shell.GENERAL.LOGIN}
          okStyle={ButtonProps.ButtonStyles.PRIMARY}
          onOk={gotoLogin}
          size={DialogProps.DialogSizes.MEDIUM}
          style={DialogProps.DialogStyles.DEFAULT}
          hideHeader
        >
          <div className="new-password-success-dialog">
            <img src={readytologinImage} className="ready-to-login" alt="You are ready to log in" />
            <span className="title">
              <Text translateId={translateKeys.LOGIN.SUCCESS_SCREEN.TITLE} />
            </span>
            <Text translateId={translateKeys.LOGIN.SUCCESS_SCREEN.INFO} />
          </div>
        </Dialog>
        <div className="modal center is-visible create-new-password createNewPasswordWeb">
          <div className="modal-dialog default center col-xs-12 col-sm-offset-1 col-sm-10">
            <div className="formAreaCreateNewPassword">
              <FormHandler
                triggerOnEnter
                resolveWith={(state) => !state.identity.isLoading}
                onSubmit={(formData) => {
                  const validations = [];

                  if (!tools.isDefinedAndNotNullAndNotEmpty(identityChallenge.challengeKey)
                || !tools.isDefinedAndNotNullAndNotEmpty(identityChallenge.username)) {
                    validations.push({
                      translateId: translateKeys.LOGIN.GENERIC_ERROR,
                      severity: TextHelperTypes.ERROR,
                    });
                  }

                  return {
                    validations,
                    execute: () => new Promise((resolve, reject) => {
                      try {
                        if (checkHasAllValidPasswordPolicy(
                          FieldCheckListArgs(tempPassword, tempConfirmation),
                        )) {
                          ResetPasswordChallenge(dispatch,
                            identityChallenge.username,
                            identityChallenge.challengeKey,
                            formData.confirmPassword,
                            sessionTypeId);
                          resolve();
                        } else {
                          reject();
                        }
                      } catch {
                        reject();
                      }
                    }),
                  };
                }}
              >
                <div>
                  <div className="formAreaCreateNewPassword-container">
                    <div className="row">
                      <span className="title noPadding">
                        <Text translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.TITLE.LABEL} />
                      </span>
                    </div>

                    <div className="use-ui-core">
                      {resetPasswordState.error?.hasError === true && (
                      <>
                        <InlineAlert
                          bodyTranslateId={resetPasswordState.messageTranslateKey}
                          icon
                          status={inlineAlertStatus.ERROR}
                        />
                      </>
                      )}
                      <div className="trackfieldContainer">
                        <TrackedToggleableIconTextField
                          name="password"
                          translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD.LABEL}
                          placeholderTranslateId={translateKeys.LOGIN
                            .CREATE_NEW_PASSWORD.PASSWORD.PLACEHOLDER}
                          controlledWith={setTempPassword}
                          required
                          iconPosition={elementWrapperIconProps.INSIDE_RIGHT}
                          config={passwordEyeConfigArray}
                        />
                      </div>
                      <div className="trackfieldContainer">

                        <TrackedToggleableIconTextField
                          name="confirmPassword"
                          translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.CONFIRMATION.LABEL}
                          placeholderTranslateId={translateKeys.LOGIN
                            .CREATE_NEW_PASSWORD.CONFIRMATION.PLACEHOLDER}
                          required
                          controlledWith={setTempConfirmation}
                          applyThisValidation={() => {
                            if (!checkHasAllValidPasswordPolicy(
                              FieldCheckListArgs(tempPassword, tempConfirmation),
                            )) {
                              return [{
                                text: '',
                                severity: 'error',
                              }];
                            }
                            return [];
                          }}
                          iconPosition={elementWrapperIconProps.INSIDE_RIGHT}
                          config={passwordEyeConfigArray}
                        />

                      </div>
                      <span className="requirements">
                        <Text
                          translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                            .PASSWORD_REQUIREMENTS.LABEL}
                        />
                      </span>
                    </div>

                    <FieldCheckList
                      checkArray={FieldCheckListArray}
                      unCheckedIcon={faCircle}
                      checkedIcon={faCheckCircle}
                      checkArgs={FieldCheckListArgs(tempPassword, tempConfirmation)}
                    />

                    <div className="create-button use-ui-core">
                      <SubmitButton
                        translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                          .CREATE_PASSWORD_BUTTON.LABEL}
                        style={ButtonProps.ButtonStyles.PRIMARY}
                        type={ButtonProps.ButtonTypes.BUTTON}
                        size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                      />
                    </div>
                  </div>
                </div>
              </FormHandler>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewPassword;
