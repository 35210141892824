import React from 'react';
import { TrackedPhoneNumberBasic } from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import translateKeys from '../../../locales/keys';

const SearchUsersTextField = ({ controlledWith }) => (
  <TrackedPhoneNumberBasic
    translateId={translateKeys.LOGIN.REGISTRATION.PHONE_NUMBER.LABEL}
    placeholderTranslateId={
      translateKeys.LOGIN.REGISTRATION.PHONE_NUMBER.PLACEHOLDER
    }
    dataTestId="phoneNumber"
    id="phoneNumber"
    name="phoneNumber"
    required
    disableCopyPaste={false}
    controlledWith={controlledWith}
  />
);

SearchUsersTextField.propTypes = {
  controlledWith: PropTypes.func,
};

SearchUsersTextField.defaultProps = {
  controlledWith: () => null,
};

export default SearchUsersTextField;
