import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import { Text } from '@lake-superior/ui-core';
import { NavLink } from 'react-router-dom';
import { isFunction } from 'lodash';
import classNames from 'classnames';

const handleOnItemClick = (onItemClick) => {
  if (isFunction(onItemClick)) {
    onItemClick();
  }
};

const buildSubMenuOptions = (items, onItemClick) => items
  .map(({
    to,
    exact,
    icon,
    text,
    translateData,
    translateId,
    activeClassName,
  }) => (
    <li
      onClick={() => handleOnItemClick(onItemClick)}
      onKeyDown={() => handleOnItemClick(onItemClick)}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      key={nextId('shell-menu-mfe-option')}
      className="shell-menu-mfe-option"
    >
      <NavLink
        exact={exact}
        activeClassName={classNames('shell-menu-mfe-option-active', activeClassName)}
        to={to}
      >
        {icon && (
        <div className="shell-menu-mfe-option-icon">
          {icon}
        </div>
        )}

        <Text
          text={text}
          translateId={translateId}
          translateData={translateData}
        />
      </NavLink>
    </li>
  ));

const MFEMenuSection = ({
  items, onItemClick,
}) => {
  if (items != null) {
    return (
      <ul className="shell-submenu-container">
        {buildSubMenuOptions(items, onItemClick)}
      </ul>
    );
  }

  return null;
};

MFEMenuSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onItemClick: PropTypes.func,
};

MFEMenuSection.defaultProps = {
  onItemClick: null,
};

export default React.memo(MFEMenuSection);
