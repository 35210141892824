import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, ButtonProps, Dialog, DialogProps, FormHandler, TrackedTextField, faLink,
  FieldTypes, Text, TextHelperTypes, SubmitButton, inlineAlertStatus,
  InlineAlert, Spinner, SpinnerPositions, IdentityActions,
} from '@lake-superior/ui-core';
import { tools, phoneTools } from '@lake-superior/core';
import { useDispatch, useSelector } from 'react-redux';
import translateKeys from '../../../locales/keys';
import { searchUsersStateSelector } from '../../../redux/listeners/identityListener';
import { SearchForUsers } from '../Helpers/IdentityServiceAPIs';
import { updateSearchForm } from '../../../redux/ducks/integrations';
import useRegisterByPhoneWithUserData from '../../../hooks/use.register.by.phone.with.user.data';
import { supportUrl, needHelpInfo } from '../../../config/appsettings.json';
import SearchUsersTextField from './SearchUsersTextField';

const modalTypes = Object.freeze({
  none: null,
  creationFailed: 'creationFailed',
  accountExists: 'accountExists',
  checkPhone: 'checkPhone',
});

const callSupport = () => {
  window.location.href = `tel: ${needHelpInfo.needHelpLoginPhone}`;
};

const emailSupport = () => {
  window.location.href = `mailto: ${needHelpInfo.needHelpLoginEmail}`;
};

const SearchUsers = () => {
  const [currentModal, setCurrentModal] = useState(modalTypes.none);
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, translateId, isLoading } = useRegisterByPhoneWithUserData((status) => {
    switch (status) {
      case 'error':
        setCurrentModal(modalTypes.creationFailed);
        break;
      case 'success':
        setCurrentModal(modalTypes.checkPhone);
        break;
      case 'already-exists':
        setCurrentModal(modalTypes.accountExists);
        break;
      default:
        setCurrentModal(modalTypes.none);
    }
  }, 'search');
  const searchUsersState = useSelector(searchUsersStateSelector);

  const gotoLoginHandler = () => {
    dispatch(IdentityActions.resetRegistrationFlow());
    history.replace('/auth/login');
  };

  const resetPasswordHandler = () => {
    dispatch(IdentityActions.resetRegistrationFlow());
    history.replace('/auth/ForgotPassword');
  };

  useEffect(() => {
    const matches = searchUsersState.payload;
    if (searchUsersState.error?.hasError === false
      && tools.isDefinedAndNotNullAndNotEmpty(matches)) {
      if (matches.length > 1) {
        setCurrentModal(modalTypes.creationFailed);
      }
    }
  }, [searchUsersState, dispatch]);

  const footerContent = (
    <div className="search-user-account-dialog-footer">
      <Text
        className="footer-label-text"
        translateId={
          translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
            .DIALOG_ACCOUNT_EXIST.LABEL
        }
      />
      <Button
        label={
          translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
            .GO_BACK_TO_PEOPLE_READY
        }
        style={ButtonProps.ButtonStyles.CTABLUE3}
        icon={faLink}
        translateId={
          translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
            .DIALOG_ACCOUNT_EXIST.LINK
        }
        redirecturl={supportUrl}
      />
    </div>
  );

  return (
    <div className="registration">
      <div className="use-ui-core searchuser-dialog">
        <section className="search-user-creation-failed">
          <Dialog
            className="user-creation-failed-dialog"
            isOpen={currentModal === modalTypes.creationFailed}
            size={DialogProps.DialogSizes.MEDIUM}
            style={DialogProps.DialogStyles.DEFAULT}
            closeButton
            onCancel={() => setCurrentModal(modalTypes.none)}
          >
            <h2>
              <Text
                translateId={
                  translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                    .DIALOG_ACCOUNT_CREATION_FAILED.TITLE
                }
              />
            </h2>
            <Text
              translateId={
                translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                  .DIALOG_ACCOUNT_CREATION_FAILED.MESSAGE
              }
            />

            <div className="help-options-container">
              <div className="help-option">
                <div>
                  <Text
                    className="text-option"
                    translateId={
                      translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                        .DIALOG_ACCOUNT_CREATION_FAILED.LABEL_PHONE
                    }
                  />
                  {phoneTools.formatPhoneNumber(
                    needHelpInfo.needHelpLoginPhone,
                  )}
                </div>
                <Button
                  className="call-button"
                  size={ButtonProps.ButtonSizes.SMALL}
                  onClick={() => callSupport()}
                  translateId={
                    translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                      .DIALOG_ACCOUNT_CREATION_FAILED.BUTTON_CALL
                  }
                />
              </div>

              <hr />
              <div className="help-option">
                <div>
                  <Text
                    className="text-option"
                    translateId={
                      translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                        .DIALOG_ACCOUNT_CREATION_FAILED.LABEL_EMAIL
                    }
                  />
                  {needHelpInfo.needHelpLoginEmail}
                </div>
                <Button
                  size={ButtonProps.ButtonSizes.SMALL}
                  onClick={() => emailSupport()}
                  translateId={
                    translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                      .DIALOG_ACCOUNT_CREATION_FAILED.BUTTON_EMAIL
                  }
                />
              </div>
            </div>
          </Dialog>
        </section>
        <section className="search-user-account-exist">
          <Dialog
            className="account-exist-dialog"
            isOpen={currentModal === modalTypes.accountExists}
            okButtonTranslateId={
              translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                .DIALOG_ACCOUNT_EXIST.BUTTON_TITLE
            }
            okStyle={ButtonProps.ButtonStyles.PRIMARY}
            onOk={resetPasswordHandler}
            size={DialogProps.DialogSizes.MEDIUM}
            style={DialogProps.DialogStyles.DEFAULT}
            translateId={
              translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                .DIALOG_ACCOUNT_EXIST.TITLE
            }
            footerContent={footerContent}
            closeButton
            onCancel={() => setCurrentModal(modalTypes.none)}
          >
            <div className="search-user-account-exist-message">
              <Text
                translateId={
                  translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                    .DIALOG_ACCOUNT_EXIST.MESSAGE
                }
              />
              <Text
                translateId={
                  translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                    .DIALOG_ACCOUNT_EXIST.MESSAGE2
                }
              />
            </div>
          </Dialog>
        </section>
        <section className="search-user-creation-success">
          <Dialog
            isOpen={currentModal === modalTypes.checkPhone}
            okButtonTranslateId={
              translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                .DIALOG_CHECK_PHONE.BUTTON_TITLE
            }
            okStyle={ButtonProps.ButtonStyles.PRIMARY}
            size={DialogProps.DialogSizes.SMALL}
            style={DialogProps.DialogStyles.DEFAULT}
            translateId={
              translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                .DIALOG_CHECK_PHONE.TITLE
            }
            onOk={gotoLoginHandler}
            footerContent={footerContent}
            showMobileHeader
          >
            <div className="search-user-check-phone-message">
              <Text
                translateId={
                  translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                    .DIALOG_CHECK_PHONE.MESSAGE
                }
              />
              <br />
              <br />
              <Text
                translateId={translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                  .DIALOG_CHECK_PHONE.MESSAGE2}
              />
            </div>
          </Dialog>
        </section>
      </div>
      <div className="bodyContainer">
        <div className="formAreaCreateNewPassword use-ui-core">
          <Spinner
            isLoading={isLoading}
            position={SpinnerPositions.OVERLAY}
          >
            <FormHandler
              triggerOnEnter
              resolveWith={(state) => !state.identity.isLoading}
              onSubmit={(formData) => {
                const validations = [];

                const firstName = formData.firstName.trim();
                const lastName = formData.lastName.trim();
                const phoneNumber = formData.phoneNumber.trim();
                const email = formData.email.trim();
                const dateOfBirth = formData.dateOfBirth.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$1/$2');
                const SSN = formData.lastFourSSN.trim();

                if (!tools.isDefinedAndNotNullAndNotEmpty(firstName)
                || !tools.isDefinedAndNotNullAndNotEmpty(lastName)
                || !tools.isDefinedAndNotNullAndNotEmpty(phoneNumber)
                || !tools.isDefinedAndNotNullAndNotEmpty(email)
                || !tools.isDefinedAndNotNullAndNotEmpty(dateOfBirth)
                || !tools.isDefinedAndNotNullAndNotEmpty(SSN)) {
                  validations.push({
                    translateId: translateKeys.LOGIN.GENERIC_ERROR,
                    severity: TextHelperTypes.ERROR,
                  });
                }

                dispatch(
                  updateSearchForm({
                    email,
                    firstName,
                    lastName,
                    phoneNumber,
                    dateOfBirth,
                    SSN,
                  }),
                );

                return {
                  validations,
                  execute: () => new Promise((resolve, reject) => {
                    try {
                      SearchForUsers(dispatch, {
                        firstName,
                        lastName,
                        phoneNumber,
                        email,
                        dateOfBirth,
                        SSN,
                      });
                      resolve();
                    } catch {
                      reject();
                    }
                  }),
                };
              }}
            >
              <div className="formAreaCreateNewPassword-container">
                <div className="row left-xs">
                  <span className="title">
                    <Text translateId={translateKeys.LOGIN.REGISTRATION.GREETING.LABEL} />
                  </span>
                  <span className="sub-title">
                    <Text translateId={
                      translateKeys.LOGIN.REGISTRATION.CONTACT_YOU_MESSAGE.LABEL
                      }
                    />
                  </span>
                </div>
                <div className="use-ui-core">
                  {error?.hasError === true && (
                    <InlineAlert
                      bodyTranslateId={translateId}
                      icon
                      status={inlineAlertStatus.ERROR}
                    />
                  )}

                  <div className="formFieldGroup">
                    <div className="row">
                      <div className="trackfieldContainer username">
                        <TrackedTextField
                          id="firstName"
                          name="firstName"
                          dataTestId="firstName"
                          translateId={translateKeys.LOGIN.REGISTRATION.FIRST_NAME.LABEL}
                          placeholderTranslateId={translateKeys.LOGIN
                            .REGISTRATION.FIRST_NAME.PLACEHOLDER}
                          type={FieldTypes.TEXT}
                          required
                        />
                      </div>
                      <div className="trackfieldContainer username">
                        <TrackedTextField
                          id="lastName"
                          name="lastName"
                          dataTestId="lastName"
                          translateId={translateKeys.LOGIN.REGISTRATION.LAST_NAME.LABEL}
                          placeholderTranslateId={translateKeys.LOGIN
                            .REGISTRATION.LAST_NAME.PLACEHOLDER}
                          type={FieldTypes.TEXT}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="trackfieldContainer username">
                        <SearchUsersTextField />
                      </div>
                      <div className="trackfieldContainer username">
                        <TrackedTextField
                          id="email"
                          name="email"
                          dataTestId="email"
                          translateId={translateKeys.LOGIN.REGISTRATION.EMAIL.LABEL}
                          placeholderTranslateId={translateKeys.LOGIN
                            .REGISTRATION.EMAIL.PLACEHOLDER}
                          type={FieldTypes.EMAIL}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="trackfieldContainer username">
                        <TrackedTextField
                          id="dateOfBirth"
                          name="dateOfBirth"
                          dataTestId="dateOfBirth"
                          customInputMode={FieldTypes.NUMBER}
                          isClearable
                          mask="99/99/9999"
                          maskPlaceholder="mm/dd/yyyy"
                          max={10}
                          min={10}
                          placeholderTranslateId={
                            translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                              .DATE_OF_BIRTH_PLACEHOLDER.LABEL
                          }
                          required
                          translateId={
                            translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                              .DATE_OF_BIRTH.LABEL
                          }
                          type={FieldTypes.TEXT}
                          applyThisValidation={({ value }) => {
                            if (Number.isNaN(Date.parse(value))) {
                              return [{
                                translateId: 'uicore.formhandler.required_field',
                                severity: 'error',
                              }];
                            }
                            return [];
                          }}
                        />
                      </div>
                      <div className="trackfieldContainer username">
                        <TrackedTextField
                          id="lastFourSSN"
                          name="lastFourSSN"
                          dataTestId="lastFourSSN"
                          translateId={
                            translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                              .LAST_FOUR_SSN.LABEL
                          }
                          placeholderTranslateId={
                            translateKeys.LOGIN.SEARCH_USER_ADDITIONAL_INFORMATION
                              .LAST_FOUR_SSN_PLACEHOLDER.LABEL
                          }
                          mask="9999"
                          max={4}
                          min={4}
                          type={FieldTypes.TEXT}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-divider" />
                  </div>
                  <div className="button-row row">
                    <div className="create-button use-ui-core cancelButton">
                      <Button
                        dataTestId="registerCancelButton"
                        translateId={translateKeys.shell.GENERAL.CANCEL}
                        style={ButtonProps.ButtonStyles.OUTLINEBLUE}
                        size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                        onClick={() => {
                          history.replace('/auth/login');
                        }}
                      />
                    </div>
                    <div className="create-button use-ui-core continueButton">
                      <SubmitButton
                        dataTestId="buttonContinue"
                        translateId={translateKeys.LOGIN.REGISTRATION
                          .CONTINUE_BUTTON.LABEL}
                        style={ButtonProps.ButtonStyles.PRIMARY}
                        size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormHandler>
          </Spinner>
        </div>
      </div>
    </div>
  );
};
export default SearchUsers;
