import React, { useEffect } from 'react';
import {
  showAlert,
  Text,
} from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import translateKeys from '../../locales/keys';

const UrlActionToastMessage = (props) => {
  const { params } = props;
  const component = (
    <>
      <h1>
        <Text translateId={params.message} />
      </h1>
      <div>
        <a
          data-testid="BI"
          target="_blank"
          rel="noopener noreferrer"
          className="Toastify__toast-body__link"
          href={params.url}
        >
          <Text translateId={translateKeys.shell.REPORTS.REDIRECT} />
        </a>
      </div>
    </>
  );

  useEffect(() => {
    showAlert.success(component, {
      autoClose: +params.delay,
      onClose: () => window.open(params.url, '_blank'),
    });
  });
  return <></>;
};

UrlActionToastMessage.propTypes = {
  params: PropTypes.string.isRequired,
};

export default UrlActionToastMessage;
