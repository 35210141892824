import React from 'react';
import classNames from 'classnames';
import {
  Text,
} from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import { constants } from '@lake-superior/core';
import NotificationsFilterBadge from './NotificationsFilterBadge';

const NotificationsFilterOption = ({
  currentNotificationStatus,
  targetNotificationStatus,
  totalNotifications,
  onFilterChange,
  translateIdTitle,
}) => (
  <span
    onClick={() => {
      if (currentNotificationStatus !== targetNotificationStatus) {
        onFilterChange(targetNotificationStatus);
      }
    }}
    onKeyDown={() => null}
    tabIndex={-1}
    role="button"
    className={classNames('filters-option', { active: currentNotificationStatus === targetNotificationStatus })}
  >
    <Text translateId={translateIdTitle} />
    <NotificationsFilterBadge
      totalNotifications={totalNotifications}
    />
  </span>
);

NotificationsFilterOption.propTypes = {
  currentNotificationStatus: PropTypes.oneOf(
    Object.values(constants.NOTIFICATION_STATUS),
  ).isRequired,
  targetNotificationStatus: PropTypes.oneOf(
    Object.values(constants.NOTIFICATION_STATUS),
  ).isRequired,
  totalNotifications: PropTypes.number.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  translateIdTitle: PropTypes.string.isRequired,
};

export default NotificationsFilterOption;
