import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Text,
  FormHandler,
  SubmitButton,
  inlineAlertStatus,
  InlineAlert,
  TrackedTextField,
  IdentityActions,
} from '@lake-superior/ui-core';
import { tools } from '@lake-superior/core';
import TranslateKeys from '../../locales/keys';
import { useSecurityQuestions } from '../../hooks';
import { GetSecurityQuestions, VerifySecurityQuestionsAnswers } from './Helpers/IdentityServiceAPIs';
import { getSecurityQuestionsSelector, verifySecurityQuestionsAnswersSelector } from '../../redux/listeners/identityListener';

const SecurityQuestionsAnswer = () => {
  const securityQuestions = useSelector(IdentityActions.securityQuestionsSelector);
  const verifyQuestionsResult = useSelector(IdentityActions.verifyQuestionsResultSelector);
  const getSecurityQuestions = useSelector(getSecurityQuestionsSelector);
  const verifySecurityQuestionsAnswers = useSelector(verifySecurityQuestionsAnswersSelector);

  const dispatch = useDispatch();
  const history = useHistory();
  const options = useSecurityQuestions();
  const location = useLocation();
  const { forgotPasswordUserName } = location.state;

  const changeQuestionsHandler = () => {
    GetSecurityQuestions(dispatch, forgotPasswordUserName);
  };

  useEffect(() => {
    if (tools.isDefinedAndNotNullAndNotEmpty(verifyQuestionsResult?.oneTimeGuid)) {
      history.replace({
        pathname: '/auth/CreateNewPasswordForSecurityQuestions',
        state: {
          backTo: 'auth/ForgotPassword',
        },
      });
    }
  }, [history, verifyQuestionsResult]);

  return (
    <div className="create-new-password">
      <div className="bodyContainer">
        <div className="formAnswerSecurityQuestions use-ui-core">
          <div className="information center-xs">
            <span className="title">
              <Text
                translateId={TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.TITLE}
              />
            </span>
            <span className="instructions">
              <Text
                translateId={
                  TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.INSTRUCTIONS
                }
              />
            </span>
          </div>

          {getSecurityQuestions.error?.hasError === true && (
            <div className="use-ui-core error-alert">
              <InlineAlert
                bodyTranslateId={getSecurityQuestions.messageTranslateKey}
                icon
                status={inlineAlertStatus.ERROR}
              />
            </div>
          )}

          {verifySecurityQuestionsAnswers.error?.hasError === true && (
            <div className="use-ui-core error-alert">
              <InlineAlert
                bodyTranslateId={verifySecurityQuestionsAnswers.messageTranslateKey}
                icon
                status={inlineAlertStatus.ERROR}
              />
            </div>
          )}
          <FormHandler
            onSubmit={(formData) => {
              const validations = [];

              return {
                validations,
                execute: () => new Promise((resolve, reject) => {
                  try {
                    const answers = Object.entries(formData).reduce(
                      (result, value) => result.concat(
                        [{
                          questionId: parseInt(value[0], 10),
                          answer: value[1],
                        }],
                      ), [],
                    );

                    VerifySecurityQuestionsAnswers(
                      dispatch, forgotPasswordUserName,
                      answers,
                    );

                    resolve();
                  } catch {
                    reject();
                  }
                }),
              };
            }}
          >
            <div className="inputs">
              {tools.isDefinedAndNotNullAndNotEmpty(securityQuestions)
              && securityQuestions.map((text, index) => {
                const questionNumber = text;
                const questionText = options.find(
                  (x) => x.value === (parseInt(questionNumber, 10)),
                )?.label;

                return (
                  <div
                    key={`question-${questionNumber}`}
                    className="question-handler"
                  >
                    <TrackedTextField
                      name={questionNumber}
                      translateId={
                        TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.INPUT.LABEL
                      }
                      translateData={{ index: index + 1, questionNumber, questionText }}
                      placeholderTranslateId={
                        TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.INPUT
                          .PLACEHOLDER
                      }
                      required
                      hideAsteriskMark
                    />

                    {questionNumber < securityQuestions.length && <hr />}
                  </div>
                );
              })}
            </div>

            <div className="submit-button">
              <SubmitButton
                dataTestId="buttonSubmit"
                translateId={
                  TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.BUTTONS.SUBMIT
                    .LABEL
                }
                style={ButtonProps.ButtonStyles.PRIMARY}
                size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                loadingTranslateId={
                  TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.BUTTONS.SUBMIT
                    .LOADING_TEXT
                }
              />
            </div>
          </FormHandler>
          <div className="change-questions center-xs">
            <Text
              className="text"
              translateId={
                TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.NEED_QUESTIONS
              }
            />

            <Button
              translateId={TranslateKeys.LOGIN.SECURITY_QUESTIONS.ANSWER.CHANGE_QUESTIONS}
              style={ButtonProps.ButtonStyles.LINK}
              onClick={changeQuestionsHandler}
            />

          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityQuestionsAnswer;
