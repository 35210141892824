import React from 'react';
import { Button, ButtonProps, Auth } from '@lake-superior/ui-core';
import { useHistory } from 'react-router-dom';
import MFEMenu from '../Menu/MFEMenu';
import translateKeys from '../../locales/keys';

const GetLoginComponent = () => {
  const history = useHistory();

  return (
    <div className="use-ui-core">
      <Button
        className="login"
        onClick={() => history.push('/auth/login')}
        style={ButtonProps.ButtonStyles.PRIMARY}
        translateId={translateKeys.shell.GENERAL.LOGIN}
      />
    </div>
  );
};
const getLogOutComponent = (userName, hideAvatar, avatarIcon, userNameInitials) => (
  <MFEMenu
    hideAvatar={hideAvatar}
    avatarIcon={avatarIcon}
    userNameInitials={userNameInitials}
  />
);

const LoginButton = () => (
  <Auth.Login
    logInComponent={GetLoginComponent}
    logOutComponent={getLogOutComponent}
  />
);

export default LoginButton;
