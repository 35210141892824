/* eslint-disable no-console */
import { isFunction } from 'lodash';
import { tools } from '@lake-superior/core';
import {
  pendoconfig, environment, pendoRegion,
} from '../config/appsettings.json';
import { getSession } from '../Components/Auth/Helpers/session';

const setupPendoMetadata = () => {
  const { pendo } = window;
  if (pendoconfig.enabled
    && (environment.toLowerCase() === 'production' || environment.toLowerCase() === 'uat')
    && pendo && isFunction(pendo.isReady)
  ) {
    const session = getSession();
    const pendoActualInfo = {
      visitor: {
        ...pendoconfig.metadata.visitor,
      },
      account: {
        ...pendoconfig.metadata.account,
      },
    };
    if (tools.isDefinedAndNotNullAndNotEmpty(session)) {
      const { user: { userId, phone, email } } = session;
      pendoActualInfo.visitor.id = tools.formatPendoVistorId(environment, userId, pendoRegion);
      if (tools.isDefinedAndNotNullAndNotEmpty(email)) {
        pendoActualInfo.visitor.email = email;
      }
      if (tools.isDefinedAndNotNullAndNotEmpty(phone)) {
        pendoActualInfo.visitor.phone = phone;
      }
    }
    try {
      if (pendo.isReady() === false && pendo.isInitialized !== true) {
        pendo.initialize(pendoActualInfo);
        pendo.isInitialized = true;
      } else {
        pendo.identify(pendoActualInfo);
      }
    } catch (ex) {
      console.error('There was an error sending pendo metadata', ex);
    }
  }
};

/*
  PT - The following code is not ours. It is code from Pendo.
  The Pendo code does not adhere to our PeopleReady linting standards
  hence the disabled rules.

  At some point the following Pendo code should be rewritten so that it
  adheres to our PeopleReady linting standards.

*/
// This funcion may not make sense,
// but it comes from pendo's doc at: https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-Guide-To-Installing-Pendo
/* eslint-disable no-multi-assign */
const installPendo = () => {
  if (pendoconfig.enabled === true) {
    const { apikey } = pendoconfig;
    ((p, e, n, d, o) => {
      let v = null;
      let w;
      let x;
      let y = null;
      let z = null;
      // eslint-disable-next-line no-param-reassign
      o = p[d] = p[d] || {};
      // eslint-disable-next-line no-param-reassign
      o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        // eslint-disable-next-line no-loop-func
        ((m) => {
          // eslint-disable-next-line no-param-reassign
          o[m] = o[m] || (() => {
            // eslint-disable-next-line no-undef
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          });
        })(v[w]);
      }
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.pendo.io/agent/static/${apikey}/pendo.js`;
      // eslint-disable-next-line prefer-destructuring
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');

    const initializePendo = () => {
      if (isFunction(window.pendo?.isReady)) {
        setupPendoMetadata();
      } else {
        setTimeout(initializePendo, 1000);
      }
    };

    setTimeout(initializePendo, 1000);
  }
};

export {
  installPendo,
  setupPendoMetadata,
};
