import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, ButtonProps, FormHandler, Text, TrackedTextField,
  FieldTypes, SubmitButton, TextHelperTypes, InlineAlert, inlineAlertStatus,
} from '@lake-superior/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { tools, phoneTools } from '@lake-superior/core';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import translateKeys from '../../locales/keys';
import { needHelpInfo } from '../../config/appsettings.json';
import { ForgotPasswordByPhone } from './Helpers/IdentityServiceAPIs';
import { forgotPasswordStateSelector } from '../../redux/listeners/identityListener';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const forgotPasswordState = useSelector(forgotPasswordStateSelector);
  const [showHelpLoginIn, setShowHelpLoginIn] = useState(false);
  const location = useLocation();
  const [username, setUsername] = useState(location?.state?.resetPhone);

  const redirectoToValidatePassword = useCallback((phonenumber) => {
    history.replace({
      pathname: '/auth/ValidatePasswordByPhone',
      state: {
        phonenumber,
        backTo: 'auth/ResetPassword',
      },
    });
  }, [history]);

  useEffect(() => {
    if (forgotPasswordState.error?.hasError === false) {
      redirectoToValidatePassword(forgotPasswordState.thunkPayload);
    }
  }, [history, forgotPasswordState, redirectoToValidatePassword]);

  useEffect(() => {
    if (!tools.isPropertyDefinedAndNotNullAndNotEmpty(location, 'state.resetPhone')) {
      history.replace('/auth/login');
    }
  }, [history, location]);

  return (
    <div className="create-new-password">
      <div className="bodyContainer">
        <div className={classNames('formAreaForgotPassword', 'use-ui-core', { hideme: showHelpLoginIn })}>
          <FormHandler
            triggerOnEnter
            resolveWith={(state) => !state.identity.isLoading}
            onSubmit={(formData) => {
              const validations = [];

              if (!tools.isDefinedAndNotNullAndNotEmpty(formData.phoneNumber)) {
                validations.push({
                  translateId: translateKeys.LOGIN.GENERIC_ERROR,
                  severity: TextHelperTypes.ERROR,
                });
              }

              return {
                validations,
                execute: () => new Promise((resolve, reject) => {
                  try {
                    ForgotPasswordByPhone(dispatch, formData.phoneNumber);
                    resolve();
                  } catch (error) {
                    reject();
                  }
                }),
              };
            }}
          >
            <div>
              <div className="formAreaForgotPassword-container">
                <div className="row center-xs">
                  <span className="forgotpass">
                    <Text translateId={translateKeys.LOGIN.RESETPASSWORD_BY_PHONE.TITLE.LABEL} />
                  </span>
                  <span className="message">
                    <Text translateId={translateKeys.LOGIN.RESETPASSWORD_BY_PHONE.MESSAGE.LABEL} />
                  </span>
                </div>

                {forgotPasswordState.error?.hasError === true && (
                <div className="use-ui-core">
                  <InlineAlert
                    bodyTranslateId={forgotPasswordState.messageTranslateKey}
                    icon
                    status={inlineAlertStatus.ERROR}
                  />
                </div>
                )}

                <div className="use-ui-core">
                  <div className="trackfieldContainer">
                    <TrackedTextField
                      name="phoneNumber"
                      type={FieldTypes.TELEPHONE}
                      value={username}
                      controlledWith={setUsername}
                      customInputMode={FieldTypes.TEXT}
                      translateId={translateKeys.LOGIN.RESETPASSWORD_BY_PHONE.INPUT.LABEL}
                      placeholderTranslateId={translateKeys.LOGIN
                        .RESETPASSWORD_BY_PHONE.INPUT.PLACEHOLDER}
                      required
                      translateIdForRequiredValidation={translateKeys.LOGIN
                        .RESETPASSWORD_BY_PHONE.INPUT_ERROR.LABEL}
                      applyThisValidation={(value) => {
                        const checkingPhoneNumber = value.value;
                        if (!tools.isPropertyDefinedAndNotNullAndNotEmpty(checkingPhoneNumber)) {
                          return [];
                        }
                        return [{
                          translateId: translateKeys.LOGIN
                            .RESETPASSWORD_BY_PHONE.INPUT_ERROR.LABEL,
                          severity: TextHelperTypes.ERROR,
                        }];
                      }}
                    />
                  </div>
                </div>

                <div className="submit-button use-ui-core">
                  <SubmitButton
                    translateId={translateKeys.LOGIN
                      .RESETPASSWORD_BY_PHONE.SENDMECODE_BUTTON.LABEL}
                    style={ButtonProps.ButtonStyles.PRIMARY}
                    size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                  />
                </div>
                <div>
                  <center>
                    <Button
                      translateId={translateKeys.LOGIN.RESETPASSWORD_BY_EMAIL.ALREADY_HAVE_CODE}
                      style={ButtonProps.ButtonStyles.LINK}
                      onClick={() => redirectoToValidatePassword(username)}
                    />
                  </center>
                </div>

                <span className="sendmecode use-ui-core">
                  <span className="help">
                    <Text translateId={translateKeys.LOGIN
                      .RESETPASSWORD_BY_PHONE.SENDMECODE_HELP.LABEL}
                    />
                  </span>
                  <Button
                    translateId={translateKeys.LOGIN
                      .RESETPASSWORD_BY_PHONE.SENDMECODE_HELP.LINK}
                    style={ButtonProps.ButtonStyles.LINK}
                    onClick={() => setShowHelpLoginIn(true)}
                  />
                </span>
              </div>
            </div>
          </FormHandler>
        </div>

        <div className={classNames('helpLoginIn', 'formAreaForgotPassword-container', { showme: showHelpLoginIn })}>
          <div className="row center-xs">
            <span className="forgotpass">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.TITLE.LABEL} />
            </span>
            <span className="message">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.MESSAGE.LABEL} />
            </span>
          </div>
          <div className="forgotpass-phone-button use-ui-core">
            {/* todo: refactor to use a link */}
            <Button
              label={phoneTools.formatPhoneNumber(needHelpInfo.needHelpLoginPhone)}
              style={ButtonProps.ButtonStyles.PRIMARY}
              size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
              onClick={() => { window.location.href = `tel://${needHelpInfo.needHelpLoginPhone}`; }}
              icon={faPhoneAlt}
            />
          </div>
        </div>
      </div>
    </div>

  );
};

export default ForgotPassword;
