import React from 'react';
import { Route } from 'react-router-dom';
import AnswerSecurityQuestions from '../Auth/SecurityQuestionsAnswer';
import ContactBranch from '../Auth/ContactBranch';
import CreateNewPassword from '../Auth/CreateNewPassword';
import CreateNewPasswordForSecurityQuestions from '../Auth/CreateNewPasswordForSecurityQuestions';
import ForgotPassword from '../Auth/ForgotPasswordByPhone';
import LandingPage from '../LandingPage/LandingPage';
import Login from '../Auth/LoginByPhone';
import MFEWrapper from '../MFE/MFEWrapper';
import Register from '../Auth/RegistrationByPhone';
import ResetPassword from '../Auth/ResetPasswordByPhone';
import SearchUsers from '../Auth/Integration/SearchUsers';
import SecurityQuestions from '../Auth/SecurityQuestionsForm';
import SelectVerificationMethod from '../Auth/SelectVerificationMethod';
import ValidatePasswordByEmail from '../Auth/ValidatePasswordByEmail';
import ValidatePasswordByPhone from '../Auth/ValidatePasswordByPhone';

const ShellBody = () => (

  <div className="shell-body">
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/auth/ContactBranch" component={ContactBranch} />
    <Route exact path="/auth/CreateNewPassword" component={CreateNewPassword} />
    <Route exact path="/auth/CreateNewPasswordForSecurityQuestions" component={CreateNewPasswordForSecurityQuestions} />
    <Route exact path="/auth/ForgotPassword" component={ForgotPassword} />
    <Route exact path="/auth/login" component={Login} />
    <Route exact path="/auth/Register" component={Register} />
    <Route exact path="/auth/ResetPassword" component={ResetPassword} />
    <Route exact path="/auth/SearchUsers" component={SearchUsers} />
    <Route exact path="/auth/SecurityQuestions" component={SecurityQuestions} />
    <Route exact path="/auth/SecurityQuestionsAnswer" component={AnswerSecurityQuestions} />
    <Route exact path="/auth/SelectVerificationMethod" component={SelectVerificationMethod} />
    <Route exact path="/auth/ValidatePasswordByEmail" component={ValidatePasswordByEmail} />
    <Route exact path="/auth/ValidatePasswordByPhone" component={ValidatePasswordByPhone} />
    <MFEWrapper />
  </div>
);

export default ShellBody;
