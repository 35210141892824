import React from 'react';
import { Button, ButtonProps, Auth } from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const GetLoginComponent = (translateId) => {
  const history = useHistory();

  return (

    <div className="use-ui-core">
      <Button
        onClick={() => history.push('/auth/login')}
        style={ButtonProps.ButtonStyles.PRIMARY}
        translateId={translateId}
      />
    </div>
  );
};

const getLogOutComponent = () => (null);

const LoginButton = ({ translateId }) => (
  <Auth.Login
    logInComponent={() => GetLoginComponent(translateId)}
    logOutComponent={getLogOutComponent}
  />
);

LoginButton.propTypes = {
  translateId: PropTypes.string.isRequired,
};

export default LoginButton;
