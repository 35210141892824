import React from 'react';
import {
  Button, Text,
} from '@lake-superior/ui-core';
import { phoneTools } from '@lake-superior/core';
import { needHelpInfo } from '../../config/appsettings.json';
import translateKeys from '../../locales/keys';

const callSupport = () => {
  window.location.href = `tel: ${needHelpInfo.needHelpLoginPhone}`;
};

const emailSupport = () => {
  window.location.href = `mailto: ${needHelpInfo.needHelpLoginEmail}`;
};

const ContactBranch = () => (
  <div className="create-new-password">
    <div className="bodyContainer">
      <div className="formAreaContactBranch use-ui-core">
        <div className="formAreaContactBranch-initial-container">
          <div className="header">
            <Text translateId={translateKeys.LOGIN.CONTACT_BRANCH.PAGE_HEADER} />
          </div>
          <div className="line1">
            <Text translateId={translateKeys.LOGIN.CONTACT_BRANCH.CONTACT_STATEMENT} />
          </div>
        </div>
        <div className="formAreaContactBranch-mid-container">
          <div className="infoContainer row">
            <div className="col-xs-8">
              <div className="infoText1">
                <Text translateId={translateKeys.LOGIN.CONTACT_BRANCH.INFO_PHONE_HEADER} />
              </div>
              <div className="infoText2">
                <Text>{phoneTools.formatPhoneNumber(needHelpInfo.needHelpLoginPhone)}</Text>
              </div>
            </div>
            <div className="col-xs-4">
              <Button
                onClick={() => callSupport()}
                translateId={translateKeys.LOGIN.CONTACT_BRANCH.BUTTON_CALL}
              />
            </div>
          </div>
        </div>
        <div className="formAreaContactBranch-mid-container">
          <div className="infoContainer row">
            <div className="col-xs-8">
              <div className="infoText1">
                <Text translateId={translateKeys.LOGIN.CONTACT_BRANCH.INFO_EMAIL_HEADER} />
              </div>
              <div className="infoText2">
                <Text>{needHelpInfo.needHelpLoginEmail}</Text>
              </div>
            </div>
            <div className="col-xs-4">
              <Button
                onClick={() => emailSupport()}
                translateId={translateKeys.LOGIN.CONTACT_BRANCH.BUTTON_EMAIL}
              />
            </div>
          </div>
        </div>
        <div className="formAreaContactBranch-end-container" />
      </div>
    </div>
  </div>
);

export default ContactBranch;
