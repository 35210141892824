import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  SidePanel,
  useKeeper,
  Text,
  DotMenu,
  Dialog,
  DialogProps,
  Icon,
  SidePanelSizes,
  useAutoCancelableInterval,
} from '@lake-superior/ui-core';
import {
  faEnvelopeOpen, faTimes, faSyncAlt, faFilter,
} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { constants } from '@lake-superior/core';
import NotificationsList from './NotificationsList';
import TranslateKeys from '../../locales/keys';
import NotificationsContext from './NotificationsContext';
import {
  deleteAllNotifications,
  toggleAllNotifications,
  totalNotificationsSelector,
  filterChange,
  reloadList,
  configSelector,
} from '../../redux/ducks/notifications';
import NotificationsFilter from './NotificationsFiter';
import { showMobileBannerSelector } from '../../redux/ducks/shell';

const calculateDeleteAllTranslateId = (currentNotificationStatus) => {
  switch (currentNotificationStatus) {
    case constants.NOTIFICATION_STATUS.NOT_READ:
      return TranslateKeys.shell.NOTIFICATIONS.CONFIRM_DELETE_NOTIFICATIONS_NOT_READ;
    case constants.NOTIFICATION_STATUS.READ:
      return TranslateKeys.shell.NOTIFICATIONS.CONFIRM_DELETE_NOTIFICATIONS_READ;
    default:
      return TranslateKeys.shell.NOTIFICATIONS.CONFIRM_DELETE_NOTIFICATIONS;
  }
};

const Notifications = ({ children }) => {
  const {
    enabled,
    pageSize,
    autoRefreshIntervalInSec,
    idleTimeToStopAutoRefreshInSec,
    targetType,
    badgeLimitNumber,
    badgeColor,
    iconColor,
    entityId,
  } = useSelector(configSelector);

  const dispatch = useDispatch();
  const { show: isBannerOpen } = useSelector(showMobileBannerSelector);
  const { total, totalNotRead, totalRead } = useSelector(totalNotificationsSelector);
  const [isNotificationsPanelOpen, setIsNotificationsPanelOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [
    currentNotificationStatus,
    setCurrentNotificationStatus] = useState(constants.NOTIFICATION_STATUS.NOT_READ);
  const htmlTag = useKeeper(document.documentElement);

  const openConfirmationDeleteModal = () => {
    setIsModalOpen(true);
  };

  const closeConfirmationDeleteModal = () => {
    setIsModalOpen(false);
  };

  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const dispatchDeleteAllNotifications = () => {
    dispatch(deleteAllNotifications(currentNotificationStatus));
    closeConfirmationDeleteModal();
  };

  const reloadNotificationInterval = useCallback(() => {
    if (!isNotificationsPanelOpen && enabled) {
      dispatch(reloadList());
    }
  }, [dispatch, enabled, isNotificationsPanelOpen]);

  useAutoCancelableInterval(() => {
    reloadNotificationInterval();
  }, autoRefreshIntervalInSec,
  idleTimeToStopAutoRefreshInSec);

  const getNotificationActions = useCallback(() => {
    switch (currentNotificationStatus) {
      case constants.NOTIFICATION_STATUS.NOT_READ:
      default:

        if (totalNotRead === 0) {
          return [
            {
              translateId: TranslateKeys.shell.NOTIFICATIONS.REFRESH,
              action: () => {
                dispatch(reloadList());
              },
              icon: faSyncAlt,
            },
          ];
        }

        return [
          {
            translateId: TranslateKeys.shell.NOTIFICATIONS.MARK_ALL_AS_READ,
            action: () => {
              dispatch(toggleAllNotifications(constants.NOTIFICATION_STATUS.READ));
            },
            icon: faEnvelopeOpen,
          },
          {
            translateId: TranslateKeys.shell.NOTIFICATIONS.DELETE_ALL_NOT_READ,
            action: openConfirmationDeleteModal,
            icon: faTimes,
          },
        ];

      case constants.NOTIFICATION_STATUS.READ:
        if (totalRead === 0) {
          return [
            {
              translateId: TranslateKeys.shell.NOTIFICATIONS.REFRESH,
              action: () => {
                dispatch(reloadList());
              },
              icon: faSyncAlt,
            },
          ];
        }

        return [
          {
            translateId: TranslateKeys.shell.NOTIFICATIONS.MARK_ALL_AS_NOT_READ,
            action: () => {
              dispatch(toggleAllNotifications(constants.NOTIFICATION_STATUS.NOT_READ));
            },
            icon: faEnvelopeOpen,
          },
          {
            translateId: TranslateKeys.shell.NOTIFICATIONS.DELETE_ALL_READ,
            action: openConfirmationDeleteModal,
            icon: faTimes,
          },
        ];

      case constants.NOTIFICATION_STATUS.BOTH:
        if (total === 0) {
          return [
            {
              translateId: TranslateKeys.shell.NOTIFICATIONS.REFRESH,
              action: () => {
                dispatch(reloadList());
              },
              icon: faSyncAlt,
            },
          ];
        }

        return [
          {
            translateId: TranslateKeys.shell.NOTIFICATIONS.DELETE_ALL,
            action: openConfirmationDeleteModal,
            icon: faTimes,
          },
        ];
    }
  }, [dispatch, currentNotificationStatus, total, totalRead, totalNotRead]);

  const closeNotifications = () => {
    if (!isNotificationsPanelOpen) {
      return;
    }

    htmlTag.classList.remove('freezeWindow');
    setIsNotificationsPanelOpen(false);
  };

  const handleOnBellClick = () => {
    if (!isNotificationsPanelOpen) {
      htmlTag.classList.add('freezeWindow');
      setIsNotificationsPanelOpen(true);
    } else {
      closeNotifications();
    }
  };

  const handleOnFilterChangeFlat = (status) => {
    dispatch(filterChange(status));
    setCurrentNotificationStatus(status);
  };

  const handleOnFilterChangeMobile = (status) => {
    handleOnFilterChangeFlat(status);
    closeFilterModal();
  };

  return (

    <NotificationsContext.Provider value={{
      totalNotRead,
      handleOnBellClick,
      closeNotifications,
      enabled,
      badgeLimitNumber,
      badgeColor,
      iconColor,
    }}
    >
      {enabled && (
      <div className="use-ui-core notifications-wrapper">

        <div className={classNames('notifications', { isBannerOpen })}>
          <SidePanel
            onBlurAreaClick={closeNotifications}
            openSize={SidePanelSizes.s35}
            isOpen={isNotificationsPanelOpen}
          >
            <div className="notifications-header">
              <div className="notifications-header-title row">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 notifications-header-title-text">
                  <Text translateId={TranslateKeys.shell.NOTIFICATIONS.HEADER_TITLE} />
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 end-xs">
                  <div
                    tabIndex={-1}
                    onKeyUp={() => {}}
                    onClick={openFilterModal}
                    role="button"
                    className="filters-mobile-opener"
                  >
                    <Icon icon={faFilter} />
                  </div>
                  <NotificationsFilter
                    currentNotificationStatus={currentNotificationStatus}
                    handleOnFilterChange={handleOnFilterChangeFlat}
                    totalNotifications={total}
                    totalNotificationsNotRead={totalNotRead}
                    totalNotificationsRead={totalRead}
                  />
                </div>
              </div>
              <div className={classNames('notifications-header-actions', { isOpen: isNotificationsPanelOpen })}>
                <DotMenu options={getNotificationActions()} />
              </div>
            </div>
            <NotificationsList
              targetType={targetType}
              entityId={entityId}
              pageSize={pageSize}
              closeNotifications={closeNotifications}
              currentNotificationStatus={currentNotificationStatus}
            />
          </SidePanel>
        </div>

        <Dialog
          isOpen={isModalOpen}
          onCancel={closeConfirmationDeleteModal}
          onOk={dispatchDeleteAllNotifications}
          style={DialogProps.DialogStyles.WARNING}
          translateId={TranslateKeys.shell.NOTIFICATIONS.CONFIRM_DELETE_NOTIFICATIONS_TITLE}
        >
          <Text translateId={calculateDeleteAllTranslateId(currentNotificationStatus)} />
        </Dialog>

        <Dialog
          isOpen={isFilterModalOpen}
          onCancel={closeFilterModal}
          style={DialogProps.DialogStyles.DEFAULT}
          hideHeader
        >
          <div className="notifications-filters-mobile-options">
            <NotificationsFilter
              currentNotificationStatus={currentNotificationStatus}
              handleOnFilterChange={handleOnFilterChangeMobile}
              totalNotifications={total}
              totalNotificationsNotRead={totalNotRead}
              totalNotificationsRead={totalRead}
            />
          </div>
        </Dialog>
      </div>
      )}
      {children}
    </NotificationsContext.Provider>
  );
};

Notifications.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

export default Notifications;
