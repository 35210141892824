import React from 'react';
import nextId from 'react-id-generator';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { IdentityActions } from '@lake-superior/ui-core';
import { useSelector } from 'react-redux';
import { tools } from '@lake-superior/core';
import { mainMenuItemSelector } from '../../redux/ducks/shell';
import { isSectionAuthorized } from '../Auth/Helpers/common';
import MFEMenuOption from './MFEMenuOption';

const handleOnItemClick = (onItemClick) => {
  if (isFunction(onItemClick)) {
    onItemClick();
  }
};

const getClasses = (itemClasses, isMobile) => {
  let classes = ['shell-menu-mfe'];

  if (tools.isDefinedAndNotNullAndNotEmpty(itemClasses)) {
    if (isMobile && tools.isPropertyDefinedAndNotNullAndNotEmpty(itemClasses, 'mobile')) {
      classes = classes.concat(itemClasses.mobile);
    } else if (tools.isPropertyDefinedAndNotNullAndNotEmpty(itemClasses, 'desktop')) {
      classes = classes.concat(itemClasses.desktop);
    }
  }

  return classes.join(' ');
};

const buildMenu = (appRoutes, onItemClick, userRoles, isMobile) => appRoutes
  .map(({ showOnRoles, classes, ...rest }) => {
    if (isSectionAuthorized(showOnRoles, userRoles)) {
      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          onClick={() => handleOnItemClick(onItemClick)}
          onKeyDown={() => handleOnItemClick(onItemClick)}
          key={nextId('shell-menu-mfe')}
          className={getClasses(classes, isMobile)}
        >
          <MFEMenuOption
            onItemClick={() => handleOnItemClick(onItemClick)}
            isMobile={isMobile}
            {...rest}
          />
        </li>
      );
    }

    return null;
  });

const RouterListener = ({ onItemClick, isMobile, selector = mainMenuItemSelector }) => {
  const routes = useSelector(selector);
  const userRoles = useSelector(IdentityActions.userRolesSelector);

  return buildMenu(routes, onItemClick, userRoles, isMobile);
};

RouterListener.propTypes = {
  isMobile: PropTypes.bool,
  onItemClick: PropTypes.func,
};

RouterListener.defaultProps = {
  isMobile: false,
  onItemClick: null,
};

export default RouterListener;
