import { IdentityActions } from '@lake-superior/ui-core';
import { baseUrls, sessionTypeId } from '../../../config/appsettings.json';

const Login = async (dispatch, username, password) => {
  dispatch(
    IdentityActions.loginIdentityInit({
      Username: username,
      Password: password,
      SessionType: sessionTypeId,
      baseUrl: baseUrls.identityService,
    }),
  );
};

const ForgotPasswordByEmail = async (dispatch, username) => {
  dispatch(
    IdentityActions.forgotPasswordByEmailInit({
      Username: username, baseUrl: baseUrls.identityService,
    }),
  );
};

const ForgotPasswordByPhone = async (dispatch, username) => {
  dispatch(
    IdentityActions.forgotPasswordByPhoneInit({
      Username: username, baseUrl: baseUrls.identityService,
    }),
  );
};

const ResetPasswordChallenge = async (dispatch, username, challengeKey, password) => {
  dispatch(
    IdentityActions.resetPasswordChallengeInit({
      Username: username,
      ChallengeKey: challengeKey,
      Password: password,
      SessionType: sessionTypeId,
      baseUrl: baseUrls.identityService,
    }),
  );
};

const ResetPassword = async (dispatch, CommitPasswordToken, confirmationCode, newPassword) => {
  dispatch(
    IdentityActions.resetPasswordInit({
      CommitPasswordToken,
      ConfirmationCode: confirmationCode,
      NewPassword: newPassword,
      baseUrl: baseUrls.identityService,
    }),
  );
};

const RegisterByPhone = async (dispatch, phoneNumber, password, isVerified) => {
  dispatch(
    IdentityActions.registerByPhoneInit({
      PhoneNumber: phoneNumber,
      Password: password,
      IsVerified: isVerified,
      SessionTypes: sessionTypeId,
      baseUrl: baseUrls.identityService,
    }),
  );
};

const RegisterByEmail = async (dispatch, email, password, isVerified) => {
  dispatch(
    IdentityActions.registerByEmailInit({
      Email: email,
      Password: password,
      IsVerified: isVerified,
      SessionTypes: [sessionTypeId],
      baseUrl: baseUrls.identityService,
    }),
  );
};

const LogOut = async (dispatch) => {
  dispatch(
    IdentityActions.logoutInit({
      baseUrl: baseUrls.identityService,
    }),
  );
};

const GetProfileByContextId = async (dispatch, contextId) => {
  dispatch(
    IdentityActions.profileByContextIdInit({
      baseUrl: baseUrls.identityService,
      contextId: +contextId,
      sessionTypeId,
    }),
  );
};

const ClearChallenge = async (dispatch) => {
  dispatch(
    IdentityActions.clearChallenge(),
  );
};

const GetVerificationOptions = async (dispatch, userName) => {
  dispatch(
    IdentityActions.getVerificationOptionsInit({
      userName,
      baseUrl: baseUrls.identityService,
      SessionType: sessionTypeId,
    }),
  );
};

const GetSecurityQuestions = async (dispatch, userName) => {
  dispatch(
    IdentityActions.getSecurityQuestionsInit({
      userName, baseUrl: baseUrls.identityService,
    }),
  );
};

const VerifySecurityQuestionsAnswers = async (dispatch, userName, answers) => {
  dispatch(
    IdentityActions.verifySecurityQuestionsInit({
      userName, answers, baseUrl: baseUrls.identityService,
    }),
  );
};

const ChangePasswordByGuid = async (dispatch, oneTimeGuid, newPassword) => {
  dispatch(
    IdentityActions.changePasswordByGuidInit({
      oneTimeGuid, newPassword, baseUrl: baseUrls.identityService,
    }),
  );
};

const SaveSecurityQuestionAnswers = async (dispatch, answers) => {
  dispatch(
    IdentityActions.saveSecurityQuestionsAnswersInit({
      baseUrl: baseUrls.identityService,
      answers,
    }),
  );
};

const ResendVerificationCodeForForgotPassword = async (
  dispatch, commitPasswordToken, deliveryMethod) => {
  dispatch(
    IdentityActions.ResendVerificationCodeForForgotPasswordInit({
      commitPasswordToken,
      deliveryMethod,
      baseUrl: baseUrls.identityService,
    }),
  );
};

const SearchForUsers = async (
  dispatch,
  {
    firstName,
    lastName,
    phoneNumber,
    email,
    dateOfBirth,
    SSN,
  },
) => {
  dispatch(
    IdentityActions.searchUsersInit({
      baseUrl: baseUrls.identityService,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phoneNumber,
      Email: email,
      DateOfBirth: dateOfBirth,
      SSN,
    }),
  );
};

const SendVerificationCodeForForgotPassword = async (dispatch, username, deliveryMethod) => {
  dispatch(
    IdentityActions.SendVerificationCodeForForgotPasswordInit({
      username,
      baseUrl: baseUrls.identityService,
      SessionType: sessionTypeId,
      deliveryMethod,
    }),
  );
};

const RegisterByPhoneWithUserData = async (dispatch, {
  email,
  firstName,
  lastName,
  phoneNumber,
  associateId,
  platformId,
  registerStatus,
  dateOfBirth,
  SSN,
}) => {
  dispatch(
    IdentityActions.registerByPhoneWithUserDataInit({
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phoneNumber,
      AssociateId: associateId,
      RegisterStatus: registerStatus,
      PlatformId: platformId,
      DateOfBirth: dateOfBirth,
      SSN,
      baseUrl: baseUrls.identityService,
    }),
  );
};

const DoesProfileExistByParams = async (dispatch, {
  email,
  phoneNumber,
}) => {
  dispatch(IdentityActions.doesProfileExistByParamsInit({
    baseUrl: baseUrls.identityService,
    email,
    phoneNumber,
  }));
};

export {
  ForgotPasswordByEmail,
  ForgotPasswordByPhone,
  Login,
  LogOut,
  RegisterByEmail,
  SearchForUsers,
  RegisterByPhone,
  ResetPassword,
  ResetPasswordChallenge,
  GetProfileByContextId,
  ClearChallenge,
  GetVerificationOptions,
  GetSecurityQuestions,
  VerifySecurityQuestionsAnswers,
  ChangePasswordByGuid,
  SaveSecurityQuestionAnswers,
  SendVerificationCodeForForgotPassword,
  ResendVerificationCodeForForgotPassword,
  RegisterByPhoneWithUserData,
  DoesProfileExistByParams,
};
