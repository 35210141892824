import React from 'react';
import {
  Badge, BadgeProps, bgColorPalette,
} from '@lake-superior/ui-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const NotificationsFilterBadge = ({ totalNotifications }) => {
  let realTotalNotifications = totalNotifications;
  if (totalNotifications > 99) {
    realTotalNotifications = '99+';
  }

  return (
    <Badge
      type={classNames(BadgeProps.type.PILL)}
      extraClassNames={bgColorPalette.secondaryBlue3}
      variant={BadgeProps.variants.NOTIFICATION}
      value={realTotalNotifications}
    />
  );
};

NotificationsFilterBadge.propTypes = {
  totalNotifications: PropTypes.number.isRequired,
};

export default NotificationsFilterBadge;
