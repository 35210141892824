import React from 'react';
import { useSelector } from 'react-redux';
import { IdentityActions, Auth } from '@lake-superior/ui-core';
import classNames from 'classnames';
import ShellHeader from './Header';
import ShellFooter from './Footer';
import ShellBody from './Body';
import { setupPendoMetadata } from '../../pendo';

const ShellContainer = () => {
  const closingSession = useSelector(IdentityActions.closingSessionSelector);

  return (
    <div className={classNames('shell-container', { 'logout-blocker': closingSession })}>
      <ShellHeader />
      <ShellBody />
      <ShellFooter />
      <Auth.Centinel onAuthenticated={setupPendoMetadata} />
    </div>
  );
};

export default ShellContainer;
