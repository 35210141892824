import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { esTranslation, enTranslation } from '@lake-superior/ui-core';
import { esLocale, enLocale } from '@lake-superior/mfe-manager';
import { merge } from 'lodash';
import ShellEsLocale from './locales/translations/es.translations.json';
import ShellEnLocale from './locales/translations/en.translations.json';
import languages from './locales/languages';
import MobileBanner from './Components/MobileBanner/MobileBanner';
import ShellContainer from './Components/Shell/Container';
import ErrorPage from './Components/Errors';
import ActionRenderer from './Components/ActionRenderer/ActionRenderer';

const DefaultRoutes = () => (
  <Switch>
    <Route
      exact
      path="/error/:httpStatus"
      render={({ match }) => (
        <ErrorPage httpStatus={Number(match.params.httpStatus)} />
      )}
    />
  </Switch>
);

class App extends React.Component {
  constructor(props) {
    super(props);

    const defaultLanguage = window.localStorage.getItem('pr_preferredLanguageCode') || languages[0].code;
    const { addTranslationForLanguage, initialize } = this.props;

    initialize({
      languages,
      options: {
        renderToStaticMarkup,
        defaultLanguage,
      },
    });

    // Add the English and Spanish languages
    addTranslationForLanguage(merge(ShellEnLocale, enTranslation, enLocale), 'en');
    addTranslationForLanguage(merge(ShellEsLocale, esTranslation, esLocale), 'es');
  }

  render = () => (
    <>
      <MobileBanner />
      <ShellContainer />
      <DefaultRoutes />
      <ActionRenderer />
    </>
  );
}

App.propTypes = {
  activeLanguage: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  addTranslationForLanguage: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
};

App.defaultProps = {
  activeLanguage: {
    code: 'en',
    name: 'English',
  },
};

export default connect()(withLocalize(App));
