import React, { useCallback, useState, useEffect } from 'react';
import { Icon } from '@lake-superior/ui-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UserIcon from '../UserIcon/UserIcon';
import Logout from '../Auth/Logout';
import ContextMenuItemsListener from './ContextMenuItemsListener';

const classes = (isOpen) => classNames('context-menu-container-options',
  { 'context-menu-container-options-open': isOpen });

const ContextMenu = ({
  hideAvatar,
  userImage,
  userNameInitials,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const closeMenu = () => {
    setIsOpen(false);
  };
  const closeMenuItem = (event) => {
    if (!event.target.closest('.context-menu-container-log-out-container')) {
      closeMenu();
    }
  };

  useEffect(() => {
    const onBodyClick = (event) => {
      if (event.target.closest('.context-menu-container-options')) {
        return;
      }
      if (isOpen) {
        closeMenu(false);
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  });

  return (
    <div
      className="context-menu-container"
      role="button"
      tabIndex={-1}
      onKeyDown={toggleOpen}
      onClick={toggleOpen}
      style={{ zIndex: (isOpen ? '5' : null) }}
    >

      <UserIcon
        userImageText={userNameInitials}
        hide={hideAvatar}
        userImage={userImage}
      />

      <Icon icon={faEllipsisV} />

      <div
        className={classes(isOpen)}
        role="button"
        tabIndex={-1}
        onKeyDown={closeMenuItem}
        onClick={closeMenuItem}
      >
        <ul>
          <ContextMenuItemsListener />
          <li className="context-menu-container-log-out-container">
            <Logout />
          </li>
        </ul>
      </div>
    </div>
  );
};

ContextMenu.propTypes = {
  hideAvatar: PropTypes.bool,
  userImage: PropTypes.string,
  userNameInitials: PropTypes.string.isRequired,
};

ContextMenu.defaultProps = {
  hideAvatar: false,
  userImage: null,
};

export default ContextMenu;
