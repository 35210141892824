import React, { useState, useEffect, useMemo } from 'react';
import {
  Button, ButtonProps, FormHandler, Text, RadioButtonGroup, IdentityActions,
  BaseRadioButtonStyled, SubmitButton, TextHelperTypes, InlineAlert, inlineAlertStatus,
  IconProps,
} from '@lake-superior/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { tools, phoneTools, emailTools } from '@lake-superior/core';
import { faMobile, faEnvelope, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import translateKeys from '../../locales/keys';
import { needHelpInfo } from '../../config/appsettings.json';
import DeliveryMethods from '../../config/VerificationCodeDeliveryMethods.json';
import { SendVerificationCodeForForgotPassword, GetSecurityQuestions } from './Helpers/IdentityServiceAPIs';
import { verificationCodeForForgotStateSelector, getSecurityQuestionsSelector } from '../../redux/listeners/identityListener';
import { BY_EMAIL, BY_TEXT, BY_QUESTIONS } from './Helpers/common';
import { useSecurityQuestions } from '../../hooks';

const SelectVerificationMethod = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const verificationOptions = useSelector(IdentityActions.verificationOptionsSelector);
  const verificationCodeForForgotState = useSelector(verificationCodeForForgotStateSelector);
  const securityQuestionsState = useSelector(getSecurityQuestionsSelector);
  const [showHelpLoginIn, setShowHelpLoginIn] = useState(false);
  const [userSelectedDestination, setUserSelectedDestination] = useState({});
  const options = useSecurityQuestions();
  const location = useLocation();
  const { forgotPasswordUserName } = location.state;

  useEffect(() => {
    if (!tools.isDefinedAndNotNullAndNotEmpty(verificationOptions)
      || !tools.isDefinedAndNotNullAndNotEmpty(forgotPasswordUserName)) {
      history.replace({
        pathname: '/auth/login',

      });
    }
  }, [history, verificationOptions, forgotPasswordUserName]);

  const maskedEmailMemo = useMemo(() => {
    if (tools.isDefinedAndNotNullAndNotEmpty(verificationOptions)) {
      return verificationOptions.email
        && emailTools.getMaskedEmail(verificationOptions.email);
    }
    return null;
  }, [verificationOptions]);

  const maskedPhoneNumberMemo = useMemo(() => {
    if (tools.isDefinedAndNotNullAndNotEmpty(verificationOptions)) {
      return verificationOptions.phoneNumber
        && phoneTools.getMaskedPhone(verificationOptions.phoneNumber);
    }

    return null;
  }, [verificationOptions]);

  useEffect(() => {
    if (securityQuestionsState.error?.hasError === false) {
      history.replace({
        pathname: '/auth/SecurityQuestionsAnswer',
        state: {
          options,
          forgotPasswordUserName,
          backTo: 'auth/ForgotPassword',
        },
      });
    }
  }, [history, options, securityQuestionsState, forgotPasswordUserName]);

  useEffect(() => {
    if (verificationCodeForForgotState.error?.hasError === false) {
      if (userSelectedDestination.code === BY_EMAIL) {
        history.replace({
          pathname: '/auth/ValidatePasswordByEmail',
          state: {
            email: verificationOptions.email,
            backTo: 'auth/ForgotPassword',
          },
        });
      } else if (userSelectedDestination.code === BY_TEXT) {
        history.replace({
          pathname: '/auth/ValidatePasswordByPhone',
          state: {
            phonenumber: verificationOptions.phoneNumber,
            backTo: 'auth/ForgotPassword',
          },
        });
      }
    }
  }, [history, verificationCodeForForgotState, userSelectedDestination, verificationOptions]);

  const setVerificationDestination = (value) => {
    switch (value) {
      case BY_EMAIL:
        setUserSelectedDestination(
          {
            code: BY_EMAIL,
            value: verificationOptions.email,
            maskedValue: maskedEmailMemo,
          },
        );
        break;
      case BY_TEXT:
        setUserSelectedDestination(
          {
            code: BY_TEXT,
            value: verificationOptions.phoneNumber,
            maskedValue: maskedPhoneNumberMemo,
          },
        );
        break;
      case BY_QUESTIONS:
        setUserSelectedDestination(
          {
            code: BY_QUESTIONS,
            value: '',
          },
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!tools.isDefinedAndNotNullAndNotEmpty(verificationOptions)) {
      return;
    }

    if (verificationOptions.isPhoneVerified) {
      setUserSelectedDestination(
        {
          code: BY_TEXT,
          value: verificationOptions.phoneNumber,
          maskedValue: phoneTools.getMaskedPhone(verificationOptions.phoneNumber),
        },
      );
    } else if (verificationOptions.isEmailVerified) {
      setUserSelectedDestination(
        {
          code: BY_EMAIL,
          value: verificationOptions.email,
          maskedValue: emailTools.getMaskedEmail(verificationOptions.email),
        },
      );
    } else if (verificationOptions.hasSecurityQuestions) {
      setUserSelectedDestination(
        {
          code: BY_QUESTIONS,
          value: '',
        },
      );
    } else {
      setShowHelpLoginIn(true);
    }
  }, [verificationOptions, history]);

  if (!tools.isDefinedAndNotNullAndNotEmpty(verificationOptions)) {
    return <></>;
  }

  return (
    <div className="create-new-password">
      <div className="bodyContainer">
        <div className={classNames('formAreaForgotPassword', 'use-ui-core', { hideme: showHelpLoginIn })}>
          <FormHandler
            triggerOnEnter
            resolveWith={(state) => !state.identity.isLoading}
            onSubmit={() => {
              const validations = [];
              switch (userSelectedDestination.code) {
                case BY_EMAIL:
                  if (!tools.isDefinedAndNotNullAndNotEmpty(verificationOptions.email)) {
                    validations.push({
                      translateId: translateKeys.LOGIN.GENERIC_ERROR,
                      severity: TextHelperTypes.ERROR,
                    });
                  }

                  return {
                    validations,
                    execute: () => new Promise((resolve, reject) => {
                      try {
                        SendVerificationCodeForForgotPassword(
                          dispatch,
                          forgotPasswordUserName,
                          DeliveryMethods.Email,
                        );
                        resolve();
                      } catch (error) {
                        reject();
                      }
                    }),
                  };
                case BY_TEXT:
                  if (!tools.isDefinedAndNotNullAndNotEmpty(
                    verificationOptions.phoneNumber,
                  )) {
                    validations.push({
                      translateId: translateKeys.LOGIN.GENERIC_ERROR,
                      severity: TextHelperTypes.ERROR,
                    });
                  }

                  return {
                    validations,
                    execute: () => new Promise((resolve, reject) => {
                      try {
                        SendVerificationCodeForForgotPassword(
                          dispatch,
                          forgotPasswordUserName,
                          DeliveryMethods.SMS,
                        );
                        resolve();
                      } catch (error) {
                        reject();
                      }
                    }),
                  };
                case BY_QUESTIONS:
                  return {
                    validations,
                    execute: () => new Promise((resolve, reject) => {
                      try {
                        GetSecurityQuestions(dispatch, forgotPasswordUserName);
                        resolve();
                      } catch (error) {
                        reject();
                      }
                    }),
                  };
                default:
                  validations.push({
                    translateId: translateKeys.LOGIN.GENERIC_ERROR,
                    severity: TextHelperTypes.ERROR,
                  });
                  return {
                    validations,
                  };
              }
            }}
          >
            <div className="">
              <div className="formAreaForgotPassword-container">
                <div className="row center-xs">
                  <span className="verification-title">
                    <Text translateId={translateKeys.LOGIN.SELECT_VERIFICATION_METHOD.TITLE} />
                  </span>
                  <span className="verification-message">
                    {userSelectedDestination.code === BY_QUESTIONS ? (
                      <Text
                        translateId={
                          translateKeys.LOGIN.SELECT_VERIFICATION_METHOD
                            .MESSAGE_SECURITY_QUESTIONS
                        }
                      />
                    ) : (
                      <Text
                        translateId={
                          translateKeys.LOGIN.SELECT_VERIFICATION_METHOD.MESSAGE
                        }
                        translateData={{
                          destination: userSelectedDestination.maskedValue,
                        }}
                      />
                    )}
                  </span>
                </div>

                {verificationCodeForForgotState.error?.hasError === true && (
                  <div className="use-ui-core error-alert">
                    <InlineAlert
                      bodyTranslateId={verificationCodeForForgotState.messageTranslateKey}
                      icon
                      status={inlineAlertStatus.ERROR}
                    />
                  </div>
                )}

                {securityQuestionsState.error?.hasError === true && (
                  <div className="use-ui-core error-alert">
                    <InlineAlert
                      bodyTranslateId={securityQuestionsState?.messageTranslateKey}
                      icon
                      status={inlineAlertStatus.ERROR}
                    />
                  </div>
                )}

                {verificationOptions.error?.hasError === true && (
                  <div className="use-ui-core">
                    <InlineAlert
                      bodyTranslateId={verificationOptions.messageTranslateKey}
                      icon
                      status={inlineAlertStatus.ERROR}
                    />
                  </div>
                )}

                <div className="verification-options">
                  <RadioButtonGroup
                    name="Method"
                    value={userSelectedDestination.code}
                    vertical
                    onChange={({ value }) => setVerificationDestination(value)}
                  >
                    {verificationOptions.isPhoneVerified ? (
                      <BaseRadioButtonStyled
                        className="row center-xs"
                        value={BY_TEXT}
                        label="By text"
                        translateId={translateKeys.LOGIN.SELECT_VERIFICATION_METHOD.BY_TEXT}
                        id="cmbPhone"
                        icon={faMobile}
                        iconSize={IconProps.IconSizes.LARGE}
                      />
                    ) : <></>}
                    {verificationOptions.isEmailVerified ? (
                      <BaseRadioButtonStyled
                        className="row center-xs"
                        value={BY_EMAIL}
                        label="By email"
                        translateId={translateKeys.LOGIN.SELECT_VERIFICATION_METHOD.BY_EMAIL}
                        id="cmbEmail"
                        icon={faEnvelope}
                        iconSize={IconProps.IconSizes.LARGE}
                      />
                    ) : <></>}
                    {verificationOptions.hasSecurityQuestions ? (
                      <BaseRadioButtonStyled
                        className="row center-xs"
                        value={BY_QUESTIONS}
                        label="Security questions"
                        translateId={translateKeys.LOGIN.SELECT_VERIFICATION_METHOD.BY_QUESTIONS}
                        id="cmbQuestions"
                        icon={faQuestionCircle}
                        iconSize={IconProps.IconSizes.LARGE}
                      />
                    ) : <></>}
                  </RadioButtonGroup>
                </div>

                <div className="submit-button use-ui-core">
                  <SubmitButton
                    translateId={userSelectedDestination.code === BY_QUESTIONS ? translateKeys.LOGIN
                      .SELECT_VERIFICATION_METHOD.ANSWER_QUESTIONS_BUTTON.LABEL
                      : translateKeys.LOGIN.SELECT_VERIFICATION_METHOD.SENDMECODE_BUTTON.LABEL}
                    style={ButtonProps.ButtonStyles.PRIMARY}
                    size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                  />
                </div>

                <span className="sendmecode use-ui-core">
                  <span className="help">
                    <Text translateId={translateKeys.LOGIN
                      .SELECT_VERIFICATION_METHOD.SENDMECODE_HELP.LABEL}
                    />
                  </span>
                  <Button
                    translateId={translateKeys.LOGIN
                      .SELECT_VERIFICATION_METHOD.SENDMECODE_HELP.LINK}
                    style={ButtonProps.ButtonStyles.LINK}
                    onClick={() => setShowHelpLoginIn(true)}
                  />
                </span>
              </div>
            </div>
          </FormHandler>
        </div>

        <div className={classNames('helpLoginIn', 'formAreaForgotPassword-container', { showme: showHelpLoginIn })}>

          <div className="row center-xs">
            <span className="forgotpass">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.TITLE.LABEL} />
            </span>
            <span className="message">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.MESSAGE.LABEL} />
            </span>
          </div>
          <div className="forgotpass-phone-button use-ui-core">
            {/* todo: refactor to use a link */}
            <Button
              label={phoneTools.formatPhoneNumber(needHelpInfo.needHelpLoginPhone)}
              style={ButtonProps.ButtonStyles.PRIMARY}
              size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
              onClick={() => { window.location.href = `tel://${needHelpInfo.needHelpLoginPhone}`; }}
              icon={faPhoneAlt}
            />
          </div>
        </div>
      </div>
    </div>

  );
};

export default SelectVerificationMethod;
