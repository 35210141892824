import React, { useCallback } from 'react';
import MFE from '@lake-superior/mfe-manager';
import ShellConfig from '../../config/ShellConfig';
import withSession from '../../HOC/withSession';

const MFEWrapper = () => {
  const mfes = useCallback(() => ShellConfig.mfes.map(({ mfe }) => (
    <MFE
      key={mfe}
      name={mfe}
      showOnRoute
    />
  )), []);

  return (
    <div className="shell-mfe-wrapper">
      {mfes()}
    </div>
  );
};

export default withSession(MFEWrapper);
