import React, { useCallback, useState } from 'react';
import {
  Icon, Text,
} from '@lake-superior/ui-core';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import TranslateKeys from '../../locales/keys';
import { LogOut } from './Helpers/IdentityServiceAPIs';

const Logout = () => {
  const [loggingOut, setLoggingOut] = useState(false);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    if (loggingOut === false) {
      LogOut(dispatch);
    }

    setLoggingOut(true);
  }, [dispatch, loggingOut]);

  return (
    <div
      role="button"
      onKeyDown={logOut}
      onClick={logOut}
      tabIndex={-1}
    >
      <Icon icon={faSignOut} />
      <Text translateId={(loggingOut)
        ? TranslateKeys.shell.NAVBAR.LOGGING_OUT
        : TranslateKeys.shell.NAVBAR.LOG_OUT}
      />
    </div>
  );
};

export default Logout;
