import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Text, Button, ButtonProps } from '@lake-superior/ui-core';
import TranslateKeys from '../../locales/keys';
import error404 from '../../assets/img/Error/404.png';
import error500 from '../../assets/img/Error/500.png';
import jobStackLogoBlue from '../../assets/img/Landing/JobStack-logo-blue.png';
import { errorPageConfig } from '../../config/appsettings.json';

const getErrorConfig = (errorNumber) => {
  switch (errorNumber) {
    case 404:
      return {
        title: TranslateKeys.shell.ERROR_PAGE.CODE_404_TITLE,
        description: TranslateKeys.shell.ERROR_PAGE.CODE_404_DESCRIPTION,
        image: error404,
      };

    case 500:
      return {
        title: TranslateKeys.shell.ERROR_PAGE.CODE_500_TITLE,
        description: TranslateKeys.shell.ERROR_PAGE.CODE_500_DESCRIPTION,
        image: error500,
      };

    default:
      return {
        title: TranslateKeys.shell.ERROR_PAGE.DEFAULT_TITLE,
        description: TranslateKeys.shell.ERROR_PAGE.DEFAULT_DESCRIPTION,
        image: error404,
      };
  }
};

const Error = ({ httpStatus }) => {
  const { description, title, image } = getErrorConfig(httpStatus);

  return (
    <div className="use-ui-core errorpage">
      <img
        src={jobStackLogoBlue}
        className="errorpage-jobstack-logo"
        alt="JobStack Logo"
      />
      <div className="errorpage-content">
        <img src={image} className="errorpage-loupe" alt="Loupe" />
        <h1>{httpStatus}</h1>
        <h3>
          <Text translateId={title} />
        </h3>
        <p>
          <Text translateId={description} />
          {errorPageConfig.contact}
        </p>
        <Link to={errorPageConfig.redirectUrl}>
          <Button
            style={ButtonProps.ButtonStyles.PRIMARY}
            translateId={TranslateKeys.shell.ERROR_PAGE.BACK_BUTTON}
          />
        </Link>
      </div>
    </div>
  );
};

Error.propTypes = {
  httpStatus: PropTypes.number.isRequired,
};

export default Error;
