import { dynatrace } from '../config/appsettings.json';

const initializeDynatrace = () => {
  if (!dynatrace.enabled) return;

  const script = document.createElement('script');
  script.src = dynatrace.url;
  script.async = true;

  document.body.appendChild(script);
};

export default initializeDynatrace;
