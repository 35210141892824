import React from 'react';
import PropTypes from 'prop-types';
import { constants } from '@lake-superior/core';
import TranslateKeys from '../../locales/keys';
import NotificationsFilterOption from './NotificationsFilterOption';

const NotificationsFilter = ({
  currentNotificationStatus,
  handleOnFilterChange,
  totalNotifications,
  totalNotificationsNotRead,
  totalNotificationsRead,
}) => (
  <div className="filters-container use-ui-core">
    <NotificationsFilterOption
      currentNotificationStatus={currentNotificationStatus}
      targetNotificationStatus={constants.NOTIFICATION_STATUS.NOT_READ}
      onFilterChange={handleOnFilterChange}
      totalNotifications={totalNotificationsNotRead}
      translateIdTitle={TranslateKeys.shell.NOTIFICATIONS.FILTER_NOT_READ}
    />

    <NotificationsFilterOption
      currentNotificationStatus={currentNotificationStatus}
      targetNotificationStatus={constants.NOTIFICATION_STATUS.READ}
      onFilterChange={handleOnFilterChange}
      totalNotifications={totalNotificationsRead}
      translateIdTitle={TranslateKeys.shell.NOTIFICATIONS.FILTER_READ}
    />

    <NotificationsFilterOption
      currentNotificationStatus={currentNotificationStatus}
      targetNotificationStatus={constants.NOTIFICATION_STATUS.BOTH}
      onFilterChange={handleOnFilterChange}
      totalNotifications={totalNotifications}
      translateIdTitle={TranslateKeys.shell.NOTIFICATIONS.FILTER_ALL}
    />

  </div>
);

NotificationsFilter.propTypes = {
  currentNotificationStatus: PropTypes.oneOf(
    Object.values(constants.NOTIFICATION_STATUS),
  ).isRequired,
  handleOnFilterChange: PropTypes.func.isRequired,
  totalNotifications: PropTypes.number.isRequired,
  totalNotificationsNotRead: PropTypes.number.isRequired,
  totalNotificationsRead: PropTypes.number.isRequired,
};

export default NotificationsFilter;
