import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Text, Icon, bgColorPalette, textColorPalette,
} from '@lake-superior/ui-core';
import { NavLink, Route } from 'react-router-dom';
import { faChevronDown, faChevronUp, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { tools } from '@lake-superior/core';
import { isArray } from 'lodash';
import nextId from 'react-id-generator';
import classNames from 'classnames';
import MFEMenuSection from './MFEMenuSection';

const getIconContainer = (icon) => (
  <div className="shell-menu-mfe-option-icon">
    {icon}
  </div>
);

const getComponentToRender = ({
  items, icon, to, text, translateId, translateData, onItemClick,
  exact, activeClassName, isMobile, target,
}) => {
  const calculatedActiveClassName = to.includes('?')
    ? null
    : activeClassName;

  if (tools.isDefinedAndNotNullAndNotEmpty(items) && items.length > 0) {
    return (
      <>
        <NavLink
          to={to}
          exact={exact}
          target={target}
          activeClassName={calculatedActiveClassName}
        >
          {icon && getIconContainer(icon)}

          <Text
            text={text}
            translateId={translateId}
            translateData={translateData}
          />

          <Icon className="toggleable-icon" icon={faChevronDown} />
          <Icon className="toggleable-icon open" icon={faChevronUp} />
        </NavLink>

        <MFEMenuSection onItemClick={onItemClick} items={items} />
      </>
    );
  }
  return (
    <NavLink
      to={to}
      exact={exact}
      target={target}
      activeClassName={calculatedActiveClassName}
    >
      {icon && getIconContainer(icon)}

      <Text
        text={text}
        translateId={translateId}
        translateData={translateData}
      />
      {isMobile && (
        <div className="use-ui-core shell-mobile-menu-item-icon-container">
          <Icon
            className={classNames('shell-mobile-menu-item-icon',
              bgColorPalette.secondaryTeal4, textColorPalette.secondaryTeal2)}
            icon={faChevronRight}
          />
        </div>
      )}
    </NavLink>
  );
};

const MFEMenuOption = ({
  showOnRoutes,
  excludeOnRoutes,
  ...props
}) => {
  const component = getComponentToRender(props);

  if (isArray(showOnRoutes) && showOnRoutes.length > 0) {
    return showOnRoutes.map((t) => <Route key={nextId('menuRouteLink')} path={t} render={() => component} />);
  }

  if (isArray(excludeOnRoutes) && excludeOnRoutes.length > 0) {
    return excludeOnRoutes.map((t) => (
      <Route
        key={nextId('menuRouteLink')}
        path="/"
        render={() => {
          if (window.location.pathname === t) {
            return null;
          }

          return component;
        }}
      />
    ));
  }

  return component;
};

MFEMenuOption.propTypes = {
  onItemClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  items: PropTypes.instanceOf(Object),
  text: PropTypes.string,
  translateId: PropTypes.string,
  translateData: PropTypes.instanceOf(Object),
  to: PropTypes.string,
  showOnRoutes: PropTypes.arrayOf(PropTypes.string),
  excludeOnRoutes: PropTypes.arrayOf(PropTypes.string),
  target: PropTypes.string,
  exact: PropTypes.bool,
};

MFEMenuOption.defaultProps = {
  onItemClick: null,
  items: null,
  icon: null,
  text: '',
  translateId: '',
  translateData: null,
  to: '',
  showOnRoutes: null,
  excludeOnRoutes: null,
  target: '',
  exact: false,
};

export default memo(MFEMenuOption, (
  prevProps,
  nextProps,
) => prevProps.appRoutes === nextProps.appRoutes
  && prevProps.mfe === nextProps.mfe);
