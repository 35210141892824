import { createSlice, createSelector } from '@reduxjs/toolkit';

const INITIAL_STATE = Object.freeze({
  searchUsersForm: {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: '',
    SSN: '',
  },
});

const IntegrationsDuck = createSlice({
  name: 'integrations',
  initialState: INITIAL_STATE,
  reducers: {
    updateSearchForm: (state, { payload }) => {
      state.searchUsersForm = { ...payload };
    },
  },
});

export const { updateSearchForm } = IntegrationsDuck.actions;

const _integrationsSelector = (state) => state.integrations;

export const searchUsersFormSelector = createSelector(
  _integrationsSelector,
  (integrations) => integrations.searchUsersForm,
);

export default IntegrationsDuck.reducer;
