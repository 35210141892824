import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Select,
  Text,
  inlineAlertStatus,
  InlineAlert,
  Textfield,
  IdentityActions,
} from '@lake-superior/ui-core';
import { tools } from '@lake-superior/core';
import { useDispatch, useSelector } from 'react-redux';
import TranslateKeys from '../../locales/keys';
import { minimumSecurityQuestionsRequired } from '../../config/appsettings.json';
import { SaveSecurityQuestionAnswers } from './Helpers/IdentityServiceAPIs';
import { securityQuestionsSelector } from '../../redux/listeners/identityListener';
import { useSecurityQuestions } from '../../hooks';
import ShellConfig from '../../config/ShellConfig';

const SecurityQuestionsForm = () => {
  const history = useHistory();
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const options = useSecurityQuestions();
  const state = useSelector(securityQuestionsSelector);
  const isAuthenticated = useSelector(IdentityActions.isAuthenticatedSelector);
  const isLoading = useSelector(IdentityActions.isLoadingSelector);
  const hasSecurityQuestions = useSelector(IdentityActions.hasSecurityQuestionsSelector);
  const dispatch = useDispatch();

  const handleQuestionChange = ({ value, question, key }) => {
    let obj = securityQuestions.find((x) => x.questionNumber === question);

    if (obj) {
      obj[key] = value;
    } else {
      obj = { [key]: value, questionNumber: question };
    }

    setSecurityQuestions([
      ...securityQuestions.filter((x) => x.questionNumber !== question),
      { ...obj },
    ]);
  };

  const handleSubmit = async () => {
    const answers = securityQuestions.map(
      ({ questionNumber, ...rest }) => rest,
    );
    await SaveSecurityQuestionAnswers(dispatch, answers);
  };

  const isSubmitDisabled = isLoading
    || securityQuestions.length < minimumSecurityQuestionsRequired
    || securityQuestions.some(
      ({ answer, questionId }) => !tools.isDefinedAndNotNullAndNotEmpty(answer)
        || !tools.isDefinedAndNotNullAndNotEmpty(questionId),
    );

  useEffect(() => {
    if (state.error?.hasError === false) {
      history.push(ShellConfig.redirecTo);
    }
  }, [history, state]);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);

  useEffect(() => {
    if (
      tools.isDefinedAndNotNullAndNotEmpty(hasSecurityQuestions) && hasSecurityQuestions === true
    ) {
      history.push(ShellConfig.redirecTo);
    }
  }, [hasSecurityQuestions, history]);

  return (
    <div className="securityQuestions">
      <div className="bodyContainer">
        <div className="formAreaSecurityQuestions use-ui-core">
          <div className="row center-xs">
            <span className="title">
              <Text
                translateId={TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.TITLE}
              />
            </span>
            <span className="instructions">
              <Text
                translateId={
                  TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.INSTRUCTIONS
                }
              />
            </span>
          </div>

          {state.error?.hasError === true && (
            <div className="use-ui-core error-alert">
              <InlineAlert
                bodyTranslateId={state.messageTranslateKey}
                icon
                status={inlineAlertStatus.ERROR}
              />
            </div>
          )}

          <div className="inputs">
            {new Array(minimumSecurityQuestionsRequired)
              .fill(undefined)
              .map((_, index) => {
                const question = index + 1;

                return (
                  <>
                    <div
                      key={`question-${question}`}
                      className="question-handler"
                    >
                      <Select
                        placeholderTranslateId={
                          TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.INPUTS
                            .PLACEHOLDER.QUESTION
                        }
                        translateId={
                          TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.INPUTS.LABEL
                            .QUESTION
                        }
                        translateData={{ question }}
                        required
                        hideAsteriskMark
                        options={options.filter((x) => !securityQuestions
                          .some((s) => s.questionId === x.value))}
                        onChange={({ value }) => handleQuestionChange({ value, question, key: 'questionId' })}
                      />

                      <Textfield
                        translateId={
                          TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.INPUTS.LABEL
                            .ANSWER
                        }
                        placeholderTranslateId={
                          TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.INPUTS
                            .PLACEHOLDER.ANSWER
                        }
                        required
                        hideAsteriskMark
                        onChange={({ value }) => handleQuestionChange({ value, question, key: 'answer' })}
                      />
                    </div>
                    {question < minimumSecurityQuestionsRequired ? <hr /> : <hr className="last" />}
                  </>
                );
              })}
          </div>

          <div className="submit-button use-ui-core">
            <Button
              dataTestId="buttonSubmit"
              disabled={isSubmitDisabled}
              translateId={
                TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.BUTTONS.SUBMIT.LABEL
              }
              style={ButtonProps.ButtonStyles.PRIMARY}
              size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
              loadingTranslateId={
                TranslateKeys.LOGIN.SECURITY_QUESTIONS.FORM.BUTTONS.SUBMIT
                  .LOADING_TEXT
              }
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityQuestionsForm;
