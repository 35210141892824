import { configCodes } from '@lake-superior/ui-core';
import { baseUrls } from '../config/appsettings.json';

const useSecurityQuestions = () => {
  const { SecurityQuestions, isFetching } = configCodes.useSecurityQuestionsCodes({
    baseUrl: baseUrls.configService,
  });

  if (isFetching) return [];

  return SecurityQuestions.options;
};

export default useSecurityQuestions;
