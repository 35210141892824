import React, { useState, useEffect } from 'react';
import {
  Button, ButtonProps, FormHandler, Text,
  SubmitButton, TextHelperTypes, InlineAlert,
  inlineAlertStatus,
  Dialog,
  DialogProps,
} from '@lake-superior/ui-core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { tools, phoneTools } from '@lake-superior/core';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import translateKeys from '../../locales/keys';
import { needHelpInfo, findLocationsUrl } from '../../config/appsettings.json';
import { GetVerificationOptions } from './Helpers/IdentityServiceAPIs';
import { getVerificationOptionsSelector } from '../../redux/listeners/identityListener';

import ForgotPasswordTextField from './ForgotPasswordTextField';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getVerificationOptionsState = useSelector(getVerificationOptionsSelector);
  const [showHelpLoginIn, setShowHelpLoginIn] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (tools.isDefinedAndNotNullAndNotEmpty(phoneNumber)
      && getVerificationOptionsState.error?.hasError === false) {
      history.replace({
        pathname: '/auth/SelectVerificationMethod',
        state: {
          forgotPasswordUserName: phoneNumber,
          backTo: 'auth/ForgotPassword',
        },
      });
    } else if (getVerificationOptionsState.error?.hasError) {
      setIsOpen(true);
    }
  }, [history, getVerificationOptionsState, phoneNumber]);

  const dialogOnCancelHandler = () => {
    history.push('/auth/SearchUsers');
  };

  const dialogOnOkHandler = () => {
    window.location.href = findLocationsUrl;
  };

  return (
    <div className="create-new-password">
      <div className="use-ui-core">
        <div className="unable-to-reset-password-dialog">
          <Dialog
            buttonsPosition={DialogProps.ButtonsPosition.CENTER}
          // eslint-disable-next-line max-len
            cancelButtonTranslateId={translateKeys.LOGIN.FORGOTPASSWORD_BY_EMAIL.UNABLE_TO_RESET_PASSWORD_DIALOG.CRATE_ACCOUNT_BUTTON}
            cancelStyle={ButtonProps.ButtonStyles.PRIMARY}
            okStyle={ButtonProps.ButtonStyles.CTABLUE2}
            isOpen={isOpen}
            closeButton={false}
          // eslint-disable-next-line max-len
            okButtonTranslateId={translateKeys.LOGIN.FORGOTPASSWORD_BY_EMAIL.UNABLE_TO_RESET_PASSWORD_DIALOG.FIND_LOCATIONS_BUTTON}
            onOk={dialogOnOkHandler}
            onCancel={dialogOnCancelHandler}
            size={DialogProps.DialogSizes.SMALL}
            style={DialogProps.DialogStyles.DEFAULT}
          // eslint-disable-next-line max-len
            translateId={translateKeys.LOGIN.FORGOTPASSWORD_BY_EMAIL.UNABLE_TO_RESET_PASSWORD_DIALOG.TITLE}
          >
            <Text
              // eslint-disable-next-line max-len
              translateId={translateKeys.LOGIN.FORGOTPASSWORD_BY_EMAIL.UNABLE_TO_RESET_PASSWORD_DIALOG.TEXT}
              className="text"
            />
            <Text
              // eslint-disable-next-line max-len
              translateId={translateKeys.LOGIN.FORGOTPASSWORD_BY_EMAIL.UNABLE_TO_RESET_PASSWORD_DIALOG.TEXT2}
              className="text"
            />
          </Dialog>
        </div>
      </div>
      <div className="bodyContainer">
        <div className={classNames('formAreaForgotPassword', 'use-ui-core', { hideme: showHelpLoginIn })}>
          <FormHandler
            triggerOnEnter
            resolveWith={(state) => !state.identity.isLoading}
            onSubmit={(formData) => {
              const validations = [];

              if (!tools.isDefinedAndNotNullAndNotEmpty(formData.phonenumber)
              || !tools.isValidNumber(+formData.phonenumber)) {
                validations.push({
                  translateId: translateKeys.LOGIN.GENERIC_ERROR,
                  severity: TextHelperTypes.ERROR,
                });
              }

              return {
                validations,
                execute: () => new Promise((resolve, reject) => {
                  try {
                    setPhoneNumber(formData.phonenumber);
                    GetVerificationOptions(dispatch, formData.phonenumber);
                    resolve();
                  } catch (error) {
                    reject();
                  }
                }),
              };
            }}
          >
            <div className="">
              <div className="formAreaForgotPassword-container">
                <div className="row center-xs">
                  <span className="forgotpass">
                    <Text translateId={translateKeys.LOGIN.FORGOTPASSWORD_BY_PHONE.TITLE.LABEL} />
                  </span>
                  <span className="message">
                    <Text translateId={translateKeys.LOGIN.FORGOTPASSWORD_BY_PHONE.MESSAGE.LABEL} />
                  </span>
                </div>
                {getVerificationOptionsState.error?.hasError === true
                && (
                <div className="use-ui-core">
                  <InlineAlert
                    bodyTranslateId={getVerificationOptionsState.messageTranslateKey}
                    icon
                    status={inlineAlertStatus.ERROR}
                  />
                </div>
                )}

                <div className="use-ui-core">
                  <div className="trackfieldContainer">
                    <ForgotPasswordTextField />
                  </div>
                </div>

                <div className="submit-button use-ui-core">
                  <SubmitButton
                    dataTestId="buttonSubmit"
                    translateId={translateKeys.LOGIN
                      .FORGOTPASSWORD_BY_PHONE.NEXT_BUTTON.LABEL}
                    style={ButtonProps.ButtonStyles.PRIMARY}
                    size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                  />
                </div>

                <span className="sendmecode use-ui-core">
                  <span className="help">
                    <Text translateId={translateKeys.LOGIN
                      .FORGOTPASSWORD_BY_PHONE.NEXT_HELP.LABEL}
                    />
                  </span>
                  <Button
                    translateId={translateKeys.LOGIN
                      .FORGOTPASSWORD_BY_PHONE.NEXT_HELP.LINK}
                    style={ButtonProps.ButtonStyles.LINK}
                    onClick={() => setShowHelpLoginIn(true)}
                  />
                </span>
              </div>
            </div>
          </FormHandler>
        </div>

        <div className={classNames('helpLoginIn', 'formAreaForgotPassword-container', { showme: showHelpLoginIn })}>
          <div className="row center-xs">
            <span className="forgotpass">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.TITLE.LABEL} />
            </span>
            <span className="message">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.MESSAGE.LABEL} />
            </span>
          </div>
          <div className="forgotpass-phone-button use-ui-core">
            {/* todo: refactor to use a link */}
            <Button
              label={phoneTools.formatPhoneNumber(needHelpInfo.needHelpLoginPhone)}
              style={ButtonProps.ButtonStyles.PRIMARY}
              size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
              onClick={() => { window.location.href = `tel://${needHelpInfo.needHelpLoginPhone}`; }}
              icon={faPhoneAlt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
