import React from 'react';
import { tools } from '@lake-superior/core';
import { InlineAlert } from '@lake-superior/ui-core';
import { isArray } from 'lodash';

const clearNavigationState = () => {
  window.history.replaceState({}, document.title);
};

const fromResetPassword = (location) => {
  if (tools.isPropertyDefinedAndNotNullAndNotEmpty(location, 'state.fromPasswordReset')) {
    clearNavigationState();
    return true;
  }
  return false;
};

const invalidInvitation = (location) => {
  if (tools.isPropertyDefinedAndNotNullAndNotEmpty(location, 'state.invalidInvitation')) {
    clearNavigationState();
    return true;
  }
  return false;
};

const invalidChallengeKeyResetPass = (location) => {
  if (tools.isPropertyDefinedAndNotNullAndNotEmpty(location, 'state.invalidChallengeKeyResetPass')) {
    clearNavigationState();
    return true;
  }
  return false;
};

const getAlert = (translateId, status) => (
  <InlineAlert
    bodyTranslateId={translateId}
    icon
    status={status}
    autoClose={7000}
  />
);

const isSectionAuthorized = (showOnRoles, userRoles) => isArray(showOnRoles) && isArray(userRoles)
      && showOnRoles.some((showIn) => userRoles.some((userRole) => showIn === userRole));

// Verification method sent by email
const BY_EMAIL = 'by-email';

// Verification method sent by SMS
const BY_TEXT = 'by-text';

// Verification method by answering security questions
const BY_QUESTIONS = 'by-questions';

export {
  fromResetPassword,
  invalidInvitation,
  invalidChallengeKeyResetPass,
  getAlert,
  isSectionAuthorized,
  BY_EMAIL,
  BY_TEXT,
  BY_QUESTIONS,
};
