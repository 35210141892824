import React from 'react';
import PropTypes from 'prop-types';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import Notifications from '../Notifications/Notifications';

const MFEMenu = ({
  hideAvatar,
  userImage,
  userNameInitials,
}) => (
  <Notifications>
    <DesktopMenu
      hideAvatar={hideAvatar}
      userImage={userImage}
      userNameInitials={userNameInitials}
    />
    <MobileMenu />
  </Notifications>
);

MFEMenu.propTypes = {
  hideAvatar: PropTypes.bool,
  userImage: PropTypes.string,
  userNameInitials: PropTypes.string.isRequired,
};

MFEMenu.defaultProps = {
  hideAvatar: false,
  userImage: null,
};

export default React.memo(MFEMenu, () => true);
