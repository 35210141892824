import React from 'react';
import className from 'classnames';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IdentityActions } from '@lake-superior/ui-core';
import NavBar from '../NavBar/NavBar';
import LoginNavBar from '../Auth/LoginNavBar';
import { shellHeaderClassesSelector } from '../../redux/ducks/shell';
import { hideHeaderAt, loginHeaderAt } from '../../config/appsettings.json';

const ShellHeader = () => {
  const shellHeaderClasses = useSelector(shellHeaderClassesSelector);
  const loggedOut = useSelector(IdentityActions.loggedOutSelector);

  const isLoginNavBar = (path) => loginHeaderAt
    .some((t) => path.toLowerCase().includes(t.toLowerCase()));

  const selectNavBar = (path) => (
    (isLoginNavBar(path))
      ? <LoginNavBar />
      : (
        <header className={className('shell-header header-bg', shellHeaderClasses)}>
          <NavBar />
        </header>
      )
  );

  return (
    <Route
      render={({ location }) => (hideHeaderAt.includes(location.pathname) || loggedOut === true
        ? null
        : selectNavBar(location.pathname)
      )}
    />
  );
};

export default ShellHeader;
