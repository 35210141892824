import React, { useEffect, useState } from 'react';
import {
  Button, ButtonProps, FieldTypes, FormHandler, Text,
  TrackedToggleableIconTextField,
  SubmitButton, elementWrapperIconProps, IdentityActions,
  inlineAlertStatus, TextHelperTypes, FeatureFlag, configCodes,
} from '@lake-superior/ui-core';
import { tools } from '@lake-superior/core';
import { useHistory, useLocation } from 'react-router-dom';
import {
  faEye, faEyeSlash,
} from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import translateKeys from '../../locales/keys';
import { Login, ClearChallenge } from './Helpers/IdentityServiceAPIs';
import {
  sessionTypeId, userAgreementUrls, baseUrls,
  featureFlags,
} from '../../config/appsettings.json';
import { loginErrorSelector } from '../../redux/listeners/identityListener';
import {
  fromResetPassword,
  invalidInvitation,
  invalidChallengeKeyResetPass,
  getAlert,
} from './Helpers/common';
import LoginTextField from './LoginTextField';
import ShellConfig from '../../config/ShellConfig';

const configArray = [
  { type: FieldTypes.PASSWORD, icon: faEyeSlash },
  { type: FieldTypes.TEXT, icon: faEye },
];

const LoginPage = () => {
  const dispatch = useDispatch();
  const loginError = useSelector(loginErrorSelector);
  const isAuthenticated = useSelector(IdentityActions.isAuthenticatedSelector);
  const identityChallenge = useSelector(IdentityActions.identityChallengeSelector);
  // PT - LS-1535 - In UAT
  const hasSecurityQuestions = useSelector(IdentityActions.hasSecurityQuestionsSelector);
  const history = useHistory();
  const [username, setUsername] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Pass identitySessionType and AccessToken
    if (identityChallenge) {
      history.push('/auth/CreateNewPassword');
    }
  }, [history, identityChallenge]);

  useEffect(() => {
    if (invalidChallengeKeyResetPass(location)) {
      ClearChallenge(dispatch);
    }
  }, [dispatch, location]);

  // PT - LS-1535 - In UAT
  useEffect(() => {
    if (isAuthenticated && !hasSecurityQuestions) {
      history.push('/auth/SecurityQuestions');
    } else if (isAuthenticated && !identityChallenge) {
      history.push(ShellConfig.redirecTo);
    }
  }, [isAuthenticated, identityChallenge, history, hasSecurityQuestions]);

  // PT - LS-1535 - In UAT
  // useEffect(() => {
  //   if (isAuthenticated && !identityChallenge) {
  //     history.push(ShellConfig.redirecTo);
  //   }
  // }, [isAuthenticated, identityChallenge, history]);

  useEffect(() => {
    if (tools.isDefinedAndNotNullAndNotEmpty(loginError.faultCode)) {
      if (loginError.faultCode === 'IdentityService_400-20' && !invalidChallengeKeyResetPass(location)) {
        history.push({
          pathname: '/auth/ResetPassword',
          state: { resetPhone: username },
        });
      }
    }
  }, [history, location, loginError.faultCode, username]);

  return (
    <div className="create-new-password">
      <div className="bodyContainer">
        <div className="formAreaLogin use-ui-core">
          <FormHandler
            ignoreUnSaveItems
            triggerOnEnter
            resolveWith={(state) => {
              if (tools.isPropertyDefinedAndNotNullAndNotEmpty(state.identity, 'session.credentials.accessToken')) {
                return false;
              }

              return !state.identity.isLoading;
            }}
            onSubmit={({ phonenumber, password }) => {
              const validations = [];

              if (!tools.isDefinedAndNotNullAndNotEmpty(phonenumber)
                || !tools.isValidNumber(Number(phonenumber))) {
                validations.push({
                  translateId: translateKeys.LOGIN.GENERIC_ERROR,
                  severity: TextHelperTypes.ERROR,
                });
              }

              if (!tools.isDefinedAndNotNullAndNotEmpty(password)) {
                validations.push({
                  translateId: translateKeys.LOGIN.GENERIC_ERROR,
                  severity: TextHelperTypes.ERROR,
                });
              }

              return ({
                validations,

                execute: () => new Promise((resolve, reject) => {
                  try {
                    Login(dispatch, phonenumber, password, sessionTypeId);
                    resolve();
                  } catch (e) {
                    reject(e);
                  }
                }),
              });
            }}
          >
            <div className="">
              <div className="formAreaLogin-container">
                <div className="row center-xs">
                  <span className="greeting desktop-only">
                    <Text translateId={translateKeys.LOGIN.LOGINPAGE.GREETING.LABEL} />
                  </span>
                  <span className="title">
                    <Text translateId={translateKeys.LOGIN.LOGINPAGE.TITLE.LABEL} />
                  </span>
                </div>

                {tools.isDefinedAndNotNullAndNotEmpty(loginError.messageTranslateKey)
                  && getAlert(loginError.messageTranslateKey, inlineAlertStatus.ERROR)}

                {fromResetPassword(location)
                  && getAlert(translateKeys.LOGIN.LOGINPAGE.LOGIN_INPUT.PASSWORD_RESET,
                    inlineAlertStatus.SUCCESS)}

                {invalidInvitation(location)
                  && getAlert(translateKeys.LOGIN.LOGINPAGE.LOGIN_INPUT.INVALID_INVITATION,
                    inlineAlertStatus.WARNING)}

                {invalidChallengeKeyResetPass(location)
                  && getAlert(translateKeys.LOGIN.LOGINPAGE.LOGIN_INPUT.INVALID_CHALLENGE_KEY,
                    inlineAlertStatus.ERROR)}

                <div className="trackfieldContainer">
                  <LoginTextField controlledWith={setUsername} />
                </div>
                <div className="trackfieldContainer">
                  <div className="forgotpass-button">
                    <Button
                      dataTestId="buttonForgotPasword"
                      translateId={translateKeys.LOGIN.LOGINPAGE
                        .FORGOT_PASSWORD.LABEL}
                      style={ButtonProps.ButtonStyles.LINK}
                      onClick={() => {
                        history.push({
                          pathname: '/auth/ForgotPassword',
                        });
                      }}
                    />
                  </div>
                  <TrackedToggleableIconTextField
                    name="password"
                    dataTestId="password"
                    translateId={translateKeys.LOGIN.LOGINPAGE.PASSWORD_INPUT.LABEL}
                    placeholderTranslateId={translateKeys.LOGIN
                      .LOGINPAGE.PASSWORD_INPUT.PLACEHOLDER}
                    required
                    iconPosition={elementWrapperIconProps.INSIDE_RIGHT}
                    config={configArray}
                  />
                </div>

                <div className="create-button use-ui-core">
                  <SubmitButton
                    dataTestId="buttonSubmit"
                    translateId={translateKeys.shell.GENERAL.LOGIN}
                    style={ButtonProps.ButtonStyles.PRIMARY}
                    size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                    loadingTranslateId={translateKeys.LOGIN.LOGINPAGE.LOGIN_INPUT.SENDING_REQUEST}
                  />
                </div>

                <FeatureFlag
                  name={featureFlags.RegistrationButton}
                  configSvcBaseUrl={baseUrls.configService}
                  region={configCodes.REGIONS.US}
                  roles={['Associate']}
                >
                  <div className="register-button use-ui-core">
                    <Button
                      dataTestId="buttonRegister"
                      translateId={translateKeys.shell.GENERAL.REGISTER}
                      style={ButtonProps.ButtonStyles.SECONDARY}
                      size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                      onClick={() => {
                        history.push({
                          pathname: '/auth/SearchUsers',
                        });
                      }}
                    />
                  </div>
                </FeatureFlag>

                <div className="agreements-container">
                  <div>
                    <Text translateId={translateKeys.LOGIN.LOGINPAGE.AGREEMENTS_SECTION.LABEL} />
                    <br />
                    <Button
                      translateId={translateKeys.LOGIN.LOGINPAGE
                        .AGREEMENTS_SECTION.LABEL_TERMS_OF_USE}
                      style={ButtonProps.ButtonStyles.LINK}
                      redirecturl={userAgreementUrls.termsOfUse}
                      targetnewtab
                    />
                    <Text translateId={translateKeys.LOGIN.LOGINPAGE
                      .AGREEMENTS_SECTION.LABEL_CONJUCTION}
                    />
                    <Button
                      translateId={translateKeys.LOGIN.LOGINPAGE
                        .AGREEMENTS_SECTION.LABEL_PRIVACY_POLICY}
                      style={ButtonProps.ButtonStyles.LINK}
                      redirecturl={userAgreementUrls.privacyNotice}
                      targetnewtab
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormHandler>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
