import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import {
  FieldTypes, validateContainsUpperCase, validateContainsLowerCase,
  validateContainsSpecialChar, validateContainsNumber,
  validateStringMatch, validateStringLength,
} from '@lake-superior/ui-core';
import translateKeys from '../../../locales/keys';
import { userPasswordRequirements } from '../../../config/appsettings.json';

const FieldCheckListArray = [
  {
    translateId:
      translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD_VALIDATIONS
        .ERROR_LOWERCASE.LABEL,
    evalFunc: validateContainsLowerCase,
  },
  {
    translateId:
      translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD_VALIDATIONS
        .ERROR_UPPERCASE.LABEL,
    evalFunc: validateContainsUpperCase,
  },
  {
    translateId:
      translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD_VALIDATIONS
        .ERROR_SPECIAL_CHARACTER.LABEL,
    evalFunc: validateContainsSpecialChar,
  },
  {
    translateId:
      translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD_VALIDATIONS.ERROR_NUMBER
        .LABEL,
    evalFunc: validateContainsNumber,
  },
  {
    translateId:
      translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD_VALIDATIONS.ERROR_LENGTH
        .LABEL,
    evalFunc: validateStringLength,
  },
  {
    translateId:
      translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD_VALIDATIONS
        .ERROR_PASSWORD_MATCH.LABEL,
    evalFunc: validateStringMatch,
  },
];

const passwordEyeConfigArray = [
  { type: FieldTypes.PASSWORD, icon: faEyeSlash },
  { type: FieldTypes.TEXT, icon: faEye },
];

const resendVerificationCode = () => {
// code for sending the verification code again
};

const checkHasAllValidPasswordPolicy = (args) => {
  if (!args) return false;

  if (validateContainsLowerCase(args)
  && validateContainsUpperCase(args)
  && validateStringLength(args)
  && validateContainsNumber(args)
  && validateContainsSpecialChar(args)
  && validateStringMatch(args)) {
    return true;
  }

  return false;
};

const FieldCheckListArgs = (tempPassword, tempConfirmation) => ({
  string1: tempPassword,
  string2: tempConfirmation,
  minStringLength: userPasswordRequirements.minLength,
});

export {
  FieldCheckListArray,
  resendVerificationCode,
  passwordEyeConfigArray,
  checkHasAllValidPasswordPolicy,
  FieldCheckListArgs,
};
