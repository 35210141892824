import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { BrowserRouter } from 'react-router-dom';
import { AlertContainer, ExceptionManager, CountDownSession } from '@lake-superior/ui-core';
import { unregister } from './serviceWorker';
import configureStore from './redux/store/configure.store';
import { installPendo } from './pendo/index';
import initializeDynatrace from './dynatrace';
import MFECoordinator from './Components/MFE/MFECoordinator';
import { baseUrls, sessionMinutesExpirationTime } from './config/appsettings.json';

// ---------------------------------------------------------- CSS IMPORTS
import './styles/App.scss';
// ---------------------------------------------------------- CSS IMPORTS

const store = configureStore();

installPendo();
initializeDynatrace();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <div className="use-ui-core">
          <ExceptionManager identityServiceBaseUrl={baseUrls.identityService} />
          <AlertContainer />
        </div>
        <BrowserRouter>
          <MFECoordinator
            store={store}
          />
          <div className="use-ui-core">
            <CountDownSession
              identityServiceBaseUrl={baseUrls.identityService}
              showAtMinutes={sessionMinutesExpirationTime}
            />
          </div>
        </BrowserRouter>
      </LocalizeProvider>
    </Provider>

  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
