import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  thunkHelper, dateTools, tools, localStorageManager,
  constants,
} from '@lake-superior/core';
import { redirectUtils } from '@lake-superior/ui-core';
import appsettings from '../../config/appsettings.json';
import ShellConfig from '../../config/ShellConfig';

const internalUser = localStorageManager
  .getObjectLocalStorage(appsettings.localStorageKeys.internalUser) || {};

const INITIAL_STATE = {
  config: {
    enabled: ShellConfig.notificationsEnabled,
    pageSize: 5,
    autoRefreshIntervalInSec: 29,
    idleTimeToStopAutoRefreshInSec: 30 * 3,
    badgeLimitNumber: 100,
    badgeColor: null,
    iconColor: null,
    targetType: ShellConfig.notificationsTargetType,
    entityId: internalUser.userId,
  },
  deleted: [],
  touched: {},
  currentFilter: constants.NOTIFICATION_STATUS.NOT_READ,
  itemsPerPage: {},
  version: 1,
  deletingAll: false,
  touchingAll: false,
  totalRead: 0,
  totalNotRead: 0,
  lastFetchedDate: dateTools.getUTCNowDate().toISOString(),
  lastClicked: null,
};

const updateItemsPerPage = (state, result, currentPage, touchedNotificationId = null) => {
  const computedItems = state.itemsPerPage;

  if (tools.isDefinedAndNotNullAndNotEmpty(touchedNotificationId)) {
    delete computedItems[touchedNotificationId];
  }

  result.forEach(({ id }) => {
    computedItems[id] = currentPage;
  });

  state.itemsPerPage = computedItems;
};

const removeNotificationIdFromDelete = (state, id) => {
  const index = state.deleted.indexOf(id);
  if (index > -1) {
    state.deleted.splice(index, 1);
  }
};

const setStateOnAuth = (state, userId) => {
  state.totalNotRead = 0;
  state.totalRead = 0;
  state.deleted = [];
  state.touched = {};
  state.itemsPerPage = {};
  state.version++;
  state.deletingAll = false;
  state.touchingAll = false;
  state.config.entityId = userId;
};

const NotificationsDuck = createSlice({
  name: 'notifications',
  initialState: INITIAL_STATE,
  reducers: {

    // -------------------------------------- > Delete process
    deleteStart: (state, { thunkPayload: { id, isRead } }) => {
      state.deleted.push(id);

      if (isRead) {
        state.totalRead--;
      } else {
        state.totalNotRead--;
      }
    },
    deleteSuccess: (state, {
      thunkPayload: { id },
      payload: {
        totalRead,
        totalNotRead,
        touchedNotificationId,
        paged: {
          currentPage,
          result,
        },
      },
    }) => {
      updateItemsPerPage(state, result, currentPage, touchedNotificationId);

      removeNotificationIdFromDelete(state, id);

      state.totalNotRead = totalNotRead;
      state.totalRead = totalRead;
    },
    deleteFail: (state, { thunkPayload: { id, isRead } }) => {
      removeNotificationIdFromDelete(state, id);

      if (isRead) {
        state.totalRead++;
      } else {
        state.totalNotRead++;
      }
    },

    // -------------------------------------- > Delete all process
    deleteAllStart: (state, { thunkPayload: status }) => {
      state.deletingAll = true;

      if (status === constants.NOTIFICATION_STATUS.READ) {
        state.totalRead = 0;
      } else if (status === constants.NOTIFICATION_STATUS.NOT_READ) {
        state.totalNotRead = 0;
      } else {
        state.totalRead = 0;
        state.totalNotRead = 0;
      }
    },
    deleteAllSuccess: (state, {
      payload: {
        totalRead,
        totalNotRead,
      },
    }) => {
      state.totalNotRead = totalNotRead;
      state.totalRead = totalRead;
    },
    deleteAllFail: (state) => {
      state.deletingAll = false;
      state.version++;
    },

    // -------------------------------------- > Read process
    toggleStatusStart: (state, { thunkPayload: { identifier, isRead } }) => {
      state.touched[identifier] = isRead;

      if (!isRead) {
        state.totalNotRead--;
        state.totalRead++;
      } else {
        state.totalNotRead++;
        state.totalRead--;
      }
    },
    toggleStatusSuccess: (state, {
      thunkPayload: {
        redirectTo,
        redirectToExternal,
        metadata,
      }, payload: {
        totalRead,
        totalNotRead,
        touchedNotificationId,
        paged: {
          currentPage,
          result,
        },
      },
    }) => {
      updateItemsPerPage(state, result, currentPage, touchedNotificationId);

      state.totalNotRead = totalNotRead;
      state.totalRead = totalRead;

      delete state.touched[touchedNotificationId];

      if (!tools.isDefinedAndNotNullAndNotEmpty(redirectTo) || redirectTo.startsWith('/')) {
        return;
      }

      redirectUtils.redirectExternalMetadata(redirectTo,
        redirectToExternal,
        metadata.ExternalLinkQueryStringData);
    },
    toggleStatusFail: (state, { thunkPayload: { identifier, isRead } }) => {
      state.touched[identifier] = !isRead;

      if (!isRead) {
        state.totalNotRead++;
        state.totalRead--;
      } else {
        state.totalNotRead--;
        state.totalRead++;
      }
    },

    // -------------------------------------- > Toggle all process
    toggleAllStart: (state, { thunkPayload: status }) => {
      state.touchingAll = true;

      if (status === constants.NOTIFICATION_STATUS.READ) {
        state.totalRead += state.totalNotRead;
        state.totalNotRead = 0;
      } else {
        state.totalNotRead += state.totalRead;
        state.totalRead = 0;
      }
    },
    toggleAllSuccess: (state, {
      payload: {
        totalRead,
        totalNotRead,
      },
    }) => {
      state.totalNotRead = totalNotRead;
      state.totalRead = totalRead;
    },
    toggleAllFail: (state) => {
      state.touchingAll = false;
      state.version++;
    },

    // -------------------------------------- > Filter Change process
    filterChange: (state, { payload: status }) => {
      state.deletingAll = false;
      state.touchingAll = false;
      state.currentFilter = status;
    },

    // -------------------------------------- > Reload list process
    reloadList: (state) => {
      state.deleted = [];
      state.touched = {};
      state.itemsPerPage = {};
      state.version++;
      state.deletingAll = false;
      state.touchingAll = false;
    },
    reConfigureNotifications: (state, {
      payload: {
        enabled,
        badgeLimitNumber,
        badgeColor,
        iconColor,
        entityId,
      },
    }) => {
      if (tools.isDefinedAndNotNullAndNotEmpty(enabled)) {
        state.config.enabled = enabled;
      }

      if (tools.isDefinedAndNotNullAndNotEmpty(badgeLimitNumber)) {
        state.config.badgeLimitNumber = badgeLimitNumber;
      }

      if (tools.isDefinedAndNotNullAndNotEmpty(badgeColor)) {
        state.config.badgeColor = badgeColor;
      }

      if (tools.isDefinedAndNotNullAndNotEmpty(iconColor)) {
        state.config.iconColor = iconColor;
      }

      if (tools.isDefinedAndNotNullAndNotEmpty(entityId)) {
        state.config.entityId = entityId;
      }
    },
    updateLastNotificationClicked: (state, { payload: data }) => {
      state.lastClicked = data;
    },
  },
  extraReducers: {
    'infiniteScroll/nextPageFetchSuccess': (state, { payload, thunkPayload: { listName } }) => {
      if (listName === 'shellNotifications') {
        const {
          totalNotRead,
          totalRead,
          paged,
        } = payload;

        const {
          currentPage,
          result,
        } = paged;

        state.totalNotRead = totalNotRead;
        state.totalRead = totalRead;
        state.lastFetchedDate = dateTools.getUTCNowDate().toISOString();

        updateItemsPerPage(state, result, currentPage);
      }
    },
    'infiniteScroll/setInitialPaginationState': (state, { payload: listName }) => {
      if (listName === 'shellNotifications') {
        state.touched = {};
        state.deleted = [];
        state.itemsPerPage = {};
      }
    },
    'identity/resetPasswordChallengeSuccess': (state, {
      payload: {
        userId,
      },
    }) => {
      setStateOnAuth(state, userId);
    },
    'identity/loginSuccess': (state, {
      payload: {
        userId,
      },
    }) => {
      setStateOnAuth(state, userId);
    },
  },
});

NotificationsDuck.actions.deleteNotification = ({
  isRead,
  id,
}) => (dispatch, getState) => {
  const {
    notifications: {
      config: {
        pageSize,
        targetType,
        entityId,
      },
      itemsPerPage,
      currentFilter,
    },
  } = getState();

  thunkHelper(
    dispatch,
    [
      NotificationsDuck.actions.deleteStart({ isRead, id }),
      NotificationsDuck.actions.deleteSuccess({ id }),
      NotificationsDuck.actions.deleteFail({ isRead, id }),
    ],
    {
      method: 'delete',
      baseURL: appsettings.baseUrls.notificationsService,
      url: `EntityNotification/RemoveNotification?${tools.toQueryString({
        EntityId: entityId,
        NotificationId: id,
        NotificationStatus: isRead
          ? constants.NOTIFICATION_STATUS.NOT_READ
          : constants.NOTIFICATION_STATUS.READ,
        CurrentFilter: currentFilter,
        pageSize,
        page: itemsPerPage[id],
        targetType,
      })}`,
    },
  );
};

NotificationsDuck.actions.deleteAllNotifications = (status) => (dispatch, getState) => {
  const {
    notifications: {
      currentFilter,
      lastFetchedDate,
      config: {
        targetType,
        entityId,
      },
    },
  } = getState();

  thunkHelper(
    dispatch,
    [
      NotificationsDuck.actions.deleteAllStart(status),
      NotificationsDuck.actions.deleteAllSuccess(),
      NotificationsDuck.actions.deleteAllFail(),
    ],
    {
      method: 'delete',
      baseURL: appsettings.baseUrls.notificationsService,
      url: `EntityNotification/RemoveNotification/Range?${tools.toQueryString({
        EntityId: entityId,
        NotificationStatus: status,
        LastFetchedDate: lastFetchedDate,
        CurrentFilter: currentFilter,
        targetType,
      })}`,
    },
  );
};

NotificationsDuck.actions.toggleNotificationIsread = ({
  identifier,
  redirectTo,
  redirectToExternal,
  metadata,
  isRead,
}) => (dispatch, getState) => {
  const {
    notifications: {
      config: {
        pageSize,
        targetType,
        entityId,
      },
      itemsPerPage,
      currentFilter,
    },
  } = getState();

  thunkHelper(
    dispatch,
    [
      NotificationsDuck.actions.toggleStatusStart({
        identifier,
        isRead,
      }),
      NotificationsDuck.actions.toggleStatusSuccess({
        redirectTo,
        redirectToExternal,
        metadata,
      }),
      NotificationsDuck.actions.toggleStatusFail({ identifier, isRead }),
    ],
    {
      method: 'put',
      baseURL: appsettings.baseUrls.notificationsService,
      url: 'EntityNotification/UpdateNotificationStatus',
      data: {
        EntityId: entityId,
        NotificationId: identifier,
        NotificationStatus: isRead
          ? constants.NOTIFICATION_STATUS.NOT_READ
          : constants.NOTIFICATION_STATUS.READ,
        pageSize,
        CurrentFilter: currentFilter,
        page: itemsPerPage[identifier],
        targetType,
      },
    },
  );
};

NotificationsDuck.actions.toggleAllNotifications = (status) => (dispatch, getState) => {
  const {
    notifications: {
      currentFilter,
      lastFetchedDate,
      config: {
        targetType,
        entityId,
      },
    },
  } = getState();

  thunkHelper(
    dispatch,
    [
      NotificationsDuck.actions.toggleAllStart(status),
      NotificationsDuck.actions.toggleAllSuccess(),
      NotificationsDuck.actions.toggleAllFail(),
    ],
    {
      method: 'put',
      baseURL: appsettings.baseUrls.notificationsService,
      url: 'EntityNotification/UpdateNotificationStatus/Range',
      data: {
        EntityId: entityId,
        NotificationStatus: status,
        LastFetchedDate: lastFetchedDate,
        CurrentFilter: currentFilter,
        targetType,
      },
    },
  );
};

// Export the actions as named export
export const {
  deleteNotification,
  deleteAllNotifications,
  toggleNotificationIsread,
  toggleAllNotifications,
  filterChange,
  reloadList,
  updateLastNotificationClicked,
} = NotificationsDuck.actions;

const _notificationsSelector = (state) => state.notifications;

export const totalNotificationsSelector = createSelector(
  _notificationsSelector,
  (notifications) => ({
    totalRead: notifications.totalRead,
    totalNotRead: notifications.totalNotRead,
    total: notifications.totalRead + notifications.totalNotRead,
  }),
);

export const configSelector = createSelector(
  _notificationsSelector,
  (notifications) => notifications.config,
);

export const versionSelector = createSelector(
  _notificationsSelector,
  (notifications) => notifications.version,
);

export const deletedSelector = createSelector(
  _notificationsSelector,
  (notifications) => ({
    deleted: notifications.deleted,
    deletingAll: notifications.deletingAll,
  }),
);

export const touchedSelector = createSelector(
  _notificationsSelector,
  (notifications) => ({
    touched: notifications.touched,
    touchingAll: notifications.touchingAll,
  }),
);

export const lastClickedSelector = createSelector(
  _notificationsSelector,
  (notifications) => notifications.lastClicked,
);

// Export the reducer in the default export
export default NotificationsDuck.reducer;
