import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { tools } from '@lake-superior/core';

import { doesProfileExistByParamsSelector, registerByPhoneWithUserDataStateSelector, searchUsersStateSelector } from '../redux/listeners/identityListener';
import { isAssociateRegisteredStateSelector } from '../redux/ducks/associate';
import { DoesProfileExistByParams, RegisterByPhoneWithUserData } from '../Components/Auth/Helpers/IdentityServiceAPIs';
import { IsAssociateRegisteredByAssociateId } from '../Components/Auth/Helpers/AssociateServiceAPIs';
import { searchUsersFormSelector } from '../redux/ducks/integrations';

const RegisterStatus = Object.freeze({
  NewUser: 0,
  InterimAssociate: 1,
  PrismUser: 2,
});

const useRegisterByPhoneWithUserData = (callback) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const doesProfileExistByParamsState = useSelector(doesProfileExistByParamsSelector);
  const searchUsersForm = useSelector(searchUsersFormSelector);
  const searchUsersState = useSelector(searchUsersStateSelector);
  const registerState = useSelector(registerByPhoneWithUserDataStateSelector);
  const isAssociateRegisteredState = useSelector(isAssociateRegisteredStateSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [isRegisterFired, setIsRegisterFired] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(null);
  const [isDoesProfileExistsByParamsFired, setDoesProfileExistByParamsFired] = useState(false);

  const getMetadata = useCallback(() => {
    if (searchUsersState.error?.hasError === true) {
      return {
        isLoading: isLoading || searchUsersState.isSpinnerLoading,
        error: searchUsersState.error,
        translateId: searchUsersState.messageTranslateKey,
      };
    }

    if (registerState.error?.hasError === true) {
      return {
        isLoading: isLoading || searchUsersState.isSpinnerLoading,
        error: registerState.error,
        translateId: registerState.messageTranslateKey,
      };
    }

    if (isAssociateRegisteredState.error?.hasError === true) {
      return {
        isLoading: isLoading || isAssociateRegisteredState.isSpinnerLoading,
        error: isAssociateRegisteredState.error,
        translateId: isAssociateRegisteredState.messageTranslateKey,
      };
    }

    return {
      isLoading,
      error: null,
      translateId: null,
    };
  }, [isLoading, searchUsersState, registerState, isAssociateRegisteredState]);

  useEffect(() => {
    if (registerStatus === null) {
      if (doesProfileExistByParamsState.error?.hasError === false) {
        if (doesProfileExistByParamsState.response === false) {
          const searchResult = searchUsersState.payload;
          let associateId = null;

          if (!searchResult?.length) {
            // the user is new
            setRegisterStatus(RegisterStatus.NewUser);
          }

          if (searchResult?.length) {
            associateId = searchResult[0].associateId;
            if (associateId > 0) {
              // user is in Interim associate
              setRegisterStatus(RegisterStatus.InterimAssociate);
            } else {
              // user is in prism but not in JS2
              setRegisterStatus(RegisterStatus.PrismUser);
            }
          }
        } else {
          setIsLoading(false);
          callback('already-exists');
        }
      }
    }
  }, [
    callback,
    doesProfileExistByParamsState,
    searchUsersForm,
    searchUsersState,
    registerStatus,
    dispatch,
  ]);

  useEffect(() => {
    if (!isDoesProfileExistsByParamsFired) {
      const matches = searchUsersState.payload;
      if (searchUsersState.error?.hasError === false
        && tools.isDefinedAndNotNullAndNotEmpty(matches)) {
        if (matches.length <= 1) {
          DoesProfileExistByParams(dispatch, searchUsersForm);
          setIsLoading(true);
          setDoesProfileExistByParamsFired(true);
        }
      }
    }
  }, [
    searchUsersState,
    searchUsersForm,
    dispatch,
    callback,
    isDoesProfileExistsByParamsFired,
  ]);

  useEffect(() => {
    if (registerState.error?.hasError === false
      && tools.isDefinedAndNotNullAndNotEmpty(registerState.response)) {
      setIsLoading(false);
      callback('success');
    }

    if (registerState.error?.hasError === true) {
      // if account already exist
      if (registerState.messageTranslateKey.includes('400-61')) {
        callback('already-exists');
      } else {
        callback('error');
      }
    }
  }, [callback, registerState, history]);

  useEffect(() => {
    if (searchUsersState.error?.hasError === true) {
      if (searchUsersState.messageTranslateKey.includes('400-62')) {
        callback('error');
      }
    }
  }, [callback, searchUsersState]);

  useEffect(() => {
    if (registerStatus !== null && !isRegisterFired) {
      if (registerStatus === RegisterStatus.NewUser) {
        RegisterByPhoneWithUserData(dispatch, {
          ...searchUsersForm,
          associateId: null,
          platformId: null,
          registerStatus,
          dateOfBirth: searchUsersForm.dateOfBirth.replaceAll('/', '-'),
        });
        setIsRegisterFired(true);
      } else if (registerStatus === RegisterStatus.InterimAssociate) {
        IsAssociateRegisteredByAssociateId(dispatch, searchUsersState.payload[0].associateId);
      } else {
        const {
          associateId,
          platformId,
          ssn,
          dateOfBirth,
        } = searchUsersState.payload[0];
        RegisterByPhoneWithUserData(dispatch, {
          ...searchUsersForm,
          associateId,
          platformId,
          registerStatus,
          dateOfBirth,
          SSN: ssn,
        });
        setIsRegisterFired(true);
      }
    }
  }, [registerStatus, isRegisterFired, searchUsersState, dispatch, searchUsersForm]);

  useEffect(() => {
    if (!isRegisterFired) {
      if (isAssociateRegisteredState.error?.hasError === false) {
        if (isAssociateRegisteredState.response) {
          callback('already-exists');
        } else {
          const {
            associateId,
            platformId,
            ssn,
            dateOfBirth,
          } = searchUsersState.payload[0];
          RegisterByPhoneWithUserData(dispatch, {
            ...searchUsersForm,
            associateId,
            platformId,
            registerStatus,
            dateOfBirth,
            SSN: ssn,
          });
          setIsRegisterFired(true);
        }
      }
    }
  }, [
    isAssociateRegisteredState,
    callback,
    searchUsersState,
    searchUsersForm,
    dispatch,
    registerStatus,
    isRegisterFired,
  ]);

  return getMetadata();
};

export default useRegisterByPhoneWithUserData;
