import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NotificationCard,
  NotificationCardState,
  ReduxInfiniteScrollList,
  ListItem,
  Dialog,
  DialogProps,
  Text,
  redirectUtils,
} from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import { tools, constants } from '@lake-superior/core';
import { useHistory } from 'react-router-dom';
import { isBoolean } from 'lodash';
import TranslateKeys from '../../locales/keys';
import {
  deleteNotification,
  toggleNotificationIsread,
  touchedSelector,
  deletedSelector,
  versionSelector,
  updateLastNotificationClicked,
} from '../../redux/ducks/notifications';
import NotificationsListRefresh from './NotificationsListRefresh';
import appSettings from '../../config/appsettings.json';

const calculateState = (state) => {
  switch (state) {
    case constants.NOTIFICATION_STATE.ERROR:
      return NotificationCardState.Error;
    case constants.NOTIFICATION_STATE.SUCCESS:
      return NotificationCardState.Success;
    case constants.NOTIFICATION_STATE.WARNING:
      return NotificationCardState.Warning;
    case constants.NOTIFICATION_STATE.INFORMATION:
    default:
      return NotificationCardState.Info;
  }
};

const calculateEmptyListMessage = (currentNotificationStatus) => {
  switch (currentNotificationStatus) {
    case constants.NOTIFICATION_STATUS.READ:
      return TranslateKeys.shell.NOTIFICATIONS.NO_NOTIFICATIONS_READ;
    case constants.NOTIFICATION_STATUS.NOT_READ:
      return TranslateKeys.shell.NOTIFICATIONS.NO_NOTIFICATIONS_NOT_READ;
    default:
      return TranslateKeys.shell.NOTIFICATIONS.NO_NOTIFICATIONS;
  }
};

const calculateEndReachedListMessage = (currentNotificationStatus) => {
  switch (currentNotificationStatus) {
    case constants.NOTIFICATION_STATUS.READ:
      return TranslateKeys.shell.NOTIFICATIONS.LIST_END_REACHED_READ;
    case constants.NOTIFICATION_STATUS.NOT_READ:
      return TranslateKeys.shell.NOTIFICATIONS.LIST_END_REACHED_NOT_READ;
    default:
      return TranslateKeys.shell.NOTIFICATIONS.LIST_END_REACHED;
  }
};

const NotificationsList = ({
  pageSize,
  closeNotifications,
  currentNotificationStatus,
  targetType,
  entityId,
}) => {
  const deleteModalInitialProps = {
    isOpen: false,
    id: 0,
    isRead: false,
  };

  const dispatch = useDispatch();
  const { deleted, deletingAll } = useSelector(deletedSelector);
  const { touched, touchingAll } = useSelector(touchedSelector);
  const version = useSelector(versionSelector);
  const [deleteModalProps, setDeleteModalProps] = useState({
    ...deleteModalInitialProps,
  });

  const listContainer = useRef();

  const history = useHistory();

  const handleOnMarkUnReadClick = (identifier) => {
    dispatch(toggleNotificationIsread({
      identifier,
      redirectTo: null,
      redirectToExternal: null,
      metadata: null,
      isRead: true,
      currentNotificationStatus,
    }));
  };

  const handleOnActiveStatusChanged = (isRead, data) => {
    const { redirectTo, metadata, redirectToExternal } = data;

    if (!isRead) {
      dispatch(toggleNotificationIsread({ ...data, isRead, currentNotificationStatus }));
    }

    closeNotifications();

    if (tools.isDefinedAndNotNullAndNotEmpty(redirectTo)) {
      if (redirectTo.startsWith('/')) {
        history.push({
          pathname: redirectTo,
          state: metadata.InternalRoutePropsData || null,
        });
      } else if (!isRead) {
        redirectUtils.redirectExternalMetadata(redirectTo,
          redirectToExternal,
          metadata.ExternalLinkQueryStringData);
      }
    }

    // stop default behavior
    return true;
  };

  const handleOnClick = (data) => {
    dispatch(updateLastNotificationClicked({
      ...data,
      eventId: nextId('notificationClick-'),
    }));

    return false;
  };

  const closeDeleteModal = () => {
    setDeleteModalProps({
      ...deleteModalInitialProps,
    });
  };

  const dispatchDeleteNotification = () => {
    const { id, isRead } = deleteModalProps;
    dispatch(deleteNotification({ id, isRead, currentNotificationStatus }));
    closeDeleteModal();
  };

  const handleOnDelete = (isRead, id) => {
    setDeleteModalProps({
      isOpen: true,
      isRead,
      id,
    });
  };

  const calculateIsActive = (isRead, id) => {
    const touchedAs = touched[id];

    return isBoolean(touchedAs) ? touchedAs : !isRead;
  };

  const getNotificationCard = ({
    id,
    state,
    created,
    read: isRead,
    messageKey: message,
    subjectKey: subject,
    redirectToDisplayTextKey: redirectToDisplayText,
    ...rest
  }) => {
    if (deleted.indexOf(id) !== -1
      || (isBoolean(touched[id])
        && currentNotificationStatus !== constants.NOTIFICATION_STATUS.BOTH)) {
      return null;
    }

    return (
      <ListItem key={id}>
        <NotificationCard
          {...rest}
          message={message}
          subject={subject}
          redirectToDisplayText={redirectToDisplayText}
          active={calculateIsActive(isRead, id)}
          identifier={id}
          createdAtUTC={created}
          state={calculateState(state)}
          onActiveStatusChanged={handleOnActiveStatusChanged}
          onDelete={handleOnDelete}
          onMarkUnReadClick={handleOnMarkUnReadClick}
          onClick={handleOnClick}
        />
      </ListItem>
    );
  };

  return (
    <div ref={listContainer} className="notifications-panel">

      {(deletingAll || touchingAll) && (
        <NotificationsListRefresh
          touchingAll={touchingAll}
          deletingAll={deletingAll}
          currentNotificationStatus={currentNotificationStatus}
        />
      )}

      {(!deletingAll && !touchingAll) && (
        <>
          <ReduxInfiniteScrollList
            scrollContainerOverrideRef={listContainer}
            name="shellNotifications"
            baseUrl={appSettings.baseUrls.notificationsService}
            path={`EntityNotification/GetPagedNotifications?${tools.toQueryString({
              entityId,
              NotificationStatus: currentNotificationStatus,
              targetType,
              version,
            })}`}
            pageSize={pageSize}
            responseProp="paged"
            fetchedItemsRenderMap={getNotificationCard}
            cleanStateWhenUnmounted
            showRefreshButtonOnEndReached
            emptyListTextTranslateId={calculateEmptyListMessage(currentNotificationStatus)}
            endReachedTranslateId={calculateEndReachedListMessage(currentNotificationStatus)}
          />

          <Dialog
            isOpen={deleteModalProps.isOpen}
            onCancel={closeDeleteModal}
            onOk={dispatchDeleteNotification}
            style={DialogProps.DialogStyles.WARNING}
            translateId={TranslateKeys.shell.NOTIFICATIONS.CONFIRM_DELETE_NOTIFICATION_TITLE}
          >
            <Text translateId={TranslateKeys.shell.NOTIFICATIONS.CONFIRM_DELETE_NOTIFICATION} />
          </Dialog>
        </>
      )}
    </div>
  );
};

NotificationsList.propTypes = {
  pageSize: PropTypes.number.isRequired,
  closeNotifications: PropTypes.func.isRequired,
  entityId: PropTypes.number.isRequired,
  currentNotificationStatus: PropTypes.oneOf(
    Object.values(constants.NOTIFICATION_STATUS),
  ).isRequired,
  targetType: PropTypes.oneOf(Object.values(constants.NOTIFICATION_TARGET_TYPE)).isRequired,
};

export default NotificationsList;
