import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  thunkHelper,
  localStorageManager,
  tools, constants,
} from '@lake-superior/core';
import appsettings from '../../config/appsettings.json';
import ShellConfig from '../../config/ShellConfig';

const {
  TASK_STATUS,
  TASK_TARGET_TYPE,
} = constants;

const internalUser = localStorageManager
  .getObjectLocalStorage(appsettings.localStorageKeys.internalUser) || {};

const INITIAL_STATE = {
  config: {
    targetType: TASK_TARGET_TYPE.ASSOCIATE,
    entityId: internalUser.userId,
    enabled: ShellConfig.tasksEnabled,
  },
  Incomplete: 0,
};

const TasksDuck = createSlice({
  name: 'tasks',
  initialState: INITIAL_STATE,
  reducers: {
    // -------------------------------------- > Delete process
    getInCompleteTasksStart: () => {
    },
    getInCompleteTasksSuccess: (state, {
      payload: {
        totalIncomplete,
      },
    }) => {
      state.Incomplete = totalIncomplete;
    },
    getInCompleteTasksFail: () => {

    },
    configureArguments: (state, {
      payload: {
        targetType,
        entityId,
      },
    }) => {
      state.config.targetType = targetType;
      state.config.entityId = entityId;
    },
  },
  extraReducers: {
    'identity/loginSuccess': (state, { payload }) => {
      const {
        userId,
      } = payload;

      state.config.entityId = userId;
    },
    'identity/resetPasswordChallengeSuccess': (state, { payload }) => {
      const {
        userId,
      } = payload;

      state.config.entityId = userId;
    },
  },
});

TasksDuck.actions.getInCompleteTasks = () => (dispatch, getState) => {
  const {
    tasks: {
      config: {
        targetType,
      },
    },
  } = getState();

  thunkHelper(
    dispatch,
    [
      TasksDuck.actions.getInCompleteTasksStart(),
      TasksDuck.actions.getInCompleteTasksSuccess(),
      TasksDuck.actions.getInCompleteTasksFail(),
    ],
    {
      method: 'get',
      baseURL: appsettings.baseUrls.notificationsService,
      url: `EntityTask/GetPagedTasks?${tools.toQueryString({
        page: 1,
        pageSize: 1,
        TaskStatus: TASK_STATUS.OPEN,
        targetType,
      })}`,
    },
  );
};

// Export the actions as named export
export const {
  getInCompleteTasks,
} = TasksDuck.actions;

const _tasksSelector = (state) => state.tasks;

export const InCompleteSelector = createSelector(
  _tasksSelector,
  (tasks) => tasks.Incomplete,
);

export const TargetTypeSelector = createSelector(
  _tasksSelector,
  (tasks) => tasks.config.targetType,
);

export const EnabledSelector = createSelector(
  _tasksSelector,
  (tasks) => tasks.config.enabled,
);

// Export the reducer in the default export
export default TasksDuck.reducer;
