import React from 'react';
import PropTypes from 'prop-types';
import { useFeatureFlag } from '@lake-superior/ui-core';
import { baseUrls, featureFlags } from '../../config/appsettings.json';
import ContextMenu from './ContextMenu';
import RouterListener from './RouterListener';
import NotificationsBell from '../Notifications/NotificationsBell';
import TasksBell from '../Tasks/TasksBell';

const DesktopMenu = ({ hideAvatar, userImage, userNameInitials }) => {
  const enableNavigationOptions = useFeatureFlag({
    name: featureFlags.NavigationOptions,
    configSvcBaseUrl: baseUrls.configService,
    region: 'us',
    roles: ['Associate'],
  });

  return (
    <div
      className={`row ${
        enableNavigationOptions ? '' : 'col-xs-offset-10 col-2'
      } shell-menu`}
    >
      {enableNavigationOptions && (
        <>
          <div className="col-xs">
            <ul className="shell-menu-options">
              <RouterListener isMobile={false} />
            </ul>
          </div>
          <NotificationsBell desktop />
          <TasksBell desktop />
        </>
      )}
      <div className="shell-menu-context-menu-container">
        <ContextMenu
          hideAvatar={hideAvatar}
          userImage={userImage}
          userNameInitials={userNameInitials}
        />
      </div>
    </div>
  );
};

DesktopMenu.propTypes = {
  hideAvatar: PropTypes.bool,
  userImage: PropTypes.string,
  userNameInitials: PropTypes.string.isRequired,
};

DesktopMenu.defaultProps = {
  hideAvatar: false,
  userImage: null,
};

export default React.memo(DesktopMenu, () => true);
