import { thunkHelper } from '@lake-superior/core';
import { createSlice, createSelector } from '@reduxjs/toolkit';

const getTranslateKeyPath = (faultCode) => ((faultCode === null)
  ? 'faultCodes.IdentityService_500-1'
  : `faultCodes.${faultCode}`);

const INITIAL_STATE = Object.freeze({
  isAssociateRegisteredState: {
    response: null,
    isSpinnerLoading: false,
    error: null,
    messageTranslateKey: null,
  },
});

const AssociateDuck = createSlice({
  name: 'associate',
  initialState: INITIAL_STATE,
  reducers: {
    isAssociateRegisteredStart: (state) => {
      state.isAssociateRegisteredState = {
        error: null,
        response: null,
        messageTranslateKey: null,
        isSpinnerLoading: true,
      };
    },
    isAssociateRegisteredSuccess: (state, { payload }) => {
      state.isAssociateRegisteredState = {
        error: {
          hasError: false,
        },
        response: payload.isRegistered,
        messageTranslateKey: null,
        isSpinnerLoading: false,
      };
    },
    isAssociateRegisteredFail: (state, { payload }) => {
      state.isAssociateRegisteredState = {
        error: {
          hasError: true,
        },
        isSpinnerLoading: false,
        messageTranslateKey: getTranslateKeyPath(payload.faultCode),
        payload: null,
      };
    },
  },
});

AssociateDuck.actions.isAssociateRegisteredInit = ({ associateId, baseUrl }) => (dispatch) => {
  thunkHelper(
    dispatch,
    [
      AssociateDuck.actions.isAssociateRegisteredStart(),
      AssociateDuck.actions.isAssociateRegisteredSuccess(),
      AssociateDuck.actions.isAssociateRegisteredFail(),
    ],
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `Registration/IsAssociateRegistered/${associateId}`,
    },
  );
};

const _associateSelector = (state) => state.associateShell;

export const isAssociateRegisteredStateSelector = createSelector(
  _associateSelector,
  (associate) => associate.isAssociateRegisteredState,
);

export const { isAssociateRegisteredInit } = AssociateDuck.actions;

export default AssociateDuck.reducer;
