export default {
  shell:
  {
    buttons: {
      Accept: 'shell.buttons.accept',
    },
    cookies: {
      main: 'shell.cookies.main',
      mainFindMore: 'shell.cookies.mainfindmore',
      browserConfig: 'shell.cookies.browserconfig',
      browserConfigHowto: 'shell.cookies.browserconfighowto',
    },
    topBanner: {
      main: 'shell.topbanner.main',
      mainMore: 'shell.topbanner.mainmore',
    },
    NAVBAR: {
      TITLE: 'shell.navbar.title',
      LOG_OUT: 'shell.navbar.log_out',
      LOGGING_OUT: 'shell.navbar.logging_out',
      MY_SHIFTS: 'shell.navbar.my_shifts',
      JOB_SEARCH: 'shell.navbar.job_search',
    },
    LANDING: {
      JOBSTACK: 'shell.landing.jobstack',
      BOX11_TITLE: 'shell.landing.box11_title',
      BOX11_CONTENT: 'shell.landing.box11_content',
      BOX11_BUTTON: 'shell.landing.box11_button',
      BOX11_SIGNUP: 'shell.landing.box11_signup',
      BOX11_SIGNUP_HELP: 'shell.landing.box11_signup_help',
      BOX21_TITLE: 'shell.landing.box21_title',
      BOX21_CONTENT: 'shell.landing.box21_content',
      BOX22_PAY: 'shell.landing.box22_pay',
      BOX22_DISTANCE: 'shell.landing.box22_distance',
      BOX22_BUTTON: 'shell.landing.box22_button',
      BOX22_MESSAGE_BOX_TITLE: 'shell.landing.box22_message_box_title',
      BOX21_NEW_FEATURE: 'shell.landing.box21_new_feature',
      BOX31_TITLE: 'shell.landing.box31_title',
      BOX31_CONTENT1: 'shell.landing.box31_content1',
      BOX31_CONTENT2: 'shell.landing.box31_content2',
      BOX31_FEATURE: 'shell.landing.box31_feature',
      FOOTER_CONTENT: 'shell.landing.footer_content',
    },
    GENERAL: {
      CLOSE: 'shell.general.close',
      BACKLOGIN: 'shell.general.backLogIn',
      BACK_LANDING_PAGE: 'shell.general.back_landing_page',
      LOGIN: 'shell.general.logIn',
      REGISTER: 'shell.general.register',
      CANCEL: 'shell.general.cancel',
    },
    NOTIFICATIONS: {
      HEADER_TITLE: 'shell.notifications.header_title',
      MARK_ALL_AS_READ: 'shell.notifications.mark_all_as_read',
      MARK_ALL_AS_NOT_READ: 'shell.notifications.mark_all_as_not_read',
      DELETE_ALL: 'shell.notifications.delete_all',
      DELETE_ALL_READ: 'shell.notifications.delete_all_read',
      DELETE_ALL_NOT_READ: 'shell.notifications.delete_all_not_read',
      NO_NOTIFICATIONS: 'shell.notifications.no_notifications',
      NO_NOTIFICATIONS_READ: 'shell.notifications.no_notifications_read',
      NO_NOTIFICATIONS_NOT_READ: 'shell.notifications.no_notifications_not_read',
      CONFIRM_DELETE_NOTIFICATIONS: 'shell.notifications.confirm_delete_notifications',
      CONFIRM_DELETE_NOTIFICATIONS_READ: 'shell.notifications.confirm_delete_notifications_read',
      CONFIRM_DELETE_NOTIFICATIONS_NOT_READ: 'shell.notifications.confirm_delete_notifications_not_read',
      CONFIRM_DELETE_NOTIFICATION: 'shell.notifications.confirm_delete_notification',
      CONFIRM_DELETE_NOTIFICATIONS_TITLE: 'shell.notifications.confirm_delete_notifications_title',
      CONFIRM_DELETE_NOTIFICATION_TITLE: 'shell.notifications.confirm_delete_notification_title',
      ALL_NOTIFICATIONS_WERE_READ: 'shell.notifications.all_notifications_were_read',
      ALL_NOTIFICATIONS_WERE_NOT_READ: 'shell.notifications.all_notifications_were_not_read',
      ALL_NOTIFICATIONS_WERE_DELETED: 'shell.notifications.all_notifications_were_deleted',
      REFRESH: 'shell.notifications.refresh',
      LIST_END_REACHED: 'shell.notifications.list_end_reached',
      LIST_END_REACHED_READ: 'shell.notifications.list_end_reached_read',
      LIST_END_REACHED_NOT_READ: 'shell.notifications.list_end_reached_not_read',
      FILTER_ALL: 'shell.notifications.filter_all',
      FILTER_READ: 'shell.notifications.filter_read',
      FILTER_NOT_READ: 'shell.notifications.filter_not_read',
    },
    ERROR_PAGE: {
      BACK_BUTTON: 'shell.errorpage.back_button',
      DEFAULT_TITLE: 'shell.errorpage.error_default_title',
      DEFAULT_DESCRIPTION: 'shell.errorpage.error_default_description',
      CODE_404_TITLE: 'shell.errorpage.error_404_title',
      CODE_404_DESCRIPTION: 'shell.errorpage.error_404_description',
      CODE_500_TITLE: 'shell.errorpage.error_500_title',
      CODE_500_DESCRIPTION: 'shell.errorpage.error_500_description',
    },
    REPORTS: {
      REPORT_BI: 'shell.reports.report_bi',
      REDIRECT: 'shell.reports.redirect',
    },
  },
  LOGIN: {
    GENERIC_ERROR: 'login.genericError',
    LOGINPAGE: {
      GREETING: {
        LABEL: 'login.loginPage.greeting.label',
      },
      TITLE: {
        LABEL: 'login.loginPage.title.label',
      },
      LOGIN_INPUT: {
        LABEL_BY_PHONE: 'login.loginPage.loginInput.label_by_phone',
        LABEL_BY_EMAIL: 'login.loginPage.loginInput.label_by_email',
        PLACEHOLDER_BY_EMAIL: 'login.loginPage.loginInput.placeholder_by_email',
        PLACEHOLDER_BY_PHONE: 'login.loginPage.loginInput.placeholder_by_phone',
        SENDING_REQUEST: 'login.loginPage.loginInput.sending_request',
        PASSWORD_RESET: 'login.loginPage.loginInput.password_reset',
        INVALID_INVITATION: 'login.loginPage.loginInput.invalid_invitation',
        INVALID_CHALLENGE_KEY: 'login.loginPage.loginInput.invalid_challenge_key',
      },
      LOGIN_INPUT_ERROR: {
        LABEL: 'login.loginPage.loginInputError.label',
      },
      LOGIN_MESSAGE_ERROR: {
        LABEL: 'login.loginPage.loginMessageError.label',
      },
      PASSWORD_INPUT: {
        LABEL: 'login.loginPage.passwordInput.label',
        PLACEHOLDER: 'login.loginPage.passwordInput.placeholder',
      },
      PASSWORD_INPUT_ERROR: {
        LABEL: 'login.loginPage.passwordInputError.label',
      },
      FORGOT_PASSWORD: {
        LABEL: 'login.loginPage.forgotPassword.label',
      },
      AGREEMENTS_SECTION: {
        LABEL: 'login.loginPage.agreementsSection.label',
        LABEL_CONJUCTION: 'login.loginPage.agreementsSection.label_conjuction',
        LABEL_TERMS_OF_USE: 'login.loginPage.agreementsSection.label_termsofuse',
        LABEL_PRIVACY_POLICY: 'login.loginPage.agreementsSection.label_privacypolicy',
      },
    },
    CREATE_NEW_PASSWORD_FOR_SECURITY_QUESTIONS: {
      SUB_HEADER: 'login.CreateNewPasswordBySecurityQuestions.subHeader',
    },
    CREATE_NEW_PASSWORD: {
      GREETING: {
        LABEL: 'login.createNewPassword.greeting.label',
      },
      TITLE: {
        LABEL: 'login.createNewPassword.title.label',
      },
      INVITE: {
        LABEL: 'login.createNewPassword.invite.label',
      },
      EMAIL: {
        LABEL: 'login.createNewPassword.email.label',
      },
      MESSAGE: {
        LABEL: 'login.createNewPassword.message.label',
      },
      PASSWORD: {
        LABEL: 'login.createNewPassword.password.label',
        PLACEHOLDER: 'login.createNewPassword.password.placeholder',
      },
      CONFIRMATION: {
        LABEL: 'login.createNewPassword.confirmation.label',
        PLACEHOLDER: 'login.createNewPassword.confirmation.placeholder',
      },
      PASSWORD_REQUIREMENTS: {
        LABEL: 'login.createNewPassword.passwordRequirements.label',
      },
      PASSWORD_VALIDATIONS: {
        ERROR_LOWERCASE: {
          LABEL: 'login.createNewPassword.passwordValidations.errorLowerCase.label',
        },
        ERROR_UPPERCASE: {
          LABEL: 'login.createNewPassword.passwordValidations.errorUpperCase.label',
        },
        ERROR_SPECIAL_CHARACTER: {
          LABEL: 'login.createNewPassword.passwordValidations.errorSpecialCharacter.label',
        },
        ERROR_NUMBER: {
          LABEL: 'login.createNewPassword.passwordValidations.errorNumber.label',
        },
        ERROR_LENGTH: {
          LABEL: 'login.createNewPassword.passwordValidations.errorLength.label',
        },
        ERROR_PASSWORD_MATCH: {
          LABEL: 'login.createNewPassword.passwordValidations.passwordMatch.label',
        },
        VALID_PASSWORD: {
          LABEL: 'login.createNewPassword.passwordValidations.validPassword.label',
        },
        ERROR_ENTER_VALID_PASSWORD: {
          LABEL: 'login.createNewPassword.passwordValidations.errorEnterValidPassword',
        },
      },
      CREATE_PASSWORD_BUTTON: {
        LABEL: 'login.createNewPassword.createPasswordButton.label',
      },
      CREATE_ACCOUNT_BUTTON: {
        LABEL: 'login.createNewPassword.createAccountButton.label',
      },
      CREATE_PASSWORD_LEGAL: {
        LABEL: 'login.createNewPassword.createPasswordLegal.label',
        LINK: 'login.createNewPassword.createPasswordLegal.link',
      },
    },
    REGISTRATION: {
      GREETING: {
        LABEL: 'login.registration.greeting.label',
      },
      CONTACT_YOU_MESSAGE: {
        LABEL: 'login.registration.contactYouMessage.label',
      },
      FIRST_NAME: {
        LABEL: 'login.registration.firstName.label',
        PLACEHOLDER: 'login.registration.firstName.placeholder',
      },
      LAST_NAME: {
        LABEL: 'login.registration.lastName.label',
        PLACEHOLDER: 'login.registration.lastName.placeholder',
      },
      PHONE_NUMBER: {
        LABEL: 'login.registration.phoneNumber.label',
        PLACEHOLDER: 'login.registration.phoneNumber.placeholder',
      },
      EMAIL: {
        LABEL: 'login.registration.email.label',
        PLACEHOLDER: 'login.registration.email.placeholder',
      },
      CONTINUE_BUTTON: {
        LABEL: 'login.registration.continueButton.label',
      },
    },
    SEARCH_USER_ADDITIONAL_INFORMATION: {
      FORM: {
        ERROR_MESSAGE: 'login.searchUserAdditionalInformation.form.errorMessage',
      },
      DIALOG_ACCOUNT_EXIST: {
        TITLE: 'login.searchUserAdditionalInformation.dialogAccountExist.title',
        MESSAGE: 'login.searchUserAdditionalInformation.dialogAccountExist.message',
        MESSAGE2: 'login.searchUserAdditionalInformation.dialogAccountExist.message2',
        BUTTON_TITLE: 'login.searchUserAdditionalInformation.dialogAccountExist.buttonTitle',
        LABEL: 'login.searchUserAdditionalInformation.dialogAccountExist.label',
        LINK: 'login.searchUserAdditionalInformation.dialogAccountExist.link',
      },
      DIALOG_ACCOUNT_CREATION_FAILED: {
        HEADER: ' ',
        TITLE: 'login.searchUserAdditionalInformation.dialogAccountCreationFailed.title',
        MESSAGE: 'login.searchUserAdditionalInformation.dialogAccountCreationFailed.message',
        LABEL_EMAIL: 'login.searchUserAdditionalInformation.dialogAccountCreationFailed.labelEmail',
        LABEL_PHONE: 'login.searchUserAdditionalInformation.dialogAccountCreationFailed.labelPhone',
        BUTTON_EMAIL: 'login.searchUserAdditionalInformation.dialogAccountCreationFailed.buttonEmail',
        BUTTON_CALL: 'login.searchUserAdditionalInformation.dialogAccountCreationFailed.buttonCall',
      },
      DIALOG_CHECK_PHONE: {
        TITLE: 'login.searchUserAdditionalInformation.dialogCheckPhone.title',
        MESSAGE: 'login.searchUserAdditionalInformation.dialogCheckPhone.message',
        MESSAGE2: 'login.searchUserAdditionalInformation.dialogCheckPhone.message2',
        BUTTON_TITLE: 'login.searchUserAdditionalInformation.dialogCheckPhone.buttonTitle',
        LABEL: 'login.searchUserAdditionalInformation.dialogCheckPhone.label',
        LINK: 'login.searchUserAdditionalInformation.dialogCheckPhone.link',
      },
      GREETING: {
        LABEL: 'login.searchUserAdditionalInformation.greeting.label',
      },
      DATE_OF_BIRTH_PLACEHOLDER: {
        LABEL: 'login.searchUserAdditionalInformation.dateOfBirthPlaceholder.label',
      },
      DATE_OF_BIRTH: {
        LABEL: 'login.searchUserAdditionalInformation.dateOfBirth.label',
      },
      LAST_FOUR_SSN: {
        LABEL: 'login.searchUserAdditionalInformation.lastFourSSN.label',
      },
      LAST_FOUR_SSN_PLACEHOLDER: {
        LABEL: 'login.searchUserAdditionalInformation.lastFourSSNPlaceholder.label',
      },
      STREET_ADDRESS: {
        LABEL: 'login.searchUserAdditionalInformation.streetAddress.label',
      },
      STREET_ADDRESS_PLACEHOLDER: {
        LABEL: 'login.searchUserAdditionalInformation.streetAddressPlaceholder.label',
      },
      STREET_ADDRESS2: {
        LABEL: 'login.searchUserAdditionalInformation.streetAddress2.label',
      },
      STREET_ADDRESS2_PLACEHOLDER: {
        LABEL: 'login.searchUserAdditionalInformation.streetAddress2Placeholder.label',
      },
      CITY: {
        LABEL: 'login.searchUserAdditionalInformation.city.label',
      },
      CITY_PLACEHOLDER: {
        LABEL: 'login.searchUserAdditionalInformation.cityPlaceholder.label',
      },
      COUNTRY: {
        LABEL: 'login.searchUserAdditionalInformation.country.label',
      },
      COUNTRY_PLACEHOLDER: {
        LABEL: 'login.searchUserAdditionalInformation.countryPlaceholder.label',
      },
      CREATE_BUTTON: {
        LABEL: 'login.searchUserAdditionalInformation.createButton.label',
      },
      CANCEL_BUTTON: {
        LABEL: 'login.searchUserAdditionalInformation.cancelButton.label',
      },
      GO_BACK_TO_PEOPLE_READY: 'login.searchUserAditionalInformation.goBackToPeopleReady',
    },
    FORGOTPASSWORD_BY_EMAIL: {
      TITLE: {
        LABEL: 'login.forgotPasswordByEmail.title.label',
      },
      MESSAGE: {
        LABEL: 'login.forgotPasswordByEmail.message.label',
      },
      INPUT: {
        LABEL: 'login.forgotPasswordByEmail.input.label',
        PLACEHOLDER: 'login.forgotPasswordByEmail.input.placeholder',
      },
      INPUT_ERROR: {
        LABEL: 'login.forgotPasswordByEmail.inputError.label',
      },
      SENDMECODE_BUTTON: {
        LABEL: 'login.forgotPasswordByEmail.sendMeCodeButton.label',
      },
      SENDMECODE_HELP: {
        LABEL: 'login.forgotPasswordByEmail.sendMeCodeHelp.label',
        LINK: 'login.forgotPasswordByEmail.sendMeCodeHelp.link',
      },
      UNABLE_TO_RESET_PASSWORD_DIALOG: {
        TITLE: 'login.forgotPasswordByPhone.unableToResetPasswordDialog.title',
        TEXT: 'login.forgotPasswordByPhone.unableToResetPasswordDialog.text',
        TEXT2: 'login.forgotPasswordByPhone.unableToResetPasswordDialog.text2',
        CRATE_ACCOUNT_BUTTON: 'login.forgotPasswordByPhone.unableToResetPasswordDialog.createAccountButton',
        FIND_LOCATIONS_BUTTON: 'login.forgotPasswordByPhone.unableToResetPasswordDialog.findLocationsButton',
      },
    },
    FORGOTPASSWORD_BY_PHONE: {
      TITLE: {
        LABEL: 'login.forgotPasswordByPhone.title.label',
      },
      MESSAGE: {
        LABEL: 'login.forgotPasswordByPhone.message.label',
      },
      INPUT: {
        LABEL: 'login.forgotPasswordByPhone.input.label',
        PLACEHOLDER: 'login.forgotPasswordByPhone.input.placeholder',
      },
      INPUT_ERROR: {
        LABEL: 'login.forgotPasswordByPhone.inputError.label',
      },
      NEXT_BUTTON: {
        LABEL: 'login.forgotPasswordByPhone.sendMeCodeButton.label',
      },
      NEXT_HELP: {
        LABEL: 'login.forgotPasswordByPhone.sendMeCodeHelp.label',
        LINK: 'login.forgotPasswordByPhone.sendMeCodeHelp.link',
      },
    },
    SELECT_VERIFICATION_METHOD: {
      TITLE: 'login.selectVerificationMethod.title',
      MESSAGE: 'login.selectVerificationMethod.message',
      MESSAGE_SECURITY_QUESTIONS: 'login.selectVerificationMethod.messageSecurityQuestions',
      BY_TEXT: 'login.selectVerificationMethod.byText',
      BY_EMAIL: 'login.selectVerificationMethod.byEmail',
      BY_QUESTIONS: 'login.selectVerificationMethod.securityQuestions',
      INPUT: {
        LABEL: 'login.selectVerificationMethod.input.label',
        PLACEHOLDER: 'login.selectVerificationMethod.input.placeholder',
      },
      INPUT_ERROR: {
        LABEL: 'login.selectVerificationMethod.inputError.label',
      },
      ANSWER_QUESTIONS_BUTTON: {
        LABEL: 'login.selectVerificationMethod.answerQuestionsbutton.label',
      },
      SENDMECODE_BUTTON: {
        LABEL: 'login.selectVerificationMethod.sendMeCodeButton.label',
      },
      SENDMECODE_HELP: {
        LABEL: 'login.selectVerificationMethod.sendMeCodeHelp.label',
        LINK: 'login.selectVerificationMethod.sendMeCodeHelp.link',
      },

    },
    RESETPASSWORD_BY_EMAIL: {
      TITLE: {
        LABEL: 'login.resetPasswordByEmail.title.label',
      },
      MESSAGE: {
        LABEL: 'login.resetPasswordByEmail.message.label',
      },
      INPUT: {
        LABEL: 'login.resetPasswordByEmail.input.label',
        PLACEHOLDER: 'login.resetPasswordByEmail.input.placeholder',
      },
      INPUT_ERROR: {
        LABEL: 'login.resetPasswordByEmail.inputError.label',
      },
      SENDMECODE_BUTTON: {
        LABEL: 'login.resetPasswordByEmail.sendMeCodeButton.label',
      },
      SENDMECODE_HELP: {
        LABEL: 'login.resetPasswordByEmail.sendMeCodeHelp.label',
        LINK: 'login.resetPasswordByEmail.sendMeCodeHelp.link',
      },
      ALREADY_HAVE_CODE: 'login.resetPasswordByEmail.already_have_code',
    },
    RESETPASSWORD_BY_PHONE: {
      TITLE: {
        LABEL: 'login.resetPasswordByPhone.title.label',
      },
      MESSAGE: {
        LABEL: 'login.resetPasswordByPhone.message.label',
      },
      INPUT: {
        LABEL: 'login.resetPasswordByPhone.input.label',
        PLACEHOLDER: 'login.resetPasswordByPhone.input.placeholder',
      },
      INPUT_ERROR: {
        LABEL: 'login.resetPasswordByPhone.inputError.label',
      },
      SENDMECODE_BUTTON: {
        LABEL: 'login.resetPasswordByPhone.sendMeCodeButton.label',
      },
      SENDMECODE_HELP: {
        LABEL: 'login.resetPasswordByPhone.sendMeCodeHelp.label',
        LINK: 'login.resetPasswordByPhone.sendMeCodeHelp.link',
      },
    },
    NEEDHELPLOGIN: {
      TITLE: {
        LABEL: 'login.needHelpLogin.title.label',
      },
      MESSAGE: {
        LABEL: 'login.needHelpLogin.message.label',
      },
    },
    VALIDATE_PASSWORD_BY_EMAIL: {
      TITLE: {
        LABEL: 'login.validatePasswordByEmail.title.label',
      },
      MESSAGE: {
        LABEL: 'login.validatePasswordByEmail.message.label',
      },
      CODE_RESEND: {
        LABEL: 'login.validatePasswordByEmail.codeResend.label',
      },
      VERIFICATION_CODE: {
        LABEL: 'login.validatePasswordByEmail.verificationCodeInput.label',
        PLACEHOLDER: 'login.validatePasswordByEmail.verificationCodeInput.placeholder',
        LINK: 'login.validatePasswordByEmail.verificationCodeInput.link',
        ERROR: 'login.validatePasswordByEmail.verificationCodeInput.error',
      },
    },
    VALIDATE_PASSWORD_BY_PHONE: {
      TITLE: {
        LABEL: 'login.validatePasswordByPhone.title.label',
      },
      MESSAGE: {
        LABEL: 'login.validatePasswordByPhone.message.label',
      },
      CODE_RESEND: {
        LABEL: 'login.validatePasswordByPhone.codeResend.label',
      },
      VERIFICATION_CODE: {
        LABEL: 'login.validatePasswordByPhone.verificationCodeInput.label',
        PLACEHOLDER: 'login.validatePasswordByPhone.verificationCodeInput.placeholder',
        LINK: 'login.validatePasswordByPhone.verificationCodeInput.link',
        ERROR: 'login.validatePasswordByPhone.verificationCodeInput.error',
      },
      SENDMECODE_BUTTON: {
        LABEL: 'login.validatePasswordByPhone.sendMeCodeButton.label',
      },
      SENDMECODE_HELP: {
        LABEL: 'login.validatePasswordByPhone.sendMeCodeHelp.label',
        LINK: 'login.validatePasswordByPhone.sendMeCodeHelp.link',
      },
    },
    ACCOUNT_BY_INVITE_DIALOG: {
      TITLE: 'login.accountByInvite.title',
      INFO: 'login.accountByInvite.info',
      BUTTON: 'login.accountByInvite.button',
    },
    EXPERIENCE_SWITCHER: {
      INSTRUCTIONS: 'shell.experience_switcher.instructions',
      EXPERIENCE_SWITCHER: 'shell.experience_switcher.experience_switcher',
    },
    SUCCESS_SCREEN: {
      TITLE: 'login.successScreen.title',
      INFO: 'login.successScreen.info',
    },
    SECURITY_QUESTIONS: {
      FORM: {
        TITLE: 'login.securityQuestions.form.title',
        INSTRUCTIONS: 'login.securityQuestions.form.instructions',
        INPUTS: {
          LABEL: {
            QUESTION: 'login.securityQuestions.form.inputs.label.question',
            ANSWER: 'login.securityQuestions.form.inputs.label.answer',
          },
          PLACEHOLDER: {
            QUESTION: 'login.securityQuestions.form.inputs.placeholder.question',
            ANSWER: 'login.securityQuestions.form.inputs.placeholder.answer',
          },
        },
        BUTTONS: {
          SUBMIT: {
            LABEL: 'login.securityQuestions.form.buttons.submit.label',
            LOADING_TEXT: 'login.securityQuestions.form.buttons.submit.loading',
          },
        },
      },
      ANSWER: {
        TITLE: 'login.securityQuestions.answer.title',
        INSTRUCTIONS: 'login.securityQuestions.answer.instructions',
        INPUT: {
          LABEL: 'login.securityQuestions.answer.input.label',
          PLACEHOLDER: 'login.securityQuestions.answer.input.placeholder',
        },
        NEED_QUESTIONS: 'login.securityQuestions.answer.needQuestions',
        CHANGE_QUESTIONS: 'login.securityQuestions.answer.changeQuestions',
        BUTTONS: {
          SUBMIT: {
            LABEL: 'login.securityQuestions.answer.buttons.submit.label',
            LOADING_TEXT: 'login.securityQuestions.answer.buttons.submit.loading',
          },
        },
        ERRORS: {
          ANSWERED_INCORRECTLY: 'login.securityQuestions.answer.errors.answeredIncorrectly',
        },
      },
    },
    CONTACT_BRANCH: {
      PAGE_HEADER: 'login.contactBranch.pageHeader',
      CONTACT_STATEMENT: 'login.contactBranch.contactStatement',
      INFO_PHONE_HEADER: 'login.contactBranch.infoPhoneHeader',
      INFO_EMAIL_HEADER: 'login.contactBranch.infoEmailHeader',
      BUTTON_CALL: 'login.contactBranch.buttonCall',
      BUTTON_EMAIL: 'login.contactBranch.buttonEmail',
    },
  },
};
