import React from 'react';
import { Text } from '@lake-superior/ui-core';
import TranslateKeys from '../../locales/keys';
import jobStackLogoWhite from '../../assets/img/Landing/JobStack-logo-white.png';
import LoginButton from './LoginButton';

const Footer = () => (
  <div className="container">
    <div className="row landing-footer">
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <div className="landing-footer-content-container landing-footer-button-container">

          <div className="footer-title">
            <img src={jobStackLogoWhite} className="jobstack-logo" alt="JobStack logo" />
          </div>

          <LoginButton
            translateId={TranslateKeys.shell.LANDING.BOX11_BUTTON}
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
        <div className="landing-footer-content-container">
          <Text translateId={TranslateKeys.shell.LANDING.FOOTER_CONTENT} />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
