import React from 'react';
import { Text, Button, ButtonProps } from '@lake-superior/ui-core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { constants } from '@lake-superior/core';
import { reloadList } from '../../redux/ducks/notifications';
import TranslateKeys from '../../locales/keys';

const NotificationsListRefresh = ({
  touchingAll,
  currentNotificationStatus,
  deletingAll,
}) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(reloadList());
  };

  const calculateTranslateId = () => {
    if (touchingAll) {
      return (currentNotificationStatus === constants.NOTIFICATION_STATUS.READ)
        ? TranslateKeys.shell.NOTIFICATIONS.ALL_NOTIFICATIONS_WERE_NOT_READ
        : TranslateKeys.shell.NOTIFICATIONS.ALL_NOTIFICATIONS_WERE_READ;
    }

    if (deletingAll) {
      return TranslateKeys.shell.NOTIFICATIONS.ALL_NOTIFICATIONS_WERE_DELETED;
    }

    return null;
  };

  return (
    <div className="notifications-panel-refresh-container">
      <div className="row center-xs">
        <div className="col-xs-10">
          <Text translateId={calculateTranslateId()} />
          <Button
            translateId={TranslateKeys.shell.NOTIFICATIONS.REFRESH}
            size={ButtonProps.ButtonSizes.SMALL}
            style={ButtonProps.ButtonStyles.PRIMARY}
            onClick={() => handleOnClick()}
          />
        </div>
      </div>

    </div>
  );
};

NotificationsListRefresh.propTypes = {
  touchingAll: PropTypes.bool.isRequired,
  deletingAll: PropTypes.bool.isRequired,
  currentNotificationStatus: PropTypes.oneOf(
    Object.values(constants.NOTIFICATION_STATUS),
  ).isRequired,
};

export default NotificationsListRefresh;
