import { constants } from '@lake-superior/core';

const config = {
  redirecTo: '/Associate',
  displayName: 'Associate',
  mfes: [{ mfe: 'associate' }, { mfe: 'jobs' }],
  notificationsTargetType: constants.NOTIFICATION_TARGET_TYPE.ASSOCIATE,
  notificationsEnabled: true,
  tasksEnabled: false,
  sessionType: constants.SESSION_TYPES.ASSOCIATE,
};

export default config;
