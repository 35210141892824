import React from 'react';
import { tools } from '@lake-superior/core';
import { Route } from 'react-router-dom';
import UrlActionToastMessage from '../UrlActionToastMessage/UrlActionToastMessage';

const ActionRenderer = () => (
  <Route
    path="/"
    render={({ location }) => {
      const params = tools.getQueryStringParamsFromUrl(`${location.pathname}${location.search}`);

      switch (params?.action) {
        case 'toast':
          return <UrlActionToastMessage params={params} />;
        default:
          return null;
      }
    }}
  />
);

export default ActionRenderer;
