import React, { useMemo } from 'react';
import { MFEManager } from '@lake-superior/mfe-manager';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import App from '../../App';
import { MFE } from '../../config/appsettings.json';
import ShellConfig from '../../config/ShellConfig';

const MFECoordinator = ({ store, addTranslationForLanguage }) => {
  const addTranslationForLanguageMemo = useMemo(() => addTranslationForLanguage,
    [addTranslationForLanguage]);

  const dispatch = useMemo(() => store.dispatch, [store.dispatch]);

  const reducerManager = useMemo(() => store.ReducerManager, [store.ReducerManager]);

  return (
    <MFEManager
      BASE_URL={MFE.BASE_URL}
      DEFAULT_RETRY_COUNT={MFE.DEFAULT_RETRY_COUNT}
      MINIMUM_LOADING_TIME_MS={MFE.MINIMUM_LOADING_TIME_MS}
      MFEs={ShellConfig.mfes}
      store={store}
      addTranslationForLanguage={addTranslationForLanguageMemo}
      dispatch={dispatch}
      reducerManager={reducerManager}
    >
      <App />
    </MFEManager>
  );
};

MFECoordinator.propTypes = {
  store: PropTypes.instanceOf(Object).isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
};

export default withLocalize(MFECoordinator);
