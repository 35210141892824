import React, { useCallback, useEffect } from 'react';
import { NotificationBell, useAutoCancelableInterval, textColorPalette } from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getInCompleteTasks,
  InCompleteSelector,
  TargetTypeSelector,
  EnabledSelector,
} from '../../redux/ducks/tasks';
import TaskRoutes from './props/taskRoutes';

const TasksBell = ({ desktop }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const incompletedTasks = useSelector(InCompleteSelector);
  const currentTargetType = useSelector(TargetTypeSelector);
  const tasksEnabled = useSelector(EnabledSelector);
  const goToTaskManagement = () => {
    history.push(TaskRoutes.manager);
  };
  const cachedGetInCompleteTasks = useCallback(() => {
    if (tasksEnabled) {
      dispatch(getInCompleteTasks());
    }
  }, [dispatch, tasksEnabled]);

  useAutoCancelableInterval(() => {
    cachedGetInCompleteTasks();
  });

  useEffect(() => {
    cachedGetInCompleteTasks();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTargetType]);

  if (!tasksEnabled) {
    return null;
  }

  if (desktop) {
    return (
      <div className="shell-menu-task-wrapper">
        <div className="shell-menu-task-container use-ui-core">
          <NotificationBell
            number={incompletedTasks}
            iconColor={textColorPalette.white}
            notificationOnly
            onClick={goToTaskManagement}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="col-xs-8 end-xs use-ui-core">
      <NotificationBell
        number={incompletedTasks}
        notificationOnly
      />
    </div>
  );
};

TasksBell.propTypes = {
  desktop: PropTypes.bool,
};

TasksBell.defaultProps = {
  desktop: false,
};

export default TasksBell;
