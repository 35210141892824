import React from 'react';
import PropTypes from 'prop-types';
import { IdentityActions } from '@lake-superior/ui-core';
import { useSelector } from 'react-redux';

const UserIcon = ({ hide }) => {
  const currentSession = useSelector(IdentityActions.activeUserSessionSelector);

  return (
    !hide && (
      <div className="shell-menu-user-icon-container">
        <div className="user-icon-container">
          {
            currentSession.user.userNameInitials
          }
        </div>
      </div>
    )
  );
};

UserIcon.propTypes = {
  hide: PropTypes.bool,
};

UserIcon.defaultProps = {
  hide: false,
};

export default UserIcon;
