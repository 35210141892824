import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import JobStackLogo from '../../assets/img/Landing/jobstack.svg';
import PeopleReadyLogo from '../../assets/img/Landing/peopleready.svg';
import { startPoint } from '../../config/appsettings.json';
import { controlSelector } from '../../redux/listeners/identityListener';

const LoginNavBar = () => {
  const history = useHistory();
  const control = useSelector(controlSelector);

  useEffect(() => {
    if (control !== 0) {
      history.replace('/auth/login');
    }
  }, [control, history]);

  return (
    <div className="shell-navbar-container">
      <div className="shell-navbar">
        <div className="row middle-xs login-container login-nav-bar">
          <Link to={startPoint} className="logo-container">
            <img src={PeopleReadyLogo} className="peopleready-logo hide-sm" alt="PeopleReady logo" />
            <div className="vertical-divider hide-sm" />
            <img src={JobStackLogo} className="jobstack-logo" alt="JobStack by PeopleReady logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginNavBar;
