import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button, ButtonProps, DelayedButton, FieldTypes, FormHandler, FieldCheckList,
  Text, TrackedToggleableIconTextField, TrackedTextField, InlineAlert, inlineAlertStatus,
  SubmitButton, useIdentityConfiguration, elementWrapperIconProps, IdentityActions,
} from '@lake-superior/ui-core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { tools, phoneTools } from '@lake-superior/core';
import { faCheckCircle, faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import translateKeys from '../../locales/keys';
import {
  FieldCheckListArray, passwordEyeConfigArray, checkHasAllValidPasswordPolicy, FieldCheckListArgs,
} from './Helpers/loginUtils';
import { ResetPassword, ResendVerificationCodeForForgotPassword } from './Helpers/IdentityServiceAPIs';
import { resetPassworStateSelector } from '../../redux/listeners/identityListener';
import { needHelpInfo, verificationCodeRequestIntervalInSeconds, baseUrls } from '../../config/appsettings.json';
import DeliveryMethods from '../../config/VerificationCodeDeliveryMethods.json';

const ValidatePasswordByPhone = () => {
  const [tempPassword, setTempPassword] = useState('');
  const [tempConfirmation, setTempConfirmation] = useState('');
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [showHelpLoginIn, setShowHelpLoginIn] = useState(false);
  const [blockUntilMoment, setBlockUntilMoment] = useState(null);

  const resetPasswordState = useSelector(resetPassworStateSelector);
  const commitPasswordToken = useSelector(IdentityActions.commitPasswordTokenSelector);

  const dispatch = useDispatch();
  const location = useLocation();

  const username = location.state.phonenumber;

  const history = useHistory();
  useEffect(() => {
    if (!tools.isDefinedAndNotNullAndNotEmpty(username)
      || !tools.isDefinedAndNotNullAndNotEmpty(commitPasswordToken)) {
      if (tools.isPropertyDefinedAndNotNullAndNotEmpty(location, 'state.backTo')) {
        history.replace(location.state.backTo);
      } else {
        history.replace('/auth/Login');
      }
    }
  }, [history, location, username, commitPasswordToken]);

  useEffect(() => {
    if (resetPasswordState.error?.hasError === false) {
      history.replace({
        pathname: '/auth/Login',
        state: { fromPasswordReset: true },
      });
    }
  }, [history, resetPasswordState]);
  const { blockUntil } = useIdentityConfiguration({ baseUrl: baseUrls.identityService, username });

  useEffect(() => {
    if (blockUntil) {
      setBlockUntilMoment(moment.utc(blockUntil));
    }
  }, [blockUntil]);

  const resendHandler = () => {
    ResendVerificationCodeForForgotPassword(dispatch, commitPasswordToken, DeliveryMethods.SMS);
    setAlertIsOpen(true);
  };

  return (
    <div className="create-new-password">
      <div className="bodyContainer">
        <div className={classNames('formAreaValidatePassword', 'use-ui-core', { hideme: showHelpLoginIn })}>
          <FormHandler
            triggerOnEnter
            resolveWith={(state) => !state.identity.isLoading}
            onSubmit={({ verificationCode, password, confirmPassword }) => ({
              validations: [],
              execute: () => new Promise((resolve, reject) => {
                try {
                  if (checkHasAllValidPasswordPolicy(
                    FieldCheckListArgs(tempPassword, tempConfirmation),
                  )
                    && tools.isDefinedAndNotNullAndNotEmpty(verificationCode)
                    && tools.isDefinedAndNotNullAndNotEmpty(commitPasswordToken)
                    && tools.isDefinedAndNotNullAndNotEmpty(password)
                    && tools.isDefinedAndNotNullAndNotEmpty(confirmPassword)
                    && tools.isValidNumber(+verificationCode)
                    && confirmPassword === password) {
                    ResetPassword(
                      dispatch,
                      commitPasswordToken,
                      verificationCode,
                      password,
                    );
                    resolve();
                  } else {
                    reject();
                  }
                } catch {
                  reject();
                }
              }),
            })}
          >
            <div className="">
              <div className="formAreaValidatePassword-container">
                <div className="row">
                  <div className="code-resend use-ui-core">
                    <InlineAlert
                      bodyTranslateId={translateKeys.LOGIN
                        .VALIDATE_PASSWORD_BY_PHONE.CODE_RESEND.LABEL}
                      icon
                      status={inlineAlertStatus.INFO}
                      open={alertIsOpen}
                      autoClose={10000}
                      syncState={setAlertIsOpen}
                    />
                  </div>
                  <span className="forgotpass">
                    <Text translateId={translateKeys.LOGIN
                      .VALIDATE_PASSWORD_BY_PHONE.TITLE.LABEL}
                    />
                  </span>
                  <span className="message">
                    <Text translateId={translateKeys.LOGIN
                      .VALIDATE_PASSWORD_BY_PHONE.MESSAGE.LABEL}
                    />
                  </span>
                </div>

                <div className="use-ui-core">
                  {resetPasswordState.error?.hasError === true && (
                    <InlineAlert
                      bodyTranslateId={resetPasswordState.messageTranslateKey}
                      icon
                      status={inlineAlertStatus.ERROR}
                    />
                  )}
                  <div className="trackfieldContainer">
                    <div className="row">
                      <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                        <TrackedTextField
                          dataTestId="verificationCode"
                          name="verificationCode"
                          mask="999999"
                          type={FieldTypes.TEXT}
                          customInputMode={FieldTypes.NUMBER}
                          translateId={translateKeys.LOGIN
                            .VALIDATE_PASSWORD_BY_PHONE.VERIFICATION_CODE.LABEL}
                          placeholderTranslateId={translateKeys.LOGIN
                            .VALIDATE_PASSWORD_BY_PHONE.VERIFICATION_CODE.PLACEHOLDER}
                          translateIdForRequiredValidation={translateKeys.LOGIN
                            .VALIDATE_PASSWORD_BY_PHONE.VERIFICATION_CODE.ERROR}
                          applyThisValidation={({ value }) => {
                            if (value.length !== 6) {
                              return [{
                                translateId: translateKeys.LOGIN
                                  .VALIDATE_PASSWORD_BY_EMAIL.VERIFICATION_CODE.ERROR,
                                severity: 'error',
                              }];
                            }
                            return [];
                          }}
                        />
                      </div>
                      <div className="resend-code col-xs-5 col-sm-5 col-md-5 col-lg-5">
                        <DelayedButton
                          blockUntil={blockUntilMoment}
                          delaySeconds={verificationCodeRequestIntervalInSeconds}
                          showRemainingTime
                          translateId={translateKeys.LOGIN
                            .VALIDATE_PASSWORD_BY_PHONE.VERIFICATION_CODE.LINK}
                          style={ButtonProps.ButtonStyles.LINK}
                          onClick={resendHandler}
                        />
                      </div>
                      <span className="sendmecode use-ui-core">
                        <span className="help">
                          <Text translateId={translateKeys.LOGIN
                            .VALIDATE_PASSWORD_BY_PHONE.SENDMECODE_HELP.LABEL}
                          />
                        </span>
                        <Button
                          translateId={translateKeys.LOGIN
                            .VALIDATE_PASSWORD_BY_PHONE.SENDMECODE_HELP.LINK}
                          style={ButtonProps.ButtonStyles.LINK}
                          onClick={() => setShowHelpLoginIn(true)}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="trackfieldContainer">
                    <TrackedToggleableIconTextField
                      name="password"
                      dataTestId="password"
                      translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD.LABEL}
                      placeholderTranslateId={translateKeys.LOGIN
                        .CREATE_NEW_PASSWORD.PASSWORD.PLACEHOLDER}
                      required={false}
                      controlledWith={setTempPassword}
                      iconPosition={elementWrapperIconProps.INSIDE_RIGHT}
                      config={passwordEyeConfigArray}
                    />
                  </div>
                  <div className="trackfieldContainer">

                    <TrackedToggleableIconTextField
                      name="confirmPassword"
                      dataTestId="confirmPassword"
                      translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.CONFIRMATION.LABEL}
                      placeholderTranslateId={translateKeys.LOGIN
                        .CREATE_NEW_PASSWORD.CONFIRMATION.PLACEHOLDER}
                      required={false}
                      controlledWith={setTempConfirmation}
                      iconPosition={elementWrapperIconProps.INSIDE_RIGHT}
                      config={passwordEyeConfigArray}
                      applyThisValidation={() => {
                        if (!checkHasAllValidPasswordPolicy(
                          FieldCheckListArgs(tempPassword, tempConfirmation),
                        )
                        ) {
                          return [{
                            text: '',
                            severity: 'error',
                          }];
                        }
                        return [];
                      }}
                    />

                  </div>
                  <span className="requirements">
                    <Text
                      translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                        .PASSWORD_REQUIREMENTS.LABEL}
                    />
                  </span>
                </div>

                <FieldCheckList
                  checkArray={FieldCheckListArray}
                  unCheckedIcon={faCircle}
                  checkedIcon={faCheckCircle}
                  checkArgs={FieldCheckListArgs(tempPassword, tempConfirmation)}
                />

                <div className="create-button use-ui-core">
                  <SubmitButton
                    dataTestId="buttonSubmit"
                    translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                      .CREATE_PASSWORD_BUTTON.LABEL}
                    style={ButtonProps.ButtonStyles.PRIMARY}
                    size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                  />
                </div>
              </div>
            </div>
          </FormHandler>
        </div>
        <div className={classNames('helpLoginIn', 'formAreaValidatePassword-container', { showme: showHelpLoginIn })}>
          <div className="row center-xs">
            <span className="forgotpass">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.TITLE.LABEL} />
            </span>
            <span className="message">
              <Text translateId={translateKeys.LOGIN.NEEDHELPLOGIN.MESSAGE.LABEL} />
            </span>
          </div>
          <div className="forgotpass-phone-button use-ui-core">
            {/* todo: refactor to use a link */}
            <Button
              label={phoneTools.formatPhoneNumber(needHelpInfo.needHelpLoginPhone)}
              style={ButtonProps.ButtonStyles.PRIMARY}
              size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
              onClick={() => { window.location.href = `tel://${needHelpInfo.needHelpLoginPhone}`; }}
              icon={faPhoneAlt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatePasswordByPhone;
