import { localizeReducer } from 'react-localize-redux';
import { UICoreReducers } from '@lake-superior/ui-core';
import ShellReducer from '../ducks/shell';
import NotificationsReducer from '../ducks/notifications';
import IdentityListenerReducer from '../listeners/identityListener';
import TasksReducer from '../ducks/tasks';
import IntegrationsReducer from '../ducks/integrations';
import AssociateReducer from '../ducks/associate';
import infiniteScroll from '../byPassedDucks/uicore.infinitescroll';

const computeUICoreReducers = {
  ...UICoreReducers,
  infiniteScroll,
};

const rootReducer = {
  localize: localizeReducer,
  shell: ShellReducer,
  notifications: NotificationsReducer,
  tasks: TasksReducer,
  integrations: IntegrationsReducer,
  associateShell: AssociateReducer,
  ...computeUICoreReducers,
  IdentityListener: IdentityListenerReducer,
};

export default rootReducer;
