import { createReducerManager } from '@lake-superior/ui-core';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from '../reducers';

export default function (initialState) {
  const reducerManager = createReducerManager(rootReducer);

  const store = configureStore({
    reducer: reducerManager.reduce,
    preloadedState: initialState,
    // [thunk, immutableStateInvariant, serializableStateInvariant, logger]
    middleware: getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action paths
        ignoredActionPaths: ['payload.options.renderToStaticMarkup', 'thunkPayload.itemMap'],
        // Ignore these paths in the state
        ignoredPaths: ['localize.options.renderToStaticMarkup', 'localize.options.onMissingTranslation'],
      },
    }).concat(logger),
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  });

  store.ReducerManager = reducerManager;
  return store;
}
