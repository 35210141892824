import React from 'react';
import { Icon, Text, IdentityActions } from '@lake-superior/ui-core';
import { useSelector } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { isArray } from 'lodash';
import nextId from 'react-id-generator';
import classNames from 'classnames';
import { contextMenuSelector } from '../../redux/ducks/shell';
import { isSectionAuthorized } from '../Auth/Helpers/common';

const getComponentToRender = ({
  icon, to, translateId, translateData, text,
  classes,
}, props) => (
  <li
    key={nextId('contexMenuItem')}
    className={classNames(classes)}
    {...props}
  >
    {icon && <Icon icon={icon} />}
    <Link to={to}>
      <Text
        translateId={translateId}
        text={text}
        translateData={translateData}
      />
    </Link>
  </li>
);

const ContextMenuItemListener = (props) => {
  const routes = useSelector(contextMenuSelector);
  const userRoles = useSelector(IdentityActions.userRolesSelector);

  return routes.map(({
    showOnRoles,
    showOnRoutes,
    excludeOnRoutes,
    ...rest
  }) => {
    if (isSectionAuthorized(showOnRoles, userRoles)) {
      const component = getComponentToRender(rest, props);

      if (isArray(showOnRoutes) && showOnRoutes.length > 0) {
        return showOnRoutes.map((t) => <Route key={nextId('contexMenuItemRoute')} path={t} render={() => component} />);
      }

      if (isArray(excludeOnRoutes) && excludeOnRoutes.length > 0) {
        return excludeOnRoutes.map((t) => (
          <Route
            key={nextId('contexMenuItemRoute')}
            path="/"
            render={() => {
              if (window.location.pathname === t) {
                return null;
              }

              return component;
            }}
          />
        ));
      }

      return component;
    }

    return null;
  });
};

export default ContextMenuItemListener;
