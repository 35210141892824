import { localStorageManager } from '@lake-superior/core';
import { localStorageKeys } from '../../../config/appsettings.json';

const getSession = () => localStorageManager
  .getObjectLocalStorage(localStorageKeys.PR_SESSION) || null;

export {
  // eslint-disable-next-line import/prefer-default-export
  getSession,
};
