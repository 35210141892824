import React from 'react';
import { Banner } from '@lake-superior/ui-core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { showMobileBannerSelector } from '../../redux/ducks/shell';

const MobileBanner = () => {
  const { show, text } = useSelector(showMobileBannerSelector);

  if (!show) {
    return null;
  }

  return (
    <div className={classNames('mobileBannerContainer use-ui-core')}>
      <Banner
        text={text}
      />
    </div>
  );
};

export default MobileBanner;
