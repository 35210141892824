import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, ButtonProps, FormHandler, FieldCheckList, TrackedTextField, FieldTypes,
  Text, TrackedToggleableIconTextField, TextHelperTypes,
  Dialog, DialogProps, SubmitButton, inlineAlertStatus,
  elementWrapperIconProps, InlineAlert, IdentityActions, Spinner, SpinnerPositions,
} from '@lake-superior/ui-core';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { tools } from '@lake-superior/core';
import { useDispatch, useSelector } from 'react-redux';
import translateKeys from '../../locales/keys';
import { RegisterByEmail, GetProfileByContextId, LogOut } from './Helpers/IdentityServiceAPIs';
import {
  FieldCheckListArray, passwordEyeConfigArray, checkHasAllValidPasswordPolicy, FieldCheckListArgs,
} from './Helpers/loginUtils';
import { registerStateSelector, currentProfileSelector } from '../../redux/listeners/identityListener';

const RegistrationByInvite = () => {
  const dispatch = useDispatch();

  const [tempPassword, setTempPassword] = useState('');
  const [tempConfirmation, setTempConfirmation] = useState('');
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const registerState = useSelector(registerStateSelector);
  const profileInfo = useSelector(currentProfileSelector);
  const isLoading = useSelector(IdentityActions.isLoadingSelector);
  const activeSession = useSelector(IdentityActions.activeUserSessionSelector);

  const gotoLoginHandler = () => {
    history.push('/auth/Login');
  };

  useEffect(() => {
    if (activeSession !== null) {
      LogOut(dispatch);
    }
  }, [activeSession, dispatch]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    const queryStringParams = tools.getQueryStringParamsFromUrl(window.location.href);

    if (!tools.isPropertyDefinedAndNotNullAndNotEmpty(queryStringParams, 'ContextId')) {
      history.push('/auth/login');
    } else {
      GetProfileByContextId(dispatch, queryStringParams.ContextId);
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (registerState.error?.hasError === false) {
      openModal();
    }
  }, [openModal, registerState.error]);

  useEffect(() => {
    if (profileInfo.error?.hasError === true) {
      history.replace({
        pathname: '/auth/Login',
        state: { invalidInvitation: true },
      });
    }
  }, [history, profileInfo.error]);

  return (
    <div className="create-new-password">

      <div className="use-ui-core invite-dialog">
        <Dialog
          buttonsPosition={DialogProps.ButtonsPosition.LEFT}
          cancelButtonTranslateId={translateKeys.LOGIN.ACCOUNT_BY_INVITE_DIALOG.TITLE}
          closeButton={false}
          isOpen={isModalOpen}
          okButtonTranslateId={translateKeys.LOGIN.ACCOUNT_BY_INVITE_DIALOG.BUTTON}
          okStyle={ButtonProps.ButtonStyles.PRIMARY}
          onOk={gotoLoginHandler}
          size={DialogProps.DialogSizes.SMALL}
          style={DialogProps.DialogStyles.DEFAULT}
          translateId={translateKeys.LOGIN.ACCOUNT_BY_INVITE_DIALOG.TITLE}
        >
          <Text translateId={translateKeys.LOGIN.ACCOUNT_BY_INVITE_DIALOG.INFO} />
        </Dialog>
      </div>

      <div className="bodyContainer">
        <div className="formAreaCreateNewPassword use-ui-core">

          <Spinner
            isLoading={profileInfo.error === null}
            position={SpinnerPositions.OVERLAY}
          >
            <FormHandler
              triggerOnEnter
              resolveWith={(state) => !state.identity.isLoading}
              onSubmit={({ username, password, confirmPassword }) => {
                const validations = [];

                if (!tools.isDefinedAndNotNullAndNotEmpty(username)
                || !tools.isDefinedAndNotNullAndNotEmpty(password)
                || !tools.isDefinedAndNotNullAndNotEmpty(confirmPassword)
                || confirmPassword !== password) {
                  validations.push({
                    translateId: translateKeys.LOGIN.GENERIC_ERROR,
                    severity: TextHelperTypes.ERROR,
                  });
                }

                return {
                  validations,
                  execute: () => new Promise((resolve, reject) => {
                    try {
                      RegisterByEmail(dispatch,
                        username,
                        confirmPassword,
                        true);
                      resolve();
                    } catch {
                      reject();
                    }
                  }),
                };
              }}
            >
              <div className="">
                <div className="formAreaCreateNewPassword-container">

                  <div className="row center-xs">
                    {tools.isPropertyDefinedAndNotNullAndNotEmpty(profileInfo, 'firstName') && (
                    <span className="greeting ">
                      <span className="capitalize">
                        {`${profileInfo.firstName} ${profileInfo?.lastName || ''}`}
                      </span>
                      <Text translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.GREETING.LABEL} />
                    </span>
                    )}

                    <span className="title">
                      <Text translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.INVITE.LABEL} />
                    </span>
                  </div>
                  <div className="use-ui-core">
                    {registerState.error?.hasError === true && (
                    <>
                      <InlineAlert
                        bodyTranslateId={registerState.messageTranslateKey}
                        icon
                        status={inlineAlertStatus.ERROR}
                      />
                      <br />
                    </>
                    )}
                    <div className="trackfieldContainer username">
                      <TrackedTextField
                        name="username"
                        dataTestId="username"
                        value={profileInfo?.email}
                        translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.EMAIL.LABEL}
                        type={FieldTypes.TEXT}
                        disabled
                      />
                    </div>
                    <div className="trackfieldContainer">
                      <TrackedToggleableIconTextField
                        name="password"
                        dataTestId="password"
                        translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.PASSWORD.LABEL}
                        placeholderTranslateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                          .PASSWORD.PLACEHOLDER}
                        required
                        controlledWith={setTempPassword}
                        iconPosition={elementWrapperIconProps.INSIDE_RIGHT}
                        config={passwordEyeConfigArray}
                        disabled={isLoading}
                      />
                    </div>
                    <div className="trackfieldContainer">
                      <TrackedToggleableIconTextField
                        name="confirmPassword"
                        dataTestId="confirmPassword"
                        translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.CONFIRMATION.LABEL}
                        placeholderTranslateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD.CONFIRMATION
                          .PLACEHOLDER}
                        required
                        controlledWith={setTempConfirmation}
                        iconPosition={elementWrapperIconProps.INSIDE_RIGHT}
                        config={passwordEyeConfigArray}
                        disabled={isLoading}
                        applyThisValidation={() => {
                          if (!checkHasAllValidPasswordPolicy(
                            FieldCheckListArgs(tempPassword, tempConfirmation),
                          )) {
                            return [{
                              text: '',
                              severity: 'error',
                            }];
                          }
                          return [];
                        }}
                      />
                    </div>
                    <span className="requirements">
                      <Text
                        translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                          .PASSWORD_REQUIREMENTS.LABEL}
                      />
                    </span>
                  </div>
                  <FieldCheckList
                    checkArray={FieldCheckListArray}
                    unCheckedIcon={faCircle}
                    checkedIcon={faCheckCircle}
                    checkArgs={FieldCheckListArgs(tempPassword, tempConfirmation)}
                  />
                  <div className="create-button use-ui-core">
                    <SubmitButton
                      dataTestId="buttonSubmit"
                      translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                        .CREATE_ACCOUNT_BUTTON.LABEL}
                      style={ButtonProps.ButtonStyles.PRIMARY}
                      size={ButtonProps.ButtonSizes.MEDIUMFLEXIBLE}
                    />
                  </div>
                  <span className="legal desktop-only use-ui-core">
                    <Text translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                      .CREATE_PASSWORD_LEGAL.LABEL}
                    />
                    <Button
                      translateId={translateKeys.LOGIN.CREATE_NEW_PASSWORD
                        .CREATE_PASSWORD_LEGAL.LINK}
                      style={ButtonProps.ButtonStyles.LINK}
                      redirecturl="https://www.peopleready.com/Legal"
                      targetnewtab
                    />
                  </span>
                </div>
              </div>
            </FormHandler>
          </Spinner>

        </div>
      </div>
    </div>
  );
};
export default RegistrationByInvite;
