import React from 'react';
import { NotificationBell } from '@lake-superior/ui-core';
import PropTypes from 'prop-types';
import { tools } from '@lake-superior/core';
import NotificationsContext from './NotificationsContext';

const NotificationsBell = ({ desktop }) => (
  <NotificationsContext.Consumer>

    {({
      totalNotRead, handleOnBellClick, enabled, badgeLimitNumber,
      badgeColor,
      iconColor,
    }) => {
      if (!enabled) {
        return null;
      }

      const computeProps = {
        number: totalNotRead,
        onClick: handleOnBellClick,
        badgeLimitNumber,
      };

      if (tools.isDefinedAndNotNullAndNotEmpty(badgeColor)) {
        computeProps.badgeColor = badgeColor;
      }

      if (tools.isDefinedAndNotNullAndNotEmpty(iconColor)) {
        computeProps.iconColor = iconColor;
      }

      if (desktop) {
        return (
          <div className="shell-menu-notifications-wrapper">
            <div className="shell-menu-notifications-container use-ui-core">
              <NotificationBell {...computeProps} />
            </div>
          </div>
        );
      }

      return (
        <div className="col-xs-8 end-xs use-ui-core">
          <NotificationBell {...computeProps} />
        </div>
      );
    }}

  </NotificationsContext.Consumer>

);

NotificationsBell.propTypes = {
  desktop: PropTypes.bool,
};

NotificationsBell.defaultProps = {
  desktop: false,
};

export default NotificationsBell;
