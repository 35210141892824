/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  Icon,
  Text,
  useKeeper,
  isMobileOrTablet,
  useFeatureFlag,
} from '@lake-superior/ui-core';
import TranslateKeys from '../../locales/keys';
import LogOut from '../Auth/Logout';
import RouterListener from './RouterListener';
import {
  contextMainMenuSelector,
  contextMenuSelector,
} from '../../redux/ducks/shell';
import NotificationsContext from '../Notifications/NotificationsContext';
import NotificationsBell from '../Notifications/NotificationsBell';
import { baseUrls, featureFlags } from '../../config/appsettings.json';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const htmlTag = useKeeper(document.documentElement);
  const enableNavigationOptions = useFeatureFlag({
    name: featureFlags.NavigationOptions,
    configSvcBaseUrl: baseUrls.configService,
    region: 'us',
    roles: ['Associate'],
  });

  const closeMenu = useCallback(() => {
    setIsOpen(false);
    htmlTag.classList.remove('freezeWindow');
  }, [htmlTag]);

  const openMenu = useCallback(() => {
    setIsOpen(true);
    htmlTag.classList.add('freezeWindow');
  }, [htmlTag]);

  const toggleOpen = useCallback(
    (closeNotifications) => {
      if (isOpen) {
        closeMenu();
      } else {
        closeNotifications();
        openMenu();
      }
    },
    [closeMenu, openMenu, isOpen],
  );

  useEffect(() => {
    function handleResize() {
      if (isOpen && !isMobileOrTablet()) {
        closeMenu();
      }
    }
    function handleOrientationChange() {
      if (isOpen) {
        closeMenu();
      }
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [isOpen, closeMenu]);

  return (
    <>
      <div className="row shell-mobile-menu">
        {enableNavigationOptions && <NotificationsBell />}

        <NotificationsContext.Consumer>
          {({ closeNotifications }) => (
            <div
              onClick={() => toggleOpen(closeNotifications)}
              onKeyDown={() => toggleOpen(closeNotifications)}
              role="button"
              tabIndex={-1}
              className={`col-xs-${
                enableNavigationOptions ? '4' : '12'
              } end-xs shell-mobile-menu-icon`}
            >
              <Icon icon={faBars} />
            </div>
          )}
        </NotificationsContext.Consumer>
      </div>

      <div
        className={classNames('shell-mobile-menu-wrapper', {
          'shell-mobile-menu-wrapper-open': isOpen,
        })}
      >
        <div className="shell-mobile-menu-options-container">
          <div
            onClick={closeMenu}
            onKeyDown={closeMenu}
            role="button"
            tabIndex={-1}
            className="shell-mobile-menu-options-close-container"
          >
            <div className="box">
              <Icon icon={faTimes} />
              <div className="shell-mobile-menu-close-text">
                <Text translateId={TranslateKeys.shell.GENERAL.CLOSE} />
              </div>
            </div>
          </div>
          <div className="shell-mobile-menu-options-wrapper">
            <ul className="shell-mobile-menu-options">
              <RouterListener
                isMobile
                onItemClick={closeMenu}
                selector={
                  enableNavigationOptions
                    ? contextMainMenuSelector
                    : contextMenuSelector
                }
              />
            </ul>
          </div>
          <div className="shell-mobile-menu-logout-container">
            <LogOut />
          </div>
        </div>
        <div
          onClick={toggleOpen}
          onKeyDown={toggleOpen}
          role="button"
          tabIndex={-1}
          className="shell-mobile-menu-overlay"
        />
      </div>
    </>
  );
};

export default MobileMenu;
