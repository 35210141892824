/* eslint-disable import/prefer-default-export */
import { baseUrls } from '../../../config/appsettings.json';
import * as AssociateActions from '../../../redux/ducks/associate';

const IsAssociateRegisteredByAssociateId = async (dispatch, associateId) => {
  dispatch(AssociateActions.isAssociateRegisteredInit({
    associateId, baseUrl: baseUrls.associateService,
  }));
};

export { IsAssociateRegisteredByAssociateId };
